import {useAppSelector} from "@root/Hooks";
import {usePageParams} from "./useParams";

export const useDocument = () => {
    const documents = useAppSelector((state) => state.Anonymize.documents);
    const params = usePageParams();
    const {documentID} = params;
    if (!documentID) return null;
    return documents[documentID]
        ? documents[documentID]
        : null;
};

export default useDocument;
