import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
    getShortState,
} from "@root/Utils/Redux/getRequestState";

const initialState:iState.Value = {
    templates: getFullState(),
    createTemplateStatus: getShortState(),
    deleteTemplateStatus: getShortState(),
    deleteTemplateModal: false,
    editTemplateModal: false,
    editTemplateStatus: getShortState(),
    createTemplateModal: false,
    selectedTemplate: null,
};

const Slice = createSlice({
    initialState,
    name: "Templates",
    reducers: {
        getTemplates: (state) => {
            requestStart(state.templates);
        },
        _getTemplatesSuccess: (state, action: PayloadAction<iActions.getTemplatesSuccess>) => {
            requestSuccess(state.templates, action.payload);
        },
        _getTemplatesError: (state) => {
            requestError(state.templates);
        },
        stopGetTemplates: (state) => {
            return state;
        },
        createTemplate: (state, action: PayloadAction<iActions.createTemplate>) => {
            requestStart(state.createTemplateStatus);
        },
        createTemplateToDefault: (state) => {
            state.createTemplateStatus = initialState.createTemplateStatus;  
        },
        _createTemplateSuccess: (state) => {
            requestSuccess(state.createTemplateStatus);
        },
        _createTemplateError: (state) => {
            requestError(state.createTemplateStatus);
        },
        setCreateTemplateModal: (state, action: PayloadAction<iActions.setCreateTemplateModal>) => {
            state.createTemplateModal = action.payload;
        },
        setDeleteModal: (state, action: PayloadAction<iActions.setDeleteModal>) => {
            state.deleteTemplateModal = action.payload;
        },
        setEditModal: (state, action: PayloadAction<iActions.setEditModal>) => {
            state.editTemplateModal = action.payload;
        },
        deleteTemplate: (state, action: PayloadAction<iActions.deleteTemplate>) => {
            requestStart(state.deleteTemplateStatus);
        },
        _deleteTemplateSuccess: (state, action: PayloadAction<iActions.deleteTemplateSuccess>) => {
            if (state.templates.data) state.templates.data = state.templates.data.filter((template) => template.id !== action.payload);
            requestSuccess(state.deleteTemplateStatus);
        },
        _deleteTemplateError: (state) => {
            requestError(state.deleteTemplateStatus);
        },
        editTemplate: (state, action: PayloadAction<iActions.editTemplate>) => {
            requestStart(state.editTemplateStatus);
        },
        _editTemplateSuccess: (state) => {
            requestSuccess(state.editTemplateStatus);
        },
        _editTemplateError: (state) => {
            requestError(state.editTemplateStatus);
        },
        setSelectedTemplate: (state, action: PayloadAction<iActions.setSelectedTemplate>) => {
            const selectedTemplate = state.templates.data?.filter((template) => template.id === action.payload);
            if (selectedTemplate && selectedTemplate.length === 1) state.selectedTemplate = selectedTemplate[0];
        },
        eraseSelectedTemplate: (state) => {
            state.selectedTemplate = null;
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
