import React, {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {useParams} from "../../Hooks/useParams";
import Icon from "@ant-design/icons";
import Button, {ButtonProps} from "../../Components/Button/Button";
import AnonymizeIcon from "./Icons/Anonymize";
import AnonymizeModal from "./Modules/AnonymizeModal/AnonymizeModal";
import {useHistory} from "react-router-dom";
import DefaultAnonymizeDo from "@root/Modules/AnonymizeDo/AnonymizeDo";
import getRoute from "@routes";

const AnonymizeDo = () => {
    const dispatch = useAppDispatch();
    const openedDoc = useAppSelector((state) => state.Project.openedDocument.data);
    const anonymizeModalVisible = useAppSelector((state) => state.Anonymize.anonymizeModal.visible);
    const entities = useAppSelector((state) => state.Common.entities);
    const params = useParams();
    const history = useHistory();

    const onClick = useCallback(() => {
        if (!openedDoc) return null;
        dispatch(Actions.Anonymize.openAnonymizeModal());
    }, [openedDoc, params]);

    const onCancel = useCallback(() => {
        dispatch(Actions.Anonymize.closeAnonymizeModal());
    }, []);

    const buttonData: ButtonProps = {
        onClick,
        icon: <Icon component={AnonymizeIcon} />,
        disabled: entities.error,
        children: "Обезличить"
    };

    if (!openedDoc) return null;

    return (
        <>
            {/* <AnonymizeModal />
            <Button {...buttonData} /> */}
            <DefaultAnonymizeDo 
                anonymizeDoButton={<Button {...buttonData} />}
                anonymizeModalOpen={anonymizeModalVisible}
                documentsIds={[openedDoc.id]}
                onCancelModal={onCancel}
                onSuccessAction={() => {
                    dispatch(Actions.Anonymize.closeAnonymizeModal());
                    dispatch(Actions.Project.eraseOpenedDoc());
                    history.push(getRoute.lk.project.anonymize(
                        params.projectID,
                        params.documentID
                    ));
                }}
                projectIds={Number(params.projectID)}/>
        </>
    );
};

export default AnonymizeDo;
