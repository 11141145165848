import React from "react";
import {Layout} from "antd";
import {iLayoutProps} from "../index";
import Sidebar from "@root/Modules/Sidebar/Sidebar";
import styles from "./LKLayout.module.less";
import classnames from "classnames";
import useShowSidebar from "./Hooks/useShowSidebar";

const LkLayout = (props: iLayoutProps) => {
    const showSidebar = useShowSidebar();

    return (
        <Layout className={classnames(styles.layout, props.layoutClassname)}>
            {showSidebar ? <Sidebar /> : <></>}
            <Layout>
                <Layout.Content className={classnames(styles.content, props.contentClassname)}>
                    {props.children}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default LkLayout;
