import React from "react";
import styles from "./ButtonsBlock.module.less";
import ButtonChangeMode, {Type} from "../../Components/ButtonChangeMode/ButtonChangeMode";
import {Anonymization} from "@types";

type SwitchModeBarProps = {
    type: NonNullable<Anonymization.EditMode>
    startEditing: (type: NonNullable<Anonymization.EditMode>) => void,
    stopEditing: () => void,
    sendEditing: () => void,
    currentMode: Anonymization.EditMode,
    isLoading: boolean,
    editingDisable: boolean;
    hasChanges: boolean
}

const ButtonsBlock = (props: SwitchModeBarProps) => {
    const {type, currentMode, editingDisable} = props;
    const disabled = (currentMode && currentMode !== type) || editingDisable;
    const coreButtonType = type === "editing" ? Type.startEditing : Type.startMarkuping;

    return (
        <div className={styles.wrapper}>
            <ButtonChangeMode 
                type={coreButtonType}
                onClick={() => props.startEditing(type)}
                editModeActive={props.currentMode}
                disabled={disabled}/>

        </div>
    );
};

export default ButtonsBlock;
