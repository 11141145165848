import React from "react";
import {useAppSelector} from "@root/Hooks";
import {useLocation} from "react-router-dom";

const useRecoveryProgress = () => {
    const workMode = useAppSelector((state) => state.HTML.editMode);
    const selectedMarkups = useAppSelector((state) => state.HTML.selectedMarkups);
    const recoveryStatus = useAppSelector((state) => state.Common.recoveryStatus);
    const path = useLocation().pathname;

    const setStateToSessionStorage = () => {
        const state = {
            path,
            workMode,
            selectedMarkups,
        };
        sessionStorage.setItem("editRecovery", JSON.stringify(state));
    };

    if (!recoveryStatus) setStateToSessionStorage();

};

export default useRecoveryProgress;
