const getAnonStringStatus = (status: number) => {
    if (status === null || status === undefined || status === 0) {
        return "Не производилось";
    } else if  (status === 100) {
        return "В процессе";
    } else if (status === 400) {
        return "Ошибка";
    } else {
        return "Обезличен";
    }
};

export default getAnonStringStatus;
