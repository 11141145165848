import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import TableView from "../Views/TableView/TableView";
import {useQueries} from "../../../Hooks/useQueries";
import EmptyView from "../Views/EmptyView/EmptyView";
import Actions from "@actions";

const DocumentsTableController = () => {
    const documents = useAppSelector((state) => state.Project.documents.data);
    const documentsFetching = useAppSelector((state) => state.Project.documents.fetching);
    const queriesData = useQueries();
    const dispatch = useAppDispatch();

    const searchFoundNoDocuments = !!(documents && documents.documents.length === 0 && queriesData.search);
    const noDocumentsInProject = !!(!documents || documents.documents.length === 0);
    const SearchingDocuments = !!(documentsFetching && (!documents || documents.documents.length === 0));

    useEffect(() => {
        return () => {
            dispatch(Actions.Project.eraseSelectedDocuments());
        };
    }, []);

    if (searchFoundNoDocuments) return <EmptyView description="Отсутствуют файлы, удовлетворяющие условиям поиска"/>;
    if (SearchingDocuments) return <EmptyView description="Поиск документов"/>;
    if (noDocumentsInProject) return <EmptyView description="Отсутствуют загруженные документы"/>;
    return <TableView />;
};

export default DocumentsTableController;
