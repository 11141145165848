import React from "react";

const PreviousAnonymizeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 14L15.5 19.5L13 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 11.1666H15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 8H15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 20.3333H9.53C5.58 20.3333 4 18.6433 4 14.4183V9.34831C4 5.12331 5.58 3.43331 9.53 3.43331H14.27C18.22 3.43331 19.8 5.12331 19.8 9.34831V11.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PreviousAnonymizeIcon;
