import React from "react";

const Warning = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Type=Warning">
                <g id="danger triangle">
                    <path id="danger triangle_2" stroke="#FDA61B" fillRule="evenodd" clipRule="evenodd" d="M9.57218 4.414C10.6525 2.52774 13.3722 2.52927 14.451 4.41545L21.637 16.9804C22.7091 18.8548 21.3545 21.1868 19.197 21.1868H4.81396C2.65472 21.1868 1.30204 18.8532 2.37396 16.9794L9.57218 4.414ZM13.1489 5.16015C12.6457 4.28038 11.3775 4.27989 10.8738 5.15952L3.67597 17.7242C3.17588 18.5984 3.80721 19.6868 4.81396 19.6868H19.197C20.2034 19.6868 20.8347 18.5988 20.335 17.7252L13.1489 5.16015ZM12.0024 9.56482C12.4167 9.56482 12.7524 9.90061 12.7524 10.3148V13.4148C12.7524 13.829 12.4167 14.1648 12.0024 14.1648C11.5882 14.1648 11.2524 13.829 11.2524 13.4148V10.3148C11.2524 9.90061 11.5882 9.56482 12.0024 9.56482ZM11.9949 15.75C11.5807 15.75 11.2449 16.0858 11.2449 16.5C11.2449 16.9142 11.5807 17.25 11.9949 17.25H12.0049C12.4191 17.25 12.7549 16.9142 12.7549 16.5C12.7549 16.0858 12.4191 15.75 12.0049 15.75H11.9949Z" fill="#FEFEFE"/>
                </g>
            </g>
        </svg>

    );
};

export default Warning;
