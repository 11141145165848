import React, {useEffect, useMemo} from "react";
import Label from "../Label/Label";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import ErrorView from "./Contents/Error/Error";
import LoadingView from "./Contents/Loading/Loading";
import ContentView from "./Contents/Content/Content";
import {Anonymization} from "@types";

type ChooseEntityProps = {
    onAddEntity: (item: Anonymization.MarkupItem) => void;
};

const ChooseEntity = (props: ChooseEntityProps) => {
    const state = useAppSelector((state) => state.HTML.labels);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.fetching) return;
        if (state.loaded) return;
        dispatch(Actions.Common.getEntities());
    }, []);

    const content = useMemo(() => {
        if (state.fetching) return <LoadingView />;
        if (state.error) return <ErrorView />;
        return <ContentView onAddEntity={props.onAddEntity} />;
    }, [state]);

    return (
        <div>
            <Label>Выбор сущности</Label>
            {content}
        </div>
    );
};

export default ChooseEntity;
