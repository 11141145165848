import React from "react";
import {Form} from "antd";
import {Button, FormItem, Input} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {User as UserApi} from "@root/Api/UserApi/types";
import Actions from "@actions";
import styles from "./EditInfoForm.module.less";
import {User} from "@types";

const {isAuthUserInfo} = User.Guard.User;

const EditInfoForm = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state) => state.User.info.data);
    const editInfo = useAppSelector((state) => state.User.changeUserInfo);

    if (!isAuthUserInfo(userInfo)) return null;

    if (!userInfo) return null;

    const onCancel = () => {
        form.resetFields();
    };

    const onSubmit = (values: UserApi.iChangeUserInfo) => {
        dispatch(Actions.User.changeUserInfo({
            ...values
        }));
    };

    return (
        <div className={styles.wrapper}>
            <Form
                form={form}
                name="changeinfo"
                layout="vertical"
                onFinish={onSubmit}
                className={styles.form}>
                <FormItem
                    name="email"
                    label="E-mail"
                    initialValue={userInfo.email}
                    required
                    rules={[
                        {
                            type: "email",
                            message: "E-mail не подходит",
                        },
                    ]}>
                    <Input
                        allowClear
                        disabled={editInfo.fetching}
                        placeholder="Введите ваш email адрес"
                    />
                </FormItem>
                <div className={styles.buttons}>
                    <FormItem>
                        <Button
                            size="large"
                            type="ghost"
                            onClick={onCancel}
                            disabled={editInfo.fetching}
                            className={styles.button}>
                            Отменить
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button
                            size="large"
                            type="primary"
                            className={styles.button}
                            loading={editInfo.fetching}
                            htmlType="submit">
                            Сохранить
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

export default EditInfoForm;
