import {useAppSelector} from "@root/Hooks";
import React from "react";

const useFilterProjects = () => {
    const sort = useAppSelector((state) => state.Projects.sort);
    const projects = useAppSelector((state) => state.Projects.projects.data);

    if (!projects) return null;
    if (!sort.sorted) return projects;

    let filteredProjects = projects;

    filteredProjects = filteredProjects.filter((item) => {
        if (sort.dates.from !== null && sort.dates.to !== null) {
            return new Date(item.created_date).getTime() >= sort.dates.from &&
                new Date(item.created_date).getTime() <= sort.dates.to;
        }
        return item;
    });

    if (sort.variant === "new") {
        filteredProjects.sort((first, second) => {
            return second.created_date.localeCompare(first.created_date);
        });
    } else if (sort.variant === "old") {
        filteredProjects.sort((first, second) => {
            return first.created_date.localeCompare(second.created_date);
        });
    } else if (sort.variant === "fav") {
        filteredProjects.sort((first, second) => {
            return Number(second.favorite) - Number(first.favorite);
        });
    }

    return filteredProjects;

};

export default useFilterProjects;
