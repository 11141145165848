import React from "react";
import Img from "../../Images/hint.svg";
import styles from "./AppDescription.module.less";

const AppDescription = () => {
    return (
        <div className={styles.wrapper}>
            <img 
                src={Img} 
                className={styles.img}
            />
            <div className={styles.text}>
                Храни, обезличивай и принимай решения на основе документов в одном сервисе
            </div>
        </div>
    );
};

export default AppDescription;
