import React from "react";
import MainInfo from "../Modules/MainInfo/MainInfo";
import PasswordChange from "../Modules/PasswordChange/PasswordChange";
import styles from "./Profile.module.less";

const Profile = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.blocks}>
                <MainInfo />
                <PasswordChange />
            </div>
        </div>
    );
};

export default Profile;
