import React from "react";
import ContentController from "./Controllers/ContentController";
import AppDescription from "./Modules/AppDescription/AppDescription";
import styles from "./Auth.module.less";
import Logo from "./Modules/Logo/Logo";
import useTitle from "@root/Hooks/useTitle/useTitle";

const AuthPage = () => {
    useTitle("DeepDocs - Авторизация");
    
    return (
        <div className={styles.wrapper}>
            <div className={styles.leftCol}>
                <div className={styles.content}>
                    <ContentController />
                </div>
            </div>
            <div className={styles.rightCol}>
                <Logo />
                <AppDescription />
            </div>
        </div>
    );
};

export default AuthPage;
