import React, {useEffect} from "react";
import styles from "./ThemeSwitcher.module.less";
import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";
import {ButtonIcon} from "@root/Components/Controls";
import Dark from "@root/Assets/Icons/Theme/Dark";
import Light from "@root/Assets/Icons/Theme/Light";
import Tooltip from "@root/Components/Tooltip/Tooltip";

const ThemeSwitcher = () => {
    const [theme, setTheme] = useLocalStorage<string, string>("light", "theme");

    const onChangeTheme = () => {
        setTheme(theme === "light" ? "dark" : "light");
        const currentTheme = document.body.getAttribute("data-theme");
        document.body.setAttribute("data-theme", currentTheme === "light" ? "dark" : "light");
    };

    useEffect(() => {
        document.body.setAttribute("data-theme", theme);
    }, []);
    
    return (
        <Tooltip
            lineTooltip={true}
            placement="right"
            title={theme === "light" ? "Перейти на темную сторону" : "Перейти на светлую сторону"}>
            <ButtonIcon
                size="large"
                onClick={onChangeTheme}
                className={styles.button}
                type="ghost"
                icon={theme === "light" ? (<Dark />) : (<Light />)}/>
        </Tooltip>

    );
};

export default ThemeSwitcher;
