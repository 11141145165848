import React, {ReactNode, Children} from "react";
import styles from "./Row.module.less";
import {Col, Row as DefaultRow} from "antd";
import Divider from "./Components/Divider/Divider";

type WithChildren = {
    children: ReactNode
    colWrapElements?: boolean
} 

const Row = (props: WithChildren) => {
    const colWrapElements = props.colWrapElements || false;
    let nullableChilds = 0;
    return (
        <DefaultRow
            gutter={[32, 0]}
            className={styles.row}>
            {Children.map(props.children, (child, index) => {
                if (!child) {
                    nullableChilds += 1;
                    return child;
                }
                const content = colWrapElements ? <Col>{child}</Col> : child;
                const childIndex = index - nullableChilds;
                if (childIndex === 0) {
                    return content;
                }
                return (
                    <>
                        <Divider />
                        {content}
                    </>
                );
            })}
        </DefaultRow>
    );
};

export default Row;
