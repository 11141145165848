import React from "react";
import Empty from "@root/Modules/Empty/Empty";
import EmptyDocuments from "@root/Assets/Icons/EmptyDocuments/EmptyDocuments";

const EmptyItem = () => {
    return (
        <Empty
            image={<EmptyDocuments />}
            description="Данных о документе нет"
        />
    );
};

export default EmptyItem;
