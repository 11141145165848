import React from "react";
import {Type} from "../ButtonChangeMode";
import Eraser from "@root/Assets/Icons/Eraser/Eraser";
import Tick from "@root/Assets/Icons/Tick/Tick";
import styles from "./../ButtonChangeMode.module.less";

const useGetButtonData = (type: Type) => {
    if (type === Type.startEditing) {
        return {
            style: styles.buttonEdit,
            toolTipTitle: "Редактирование",
            icon: <Eraser />,
        }; 
    }
    if (type === Type.save) {
        return  {
            style: styles.buttonSave,
            toolTipTitle: "Сохранить",
            icon: <Tick />,
        };
    }
    return null;
};

export default useGetButtonData;
