import React from "react";
import DocumentsTableController
    from "./Controllers/DocumentsTableController";

const DocumentsTable = () => {
    return (
        <DocumentsTableController />
    );
};

export default DocumentsTable;
