import React from "react";

const Eraser = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.1821 3.64107C15.7703 3.23038 15.2146 2.99986 14.6353 3C14.0587 3.00033 13.5056 3.23141 13.0972 3.64175L2.63035 14.218L2.62938 14.219C2.22581 14.6345 2 15.1931 2 15.7746C2 16.356 2.22581 16.9146 2.62938 17.3301L2.63035 17.3311L6.06067 20.7974L6.06423 20.8008C6.19928 20.9285 6.37772 20.9999 6.56332 21H19.8688C20.0556 21 20.2345 20.925 20.3661 20.792C20.4976 20.6591 20.5712 20.4792 20.5712 20.2919C20.5712 20.1046 20.4976 19.9247 20.3661 19.7918C20.2345 19.6588 20.0556 19.5838 19.8688 19.5838H11.8545L20.3622 10.9872C20.7708 10.574 21 10.0141 21 9.43069C21 8.84726 20.7708 8.28737 20.3622 7.87416L16.1829 3.64187L16.1821 3.64107ZM9.35081 9.42611L14.0928 4.64369C14.2385 4.49742 14.4353 4.4155 14.64 4.4155C14.8446 4.4155 15.0415 4.49748 15.1872 4.64369L19.3751 8.87543C19.52 9.02272 19.6016 9.22231 19.6016 9.43069C19.6016 9.63896 19.5199 9.83867 19.3751 9.98594L14.6355 14.766L9.35081 9.42611ZM3.6266 15.2193L8.36627 10.4301L13.6418 15.7698L9.86733 19.5838H6.85601L3.62641 16.3296C3.48163 16.1823 3.40008 15.9828 3.40008 15.7746C3.40008 15.5662 3.48177 15.3666 3.6266 15.2193Z" fill="currentColor"/>
        </svg>
    );
};

export default Eraser;
