import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {Drawer} from "@root/Components/Controls";
import styles from "./AddEntityModal.module.less";
import ChooseEntity from "./Modules/ChoseEntity/ChooseEntity";
import ChoosenEntity from "./Modules/ChoosenEntity/ChoosenEntity";
import {Anonymization} from "@types";

type AddEntityModalProps = {
    onAddEntity: (item: Anonymization.MarkupItem) => void;
};

const AddEntityModal = (props: AddEntityModalProps) => {
    const addEntityModal = useAppSelector((state) => state.HTML.addEntityModal);
    const entity = useAppSelector((state) => state.HTML.entities.chosenEntity);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (entity) {
            dispatch(Actions.HTML.openAddEntityModal());
        }
    }, [entity]);

    return (
        <>
            <Drawer
                antProps={{
                    title: "Добавление сущности",
                    width: 400,
                    onClose: () => {
                        dispatch(Actions.HTML.closeAddEntityModal());
                    },
                    open: addEntityModal.visible,
                }}>
                <div className={styles.wrapper}>
                    <div className={styles.choosenEntity}>
                        <ChoosenEntity />
                    </div>
                    <ChooseEntity onAddEntity={props.onAddEntity}/>
                </div>
            </Drawer>
        </>
    );
};

export default AddEntityModal;
