import React, {useMemo} from "react";
import {ColumnType, FilterDropdownProps} from "antd/es/table/interface";
import styles from "./useColumns.module.less";
import {useAppSelector, useGetMinColumnWidthForTable} from "@root/Hooks";
import TableFilters from "@root/Modules/TableFilters/TableFilters";
import getDate from "@root/Utils/Date";
import getFilterDataFunction from "@root/Utils/Columns/getFilterDataFunction";
import dataFilterMapper from "@root/Utils/Columns/dataFilterMapper";
import DropdownMenu from "./Modules/DropdownMenu/DropdownMenu";
import {Anonymization} from "@types";

type TemplateItem = Anonymization.Templates.Template;

const useColumns = ():ColumnType<TemplateItem>[] => {
    const templates = useAppSelector((state) => state.Templates.templates.data);
    const getFilterData = getFilterDataFunction({array: templates || []});

    const titleFD = useMemo(() => getFilterData((item) => item.title), [templates]);
    const createdDateFD = useMemo(() => getFilterData((item) => getDate.short({date: item.created_date})), [templates]);

    return [
        {
            title: "Название",
            dataIndex: "title",
            key: "title",
            sorter: (first: TemplateItem, second: TemplateItem) => first.title.localeCompare(second.title),
            showSorterTooltip: false,
            filters: titleFD.map(dataFilterMapper),
            filterDropdown: (props: FilterDropdownProps) => {
                return (
                    <TableFilters
                        {...props}
                    />
                );
            },
            onFilter: (value: string | number | boolean, record: TemplateItem) => record.title === value,
            ellipsis: true,
            render: (title) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Название")}}
                        className={styles.limitColumn}>
                        <span>
                            {title}
                        </span>
                    </div>
                );
            }
        },
        {
            title: "Дата создания",
            dataIndex: "created_date",
            key: "created_date",
            sorter: (first: TemplateItem, second: TemplateItem) => first.created_date.localeCompare(second.created_date),
            showSorterTooltip: false,
            filters: createdDateFD.map(dataFilterMapper),
            filterDropdown: (props: FilterDropdownProps) => {
                return (
                    <TableFilters
                        {...props}
                    />
                );
            },
            onFilter: (value: string | number | boolean, record: TemplateItem) => getDate.short({date: record.created_date}) === value,
            ellipsis: true,
            render: (created_date) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Дата создания")}}>
                        <span>
                            {getDate.short({date: created_date})}
                        </span>
                    </div>
                );
            }
        },
        {
            title: "Комментарий",
            dataIndex: "description",
            key: "description",
            ellipsis: true,
            render: (description) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Комментарий")}}
                        className={styles.limitColumn}>
                        <span>
                            {description}
                        </span>
                    </div>
                );
            }
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (id: TemplateItem["id"], record: TemplateItem) => {
                return <DropdownMenu id={id} />;
            },
        }
    ];
};

export default useColumns;
