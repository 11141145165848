import {Button} from "@root/Components/Controls";
import React from "react";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import styles from "./AnonymizeButton.module.less";
import classNames from "classnames";

type AnonymizeButtonProps = {
    selectedDocumentBoolean: boolean,
    notSuccessDocumentSelected: boolean
}

const AnonymizeButton = (props: AnonymizeButtonProps) => {
    const {selectedDocumentBoolean, notSuccessDocumentSelected} = props;
    const disabled = !selectedDocumentBoolean || notSuccessDocumentSelected;

    const dispatch = useAppDispatch();
    const showCreateJobForm = () => {
        dispatch(Actions.Anonymize.openAnonymizeModal());
    };
    const getTitle = () => {
        if (notSuccessDocumentSelected) return "Для обезличивания подходят только успешно загруженные документы";
        if (!selectedDocumentBoolean) return "Для обезличивания выберите документы";
        return "";
    };

    return (    
        <div>            
            <Tooltip
                className={styles.toolTip}
                title={getTitle()}>
                <span>
                    <Button
                        type="stroke"
                        disabled={disabled}
                        onClick={showCreateJobForm} 
                        className={classNames({[styles.disabled]: disabled})}>
                        Обезличить
                    </Button>
                </span>
            </Tooltip>
        </div>    
    );
};

export default AnonymizeButton;
