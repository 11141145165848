import React from "react";
import styles from "./Error.module.less";
import ErrorIcon from "./Icons/ErrorIcon";
import Empty from "@root/Modules/Empty/Empty";
const Error = () => {
    return (
        <div className={styles.error}>
            <Empty 
                noBottomPadding
                typeSmallText
                description={"Не смогли загрузить данные. Попробуйте перезагрузить страницу."} 
                image={<ErrorIcon />}
            />
        </div>
    );
};

export default Error;
