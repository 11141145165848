import {ButtonIcon} from "@root/Components/Controls";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import React from "react";
import styles from "./ButtonChangeMode.module.less";
import classNames from "classnames";
import {LoadingOutlined} from "@ant-design/icons";
import useGetButtonData from "./Hooks/useGetButtonData";
import useGetToolTipData from "./Hooks/useGetToolTipData";
import {Anonymization} from "@types";

type ButtonChangeModeProps = {
    type: Type,
    onClick?: () => void,
    isLoading?: boolean, 
    editModeActive?: Anonymization.EditMode,
    disabled?: boolean,
}

export enum Type {
    startEditing = "startEditing",
    startMarkuping = "startMarkuping",
    save = "save",
    stop = "stop"
}

const ButtonChangeMode = ({
    type,
    onClick,
    isLoading = false,
    editModeActive = null,
    disabled = false,
}: ButtonChangeModeProps) => {
    const buttonData = useGetButtonData(type);

    const click = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    if (!buttonData) return null;

    if (type === Type.startEditing) buttonData.style = `${classNames(buttonData.style, {[styles.buttonEditActive]: editModeActive === "editing"})}`;
    if (type === Type.startMarkuping) buttonData.style = `${classNames(buttonData.style, {[styles.buttonEditActive]: editModeActive === "markuping"})}`;

    const toolTipTitle = useGetToolTipData({
        disabled,
        defaultToolTip: buttonData.toolTipTitle,
        type
    });

    return (
        <Tooltip
            placement="right"
            lineTooltip={true}
            title={toolTipTitle}>
            <ButtonIcon   
                size="middle"
                onClick={click}
                className={classNames(buttonData.style, {[styles.disabled]: disabled})}>
                {isLoading ? <LoadingOutlined /> : buttonData.icon}
            </ButtonIcon> 
        </Tooltip>
    );
};

export default ButtonChangeMode;
