import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import styles from "./PdfView.module.less";
import {pdfjs} from "react-pdf/dist/esm/entry.webpack";
import {PDFDocumentProxy} from "pdfjs-dist";
import Actions from "@actions";
import PdfPage from "../Modules/PdfPage";
import Scrollbars from "react-custom-scrollbars";
import SwitchModeButtons from "../Modules/SwitchModeButtons/SwitchModeButtons";

type PdfViewProps = {
    url: string
}

const PdfView = (props: PdfViewProps) => {
    const coloredPdfPages = useAppSelector((state) => state.Anonymize.coloredPdf.pages);
    const dispatch = useAppDispatch();
    const [result, setResult] = useState<PDFDocumentProxy | null>();

    const buttonsProps = {
        startEditing: () => {
            dispatch(Actions.Anonymize.changeColoredPdfEditMode(true));
        },
        saveEditing: () => {
            dispatch(Actions.Anonymize.changeColoredPdfEditMode(false));
        },
    };

    useEffect(() => {
        if (!props.url) return;
        pdfjs
            .getDocument(props.url)
            .promise
            .then((result) => {
                setResult(result as unknown as PDFDocumentProxy);
            })
            .catch(() => {
                setResult(null);
            });
    }, []);

    useEffect(() => {
        if (!result) return;
        dispatch(Actions.Anonymize.setColoredPagesNumber(result.numPages));
    }, [result]);

    if (!coloredPdfPages) return null;
    if (!result) return null;
  
    return (
        <div className={styles.grid}>
            <SwitchModeButtons {...buttonsProps}/>
            <Scrollbars>
                <div className={styles.ViewPDF}>
                    {Array(coloredPdfPages)
                        .fill("")
                        .map((_, index) => (
                            <div className={styles.page} key={index}>
                                <PdfPage index={index} url={props.url} result={result}/>
                            </div>
                        ))}
                </div>

            </Scrollbars>
        </div>
    );
};

export default PdfView;
