import React from "react";
import {useAppSelector} from "@root/Hooks";
import ellipsisString from "@root/Utils/ellipsisString/ellipsisString";

type useGetLinkNameProps = {
    pathname: string,
    prevPathname: string,
}

const useGetLinkName = (props: useGetLinkNameProps) => {
    const isNumber = (/^\d+$/).test(props.pathname);

    if (isNumber && props.prevPathname === "projects") {
        const currentProject = useAppSelector((state) => state.Projects.selectedProject.data);
        if (currentProject) return ellipsisString({
            text: currentProject.title,
            type: "component",
            tooltip: true,
            length: 30
        });
        return " ";
    }
    if (props.pathname === "projects") return "Проекты";
    return null;
};

export default useGetLinkName;
