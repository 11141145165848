import React, {useState, useEffect} from "react";
import packageJson from "./../../../package.json";
import isServerBuildNewer from "./Utils/isServerBuildNewer";
import refreshCacheAndReload from "./Utils/refreshCacheAndReload";
import axios, {AxiosError, AxiosResponse} from "axios";

type Meta = {
  buildDate: number; 
}

function useGetCheckCacheComponent<T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    function CheckCacheComponent(props: T) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

        useEffect(() => {
            axios.get<Meta>("/meta.json", {
                headers: {
                    "Cache-Control": "no-store"
                }
            })
                .then((response: AxiosResponse) => {
                    const meta: Meta = response.data;
                    const shouldForceRefresh = isServerBuildNewer({
                        serverDate: meta.buildDate, //from server
                        cacheDate: packageJson.buildDate // from cache
                    });
                    if (shouldForceRefresh) {
                        setIsLatestBuildDate(false);
                        refreshCacheAndReload();
                    } else {
                        setIsLatestBuildDate(true);
                    }
                })
                .catch((error: AxiosError) => {
                    return false;
                });
        }, []);

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        );
    }

    return CheckCacheComponent;
}

export default useGetCheckCacheComponent;
