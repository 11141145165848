import Request from "../Request";
import {Project} from "./types";
import Endpoints from "./endpoints";

export default {
    uploadDocument: (params: Project.iUploadDocument) => {
        const fileData = new FormData();
        params.document.forEach((item) => {
            fileData.append("files", (item));
        });
        fileData.append("project_id", params.project);
        return Request.post<void>(`${Endpoints.documents.uploadDocument.replace("{project_id}", params.project)}`, fileData);
    },
    getDocuments: (params: Project.iGetDocuments) => {
        const {project_id, ...qs_params} = params;
        return Request.get<Project.oGetDocuments>(`${Endpoints.documents.getDocuments.replace("{project_id}", project_id)}`, qs_params);
    },
    getProjectInfo: (params: Project.iGetProjectInfo) => {
        return Request.get<Project.oGetProjectInfo>(`${Endpoints.projects.getProjectInfo}/${params.project_id}`);
    },
    getDocumentInfo: (params: Project.iGetDocumentInfo) => {
        return Request.get<Project.oGetDocumentInfo>(`${Endpoints.documents.getDocumentInfo.replace("{document_id}", params.id)}`);
    },
    deleteDocuments: (params: Project.iDeleteDocuments) => {
        return Request.delete<void>(`${Endpoints.documents.deleteDocuments}`, null, {
            data: params
        });
    },
    setFavorite: (params: Project.iSetFavorite) => {
        const url =  Endpoints.projects.setFavorite.replace("{id}", params.project_id);
        return Request.patch(
            url,
            {
                favorite: params.favorite,
            }
        );
    },
};
