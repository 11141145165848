import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import React from "react";

const AnonymizeFile = function*(action:PayloadAction<iActions.anonymizeFile>) {
    const {payload} = action;
    const {documents_ids, anonymization_type, entities, onSuccess} = payload;
    try {
        yield call(Api.Anonymize.Anonymize, {
            documents_ids,
            anonymization_type,
            entities
        });
        onSuccess && onSuccess();
        yield put(Actions.Anonymize._anonymizeFileSuccess());
    } catch (ex) {
        yield put(Actions.Anonymize._anonymizeFileError());
    }
};

export default function* () {
    yield takeLatest(Actions.Anonymize.anonymizeFile, AnonymizeFile);
}
