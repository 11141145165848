import React from "react";
import {Button, Input, FormItem} from "@root/Components/Controls";
import {Form} from "antd";
import {User as UserApi} from "@root/Api/UserApi/types";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {User} from "@types";

const {isAuthUserInfo} = User.Guard.User;

const PasswordChangeForm = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const passwordChange = useAppSelector((state) => state.User.changePassword);
    const userInfo = useAppSelector((state) => state.User.info.data);

    if (!isAuthUserInfo(userInfo)) return null;

    const onSuccess = () => {
        form.resetFields();
    };

    const onSubmit = (values: UserApi.iChangePassword) => {
        dispatch(Actions.User.changePassword({
            ...values,
            onSuccess,
        }));
    };
    
    return (
        <Form
            form={form}
            name="changepassword"
            layout="vertical"
            onFinish={onSubmit}>
            <FormItem
                name="old_password"
                label="Текущий пароль"
                rules={[
                    {
                        required: true,
                        message:"Это поле не может быть пустым."
                    },
                ]}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Введите пароль"
                />
            </FormItem>
            <FormItem
                name="new_password"
                label="Новый пароль"
                dependencies={["old_password"]}
                rules={[
                    ({getFieldValue}) => ({
                        validator(rule,value) {
                            if (!value) {
                                return Promise.reject(new Error("Это поле не может быть пустым."));
                            }
                            if (getFieldValue("old_password") === value) {
                                return Promise.reject(new Error("Текущий и новый пароли совпадают"));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Введите пароль"
                />
            </FormItem>
            <FormItem
                name="confirm"
                label="Повторите новый пароль"
                dependencies={["password"]}
                rules={[
                    ({getFieldValue}) => ({
                        validator(rule,value) {
                            if (!value) {
                                return Promise.reject(new Error("Это поле не может быть пустым."));
                            } 
                            if (getFieldValue("new_password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Пароли не совпадают"));
                        },
                    }),
                ]}>
                <Input.Password
                    disabled={passwordChange.fetching}
                    placeholder="Повторите пароль"
                />
            </FormItem>
            <FormItem>
                <Button
                    size="large"
                    type="primary"
                    loading={passwordChange.fetching}
                    htmlType="submit">
                        Сменить пароль
                </Button>
            </FormItem>
        </Form>
    );
};

export default PasswordChangeForm;
