import React from "react";
import {Search} from "@root/Components/Controls";
import styles from "./SearchBar.module.less";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";

type SearchBarProps = {
    loading: boolean
}

const SearchBar = (props: SearchBarProps) => {
    const {loading} = props;
    const tableConfig = useAppSelector((state) => state.Common.documentsTableConfig);
    const dispatch = useAppDispatch();

    const onSearch = (value: string) => {
        dispatch(Actions.Common.setTableConfig({
            config: {
                currentPage: 1, // ЕСЛИ НАЧИНАЕМ ПОИСК - СБРАСЫВАЕМ ТЕКУЩУЮ СТРАНИЦУ
                name: value
            },
            table: "documents"
        }));
    };

    return (
        <Search
            placeholder="Поиск по документам"
            className={styles.search}
            defaultValue={tableConfig?.name}
            onSearch={onSearch}
            loading={loading}/>
    );
};

export default SearchBar;
