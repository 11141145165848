import * as React from "react";

const DownloadIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="downlaod">
                <path id="downlaod_2" fillRule="evenodd" clipRule="evenodd" d="M11.7609 0.333313C12.2141 0.333313 12.5815 0.700669 12.5815 1.15383V13.1616L14.6342 11.1401C14.9571 10.8222 15.4766 10.8261 15.7946 11.149C16.1125 11.4719 16.1085 11.9914 15.7857 12.3094L12.3368 15.7057C12.0175 16.0202 11.5048 16.0202 11.1854 15.7057L7.73658 12.3094C7.4137 11.9914 7.40972 11.4719 7.72768 11.149C8.04565 10.8261 8.56515 10.8222 8.88803 11.1401L10.9404 13.1613V1.15383C10.9404 0.700669 11.3078 0.333313 11.7609 0.333313ZM18.3437 7.46425C20.3124 7.46425 21.8803 9.02448 21.8803 10.9181V16.5833C21.8803 18.4699 20.3172 20.0256 18.3544 20.0256L5.17884 20.0256C3.21005 20.0256 1.64103 18.4641 1.64103 16.5717V10.9054C1.64103 9.02027 3.20502 7.46425 5.16701 7.46425H6.28114C6.73429 7.46425 7.10165 7.0969 7.10165 6.64374C7.10165 6.19059 6.73429 5.82323 6.28114 5.82323H5.16701C2.32952 5.82323 0 8.08344 0 10.9054V16.5717C0 19.4003 2.33396 21.6666 5.17884 21.6666L18.3544 21.6666C21.1933 21.6666 23.5214 19.4061 23.5214 16.5833V10.9181C23.5214 8.08851 21.1887 5.82323 18.3437 5.82323H17.2402C16.7871 5.82323 16.4197 6.19059 16.4197 6.64374C16.4197 7.0969 16.7871 7.46425 17.2402 7.46425H18.3437Z" fill="currentColor"/>
            </g>
        </svg>

    );
};

export default DownloadIcon;
