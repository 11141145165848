import React from "react";
import TopToolbar from "../../Modules/TopToolbar/TopToolbar";
import HTML from "../../Modules/HTML/HTML";
import {useAppSelector} from "@root/Hooks";
import PdfView from "@root/Modules/AnonPdfView/PdfView";

const Anonymization = () => {
    const anonType = useAppSelector((state) => state.Project.openedDocument.data?.anonymization_type);

    return (
        <>
            <TopToolbar />
            {anonType === "pdf_anonymization" ? (
                <PdfView />
            ) : (
                <HTML />
            )}
        </>
    );
};

export default Anonymization;
