import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import DownloadIcon from "@root/Assets/Icons/Download/Download";
import {Popconfirm} from "antd";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import styles from "./Buttons.module.less";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import {ButtonIcon} from "@root/Components/Controls";

export const Buttons = () => {
    const dispatch = useAppDispatch();
    const selectedDocument = useAppSelector((state) => state.Project.selectedDocuments);
    const projectID = useAppSelector((state) => state.Project.documents.data?.id);
    const documentsPageLength = useAppSelector((state) => state.Project.documents.data?.documents.length);
    const totalPages = useAppSelector((state) => state.Project.documents.data?.pages);
    const tableConfig = useAppSelector((state) => state.Common.documentsTableConfig);

    const getNewCurrentPageWithCheckFullDeleteLastPage = () => {
        if (
            documentsPageLength && 
            totalPages &&
            selectedDocument.length === documentsPageLength &&
            tableConfig?.currentPage === totalPages &&
            totalPages !== 1
        ) {
            return totalPages - 1;
        }
        if (tableConfig && tableConfig.currentPage) return tableConfig.currentPage;
        return 1;
    };

    const onDeleteSuccess = () => {
        if (!projectID) return;
        dispatch(Actions.Common.setTableConfig({
            config: {
                currentPage: getNewCurrentPageWithCheckFullDeleteLastPage(),
                defaultPageSize: tableConfig?.defaultPageSize || 10,
                order_by: tableConfig?.order_by,
                name: tableConfig?.name
            },
            table: "documents"
        }));
    };

    const deleteItems = () => {
        if (selectedDocument && projectID) {
            dispatch(Actions.Project.deleteDocuments({
                documents_ids: selectedDocument?.map((item) => item.id),
                onSuccess: () => onDeleteSuccess()
            }));
        }
    };

    const downloadItems = () => {
        if (selectedDocument) {
            dispatch(Actions.Document.downloadOriginalDocument({
                documents_ids: selectedDocument?.map((item) => item.id),
            }));
        }
    };
    return (
        <div className={styles.wrapper}>
            <Popconfirm
                placement="bottomRight"
                title="Удалить выбранные документы?"
                onConfirm={deleteItems}
                okText="Да"
                cancelText="Нет">
                <div>
                    <Tooltip 
                        title="Удалить">
                        <div>
                            <ButtonIcon
                                icon={<DeleteOutlined />}
                                type="ghost"/>
                        </div>
                    </Tooltip>
                </div>
            </Popconfirm>
            <Tooltip title="Выгрузить">
                <div className={styles.buttonBlock}> 
                    <ButtonIcon
                        icon={<DownloadIcon />}
                        type="ghost"
                        onClick={downloadItems}/>
                </div>
            </Tooltip>
            <span className={styles.line}></span>
        </div>
    );
};
