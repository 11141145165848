import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import GridContainer from "@root/Components/GridContainer/GridContainer";
import styles from "./Statistics.module.less";
import Loading from "@root/Components/Loading/Loading";
import Title from "./Statistics/Modules/Title/Title";
import useTitle from "@root/Hooks/useTitle/useTitle";
import StatisticsContent from "./Statistics/Statistics";
import Scrollbars from "react-custom-scrollbars";
import LastDocuments from "./Statistics/Modules/LastDocuments/LastDocuments";

const Statistics = () => {
    const getFetching = useAppSelector((state) => state.Common.statistics.fetching);
    const dispatch  = useAppDispatch();
    useTitle("DeepDocs - Статистика");

    useEffect(() => {
        dispatch(Actions.Common.getStatistics());
        return () => {
            dispatch(Actions.Common.stopGetStatistics());
        };
    }, []);

    if (getFetching) {
        return (
            <Loading>
                Статистика загружается...
            </Loading>
        );
    }

    return (
        <>
            <GridContainer className={styles.wrapper}>
                <>
                    <div className={styles.subHeader}>
                        <Title />
                    </div>
                    <Scrollbars autoHide>
                        <div className={styles.statistics}>
                            <LastDocuments />
                            <StatisticsContent />
                        </div>
                    </Scrollbars>
                </>
            </GridContainer>
        </>
    );
};

export default Statistics;
