import Request from "../Request";
import Endpoints from "./endpoints";
import {Templates} from "./types";

export default {
    getTemplates: () => {
        return Request.get<Templates.oGetTemplate>(Endpoints.Templates.base);
    },
    createTemplates: (params: Templates.iCreateTemplate) => {
        return Request.post(Endpoints.Templates.base, params);
    },
    changeTemplates: (params: Templates.iChangeTemplate) => {
        const {id, ...data} = params;
        return Request.patch(Endpoints.Templates.change.replace("{id}", String(id)), data);
    },
    deleteTemplates: (params: Templates.iDeleteTemplate) => {
        return Request.delete(Endpoints.Templates.change.replace("{id}", String(params)));
    }
};
