type GetFilterDataFunctionProsp<T> = {
    array: T[]
}

const getFilterDataFunction = <T>(props: GetFilterDataFunctionProsp<T>) => {
    const {array} = props;
    const getDataForFilters = (handler: (item: T) => string) => {
        return Array.from(
            new Set(
                array.map((item) => {
                    return handler(item);
                })
            )
        );
    };
    return getDataForFilters;
};

export default getFilterDataFunction;
