import React from "react";
import {useAppSelector} from "@root/Hooks";
import Scrollbars from "react-custom-scrollbars";
import styles from "./DocumentController.module.less";
import PDF from "../Views/PDF/PDF";
import Loading from "@root/Components/Loading/Loading";
import TopToolbar from "../Modules/TopToolbar/TopToolbar";
import CoreToolbar from "../Modules/CoreToolbar/CoreToolbar";
import Error from "@root/Modules/Error/Error";
import EmptyItem from "../Views/EmptyItem/EmptyItem";

const DocumentController = () => {
    const openDocumentFullState = useAppSelector((state) => state.Project.openedDocument);

    if (!openDocumentFullState.error && !openDocumentFullState.loaded) return (
        <Loading>
            Документ загружается...
        </Loading>
    );
    if (openDocumentFullState.error) return <Error>Произошла ошибка при открытии документа.</Error>;
    if (!openDocumentFullState.data) return (
        <>
            <TopToolbar />
            <EmptyItem />
        </>
    );
    return (
        <>
            <TopToolbar />
            <CoreToolbar />
            <div className={styles.wrapper}>
                <PDF />
            </div>
        </>
    );
};

export default DocumentController;
