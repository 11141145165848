import React from "react";
import {Button, Modal} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import CloseIcon from "@assets/Icons/Close/Close";
import styles from "./DeleteModal.module.less";

type DeleteModalProps = {
    history?: () => void
}

const DeleteModal = (props: DeleteModalProps) => {
    const {history} = props;
    const deleteModal = useAppSelector((state) => state.Projects.deleteModal);
    const selectedProject = useAppSelector((state) => state.Projects.selectedProject.data);
    const dispatch = useAppDispatch();

    const onCloseModal = () => {
        dispatch(Actions.Projects.setDeleteModal(false));
    };

    if (!selectedProject) return null;

    return (
        <Modal 
            antProps={{
                open: deleteModal,
                title: "Удалить проект?",
                footer: null,
                onCancel: onCloseModal,
                closeIcon: <CloseIcon />,
                centered: true
            }}>
            <div className={styles.modalWrapper}>
                <div className={styles.modalText}>
                    После удаления проекта все данные по&nbsp;документам и&nbsp;выполненным заданиям будут утеряны
                </div>
                <div className={styles.modalButtons}>
                    <Button
                        onClick={() => {
                            dispatch(Actions.Projects.deleteProject({id: selectedProject.id}));
                            history && history();
                        }}
                        className={styles.modalButton}
                        size="large"
                        type="primary">
                        Удалить
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(Actions.Projects.setDeleteModal(false));
                        }}
                        className={styles.modalButton}
                        size="large"
                        type="stroke">
                        Отмена
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
