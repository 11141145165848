import {AnyAction} from "@reduxjs/toolkit";
import {htmlTypes} from "@modules/HTML/Types/types";
import Actions from "@actions";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {Anonymization} from "@types";
import {Dispatch} from "react";
const notificaton = useNotification();

type recoveryEditingProps = {
    workiframeWindow: Window,
    onAddEntity: (item: Anonymization.MarkupItem) => void,
    dispatch: Dispatch<AnyAction>,
    body: HTMLElement,
}

const recoveryMarkuping = (props: recoveryEditingProps) => {
    const {workiframeWindow, onAddEntity, dispatch, body} = props;

    try {
        dispatch(Actions.HTML.setEditMode("markuping"));

        const newEntitiesString = sessionStorage.getItem("recoveryNewEntities");
        if (!newEntitiesString) return;
        const newEntitiesJson = JSON.parse(newEntitiesString!) as htmlTypes.recoveryEntity[]; 
        // Удаляем лишнее чтобы наполнить из новых addNew
        sessionStorage.removeItem("recoveryNewEntities");
        newEntitiesJson.forEach((item) => {
            if (typeof item === "number") {
                const delete_buttons = body.getElementsByClassName("markup_control_button") as HTMLCollectionOf<HTMLElement>;
                [...delete_buttons].forEach((button: HTMLElement) => {
                    const button_id = button.getAttribute("id");
                    if (button_id  === `${item}`) {
                        button.click();
                    }
                });
                return;
            }
            const Range = workiframeWindow.document.createRange();

            const startСontainer = workiframeWindow.document.evaluate(item.xPath.start, workiframeWindow.document, null, XPathResult.ANY_TYPE, null).iterateNext();
            const endСontainer = workiframeWindow.document.evaluate(item.xPath.end, workiframeWindow.document, null, XPathResult.ANY_TYPE, null).iterateNext();

            if (!startСontainer || !endСontainer) throw new Error();

            Range.setStart(startСontainer!, item.xPath.startOffset);
            Range.setEnd(endСontainer!, item.xPath.endOffset);

            const selection  = workiframeWindow.document.getSelection();
            if (selection) selection.addRange(Range);

            onAddEntity({...item.item});
            dispatch(Actions.HTML.addNewEntity(item.item));
        });
    } catch (e) {
        notificaton({
            type: "error",
            message: "Во время восстановления прогресса произошла ошибка, не все сущности восстановлены"
        });
    }
};

export default recoveryMarkuping;
