import {useNotification} from "@root/Hooks";
import {Documents, Projects} from "@types";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";

type onRowClickProps = {
    document: Documents.Item,
    projectID: Projects.Item["id"]
}

const onRowClick = (props: onRowClickProps) => {
    const {document, projectID} = props;
    const notification = useNotification();
    const history = useHistory();

    if (document.overall_status === 0 || document.overall_status === 100) return {
        onClick: () => {
            notification({
                type: "error",
                message: "Документ обрабатывается"
            });
        }
    };

    if (document.overall_status === 400) return {
        onClick: () => {
            notification({
                type: "error",
                message: "Загруженный документ имеет ошибки"
            });
        },
    };

    return {
        onClick: () => {
            history.push(
                routes.lk.project.document(
                    projectID,
                    document.id
                )
            );
        },
    };
};

export default onRowClick;

