import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iActions, iState} from "./types";
import {getShortState, requestStart, requestSuccess, requestError} from "@root/Utils/Redux/getRequestState";

const initialState:iState.Value = {
    registration: getShortState(),
    login: getShortState(),
    recovery: getShortState(),
    reset: getShortState(),
};

const Slice = createSlice({
    initialState,
    name: "Auth",
    reducers: {
        userLogin: (state, action: PayloadAction<iActions.userLogin>) => {
            requestStart(state.login);
        },
        userLogout: (state) => {
            return state;
        },
        _userLoginSuccess: (state) => {
            requestSuccess(state.login);
        },
        _userLoginError: (state, action: PayloadAction<iActions._userLoginError>) => {
            requestError(state.login, action.payload.message);
        },
        userRegistration: (state, action: PayloadAction<iActions.userRegistration>) => {
            requestStart(state.registration);
        },
        _userRegistrationSuccess: (state) => {
            requestSuccess(state.registration);
        },
        _userRegistrationError: (state, action: PayloadAction<iActions._userRegistrationError>) => {
            requestError(state.registration, action.payload.message);
        },
        userRecovery: (state, action: PayloadAction<iActions.userRecovery>) => {
            requestStart(state.recovery);
        },
        _userRecoverySuccess: (state) => {
            requestSuccess(state.recovery);
        },
        _userRecoveryError: (state) => {
            requestError(state.recovery);
        },
        userResetPassword: (state, action: PayloadAction<iActions.userResetPassword>) => {
            requestStart(state.reset);
        },
        _userResetPasswordSuccess: (state) => {
            requestSuccess(state.recovery);
        },
        _userResetPasswordError: (state) => {
            requestError(state.recovery);
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
