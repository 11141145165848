import React from "react";
import styles from "./CountStatBlock.module.less";

type CountStatBlockProps = {
    icon: JSX.Element,
    title: string;
    statNumber: number;
    duringTime?: string;
}

const CountStatBlock = (props: CountStatBlockProps) => {
    return (
        <div className={styles.countStat}>
            <div className={styles.iconTitle}>
                <div className={styles.icon}>
                    {props.icon}
                </div>
                <div className={styles.title}>
                    {props.title}
                </div>
            </div>
            <div className={styles.info}>
                <div className={props.statNumber ? styles.count : styles.zeroCount}>
                    {props.statNumber}
                </div>

                <div className={styles.subTitle}>
                    {props.duringTime && props.duringTime}
                </div>
                
            </div>

        </div>
    );
};

export default CountStatBlock;
