import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
    getShortState
} from "@root/Utils/Redux/getRequestState";
import {getDocument} from "./utils/getDocument";
import {getVerificationKey} from "@root/Utils/PDF/getVerificationKey";

const defaultDocument: Omit<iState.Document, "id"> = {
    settings: {
        dataMode: "original",
        version: null,
    },
    validate: getShortState(),
};
const initialState: iState.Value = {
    anonymizeModal: {
        visible: false,
    },
    anonymizeProcess: getFullState(),
    anonymizedHtml: getFullState(),
    documents: {},
    coloredPdf: {
        file: getFullState(),
        pages: null,
        coloredNodes: [],
        editMode: false,
        fileName: "",
    },
};

const Slice = createSlice({
    initialState,
    name: "Anonymize",
    reducers: {
        anonymizeFile: (state, action: PayloadAction<iActions.anonymizeFile>) => {
            requestStart(state.anonymizeProcess);
        },
        _anonymizeFileSuccess: (state) => {
            requestSuccess(state.anonymizeProcess);
        },
        _anonymizeFileError: (state) => {
            requestError(state.anonymizeProcess);
        },
        getAnonHtml: (state, action: PayloadAction<iActions.getAnonHtml>) => {
            requestStart(state.anonymizedHtml);
        },
        _getAnonHtmlSuccess: (state, action: PayloadAction<iActions._getAnonHtmlSuccess>) => {
            requestSuccess(state.anonymizedHtml, action.payload);
        },
        _getAnonHtmlError: (state) => {
            requestError(state.anonymizedHtml);
        },
        openAnonymizeModal: (state) => {
            state.anonymizeModal.visible = true;
        },
        closeAnonymizeModal: (state) => {
            state.anonymizeModal.visible = false;
        },
        anonymizeWatchStart: (state, action: PayloadAction<iActions.anonymizeWatchStart>) => state,
        anonymizeWatchStop: (state) => state,
        initAnonymizedDoc: (state, action: PayloadAction<iActions.initAnonymizedDoc>) => {
            const {payload} = action;
            const {id} = payload;
            const document = getDocument(state, id);
            if (document) return state;
            else state.documents[id] = {
                ...defaultDocument,
                id,
            };
        },
        changeSettings: (state, action: PayloadAction<iActions.changeSettings>) => {
            const {payload} = action;
            const {id, settings} = payload;
            const document = getDocument(state, id);
            if (!document) return state;
            document.settings = {
                ...document.settings,
                ...settings,
            };
        },
        downloadResult: (state, action: PayloadAction<iActions.downloadResult>) => {
            return state;
        },
        confirmVerification: (state, action: PayloadAction<iActions.confirmVerification>) => {
            return state;
        },
        getColoredPdf: (state, action: PayloadAction<iActions.getColoredPdf>) => {
            requestStart(state.coloredPdf.file);
        },
        _getColoredPdfSuccess: (state, action: PayloadAction<iActions._getColoredPdfSuccess>) => {
            requestSuccess(state.coloredPdf.file, action.payload.file);
            state.coloredPdf.fileName = action.payload.fileName;
        },
        _getColoredPdfError: (state) => {
            requestError(state.coloredPdf.file);
        },
        addColoredNode: (state, action: PayloadAction<iActions.addColoredNode>) => {
            const {payload} = action;
            state.coloredPdf.coloredNodes.push({
                ...payload,
                key: getVerificationKey(payload.x, payload.y, payload.width, payload.height)
            });
        },
        setColoredPagesNumber: (state, action: PayloadAction<number>) => {
            state.coloredPdf.pages = action.payload;
        },
        deleteColoredNode: (state, action: PayloadAction<iActions.deleteColoredNode>) => {
            const {nodeId} = action.payload;
            state.coloredPdf.coloredNodes = state.coloredPdf.coloredNodes.filter(
                (node: iState.ColoredNode) => node.key !== nodeId
            );
        },
        eraseColoredNodes: (state) => {
            state.coloredPdf.coloredNodes = [];
        },
        changeColoredPdfEditMode: (state, action: PayloadAction<boolean>) => {
            state.coloredPdf.editMode = action.payload;
        },
        eraseColoredFile: (state) => {
            state.coloredPdf = initialState.coloredPdf;
        },
        eraseAnonHtml: (state) => {
            state.anonymizedHtml = initialState.anonymizedHtml;
        },
        downloadAnonymizeDocumentsResult: (state, action: PayloadAction<iActions.downloadAnonymizeDocumentsResult>) => {
            return state;
        },
    },
});

export const Actions = Slice.actions;
export default Slice.reducer;
