import React from "react";
import {Empty as DefaultEmpty, EmptyProps as DefaultEmptyProps} from "antd";
import styles from "./Empty.module.less";
import classNames from "classnames";

type EmptyProps = {
    typeSmallText?: boolean,
    noBottomPadding?: boolean
} & DefaultEmptyProps

const Empty = (props: EmptyProps) => {
    const {className, image, typeSmallText, noBottomPadding, ...componentProps} = props;
    const emptyImage = image ? image : null;
    
    return (
        <DefaultEmpty
            className={classNames(
                styles.empty,
                className,
                {[styles.typeSmallText]: typeSmallText},
                {[styles.bottomPadding]: !noBottomPadding}
            )}
            image={emptyImage}
            {...componentProps}/>
    );
};

export default Empty;
