import * as React from "react";

const Anonymized = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#4EB6F1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.8338 8.33765C19.4563 7.96118 18.9469 7.74988 18.4159 7.75C17.8873 7.75031 17.3803 7.96213 17.0059 8.33827L7.41131 18.0332L7.41043 18.0341C7.04049 18.4149 6.8335 18.927 6.8335 19.46C6.8335 19.993 7.04049 20.5051 7.41043 20.886L7.41131 20.8869L10.5558 24.0643L10.559 24.0674C10.6828 24.1844 10.8464 24.2499 11.0165 24.25H23.2132C23.3845 24.25 23.5484 24.1812 23.6691 24.0593C23.7896 23.9375 23.8571 23.7726 23.8571 23.6009C23.8571 23.4292 23.7896 23.2643 23.6691 23.1424C23.5484 23.0205 23.3845 22.9518 23.2132 22.9518H15.8668L23.6655 15.0716C24.04 14.6928 24.2502 14.1796 24.2502 13.6448C24.2502 13.11 24.04 12.5968 23.6655 12.218L19.8345 8.33838L19.8338 8.33765ZM13.5717 13.6406L17.9186 9.25672C18.0521 9.12264 18.2325 9.04754 18.4201 9.04754C18.6077 9.04754 18.7882 9.12269 18.9217 9.25672L22.7607 13.1358C22.8935 13.2708 22.9683 13.4538 22.9683 13.6448C22.9683 13.8357 22.8934 14.0188 22.7607 14.1538L18.416 18.5355L13.5717 13.6406ZM8.32454 18.951L12.6692 14.5609L17.5052 19.4556L14.0452 22.9518H11.2848L8.32437 19.9688C8.19165 19.8338 8.1169 19.6509 8.1169 19.46C8.1169 19.269 8.19179 19.086 8.32454 18.951Z" fill="#FEFEFE"/>
        </svg>
    );
};

export default Anonymized;
