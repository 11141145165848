import React from "react";
import styles from "./Title.module.less";

const Title = () => {
    return (
        <div className={styles.titleBlock}>
            <div className={styles.title}> 
                Статистика
            </div>
        </div>
    );
};

export default Title;
