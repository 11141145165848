import React, {ReactNode} from "react";
import styles from "./Wrapper.module.less";
import classnames from "classnames";
import {Row} from "antd";

type WithChildren = {
    children: ReactNode,
    className?: string
}

const Wrapper = (props: WithChildren) => {
    const className = classnames(styles.wrapper, props.className);
    return <div className={className}>
        <Row justify="space-between">
            {props.children}
        </Row>
    </div>;
};

export default Wrapper;
