import React from "react";
import {Layout} from "antd";
import {App} from "@types";
import styles from "./Sidebar.module.less";
const {Sider} = Layout;

type iSidebar = {
    children: App.children | App.children[]
}

const Sidebar = (props: iSidebar) => {
    return (
        <Sider width={48} className={styles.sider}>
            {props.children}
        </Sider>
    );
};

export default Sidebar;
