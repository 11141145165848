import React from "react";
import {CreateTemplateButton as Button} from "@root/Components/Controls";
import {useAppSelector, useAppDispatch} from "@root/Hooks";
import {Actions} from "@root/Redux/Templates/TemplatesRedux";
import useGetEntities from "./Hooks/useGetEntities";
import CreateModal from "../../Modules/CreateModal/CreateModal";

const CreateTemplateButton = () => {
    const createTemplateStatus = useAppSelector((state) => state.Templates.createTemplateStatus);
    const createTemplateModal = useAppSelector((state) => state.Templates.createTemplateModal);
    const dispatch = useAppDispatch();
    const entities = useGetEntities();

    const openCreateModal = () => dispatch(Actions.setCreateTemplateModal(true));

    if (entities === undefined) return null;

    return (
        <>
            <Button 
                active={createTemplateModal}
                loading={createTemplateStatus.fetching}
                onClick={openCreateModal} />
            <CreateModal presetEntities={entities} />
        </>
    );
};

export default CreateTemplateButton;
