import React from "react";
import Title from "@root/Components/Title/Title";
import {useAppSelector} from "@root/Hooks";
import EditInfoForm from "./Components/EditInfoForm/EditInfoForm";
import styles from "./MainInfo.module.less";
import {User} from "@types";

const {isAuthUserInfo} = User.Guard.User;

const MainInfo = () => {
    const userInfo = useAppSelector((state) => state.User.info.data);

    if (!isAuthUserInfo(userInfo)) return null;

    if (!userInfo) return null;
    return (
        <div className={styles.wrapper}>
            <Title
                variant="h2"
                className={styles.title}>
                Основная информация
            </Title>
            <EditInfoForm />
        </div>
    );
};

export default MainInfo;
