import React from "react";

const EmptyProjects = () => {
    return (
        <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M118.796 22.745C139.536 27.528 150.443 47.2021 156.577 64.833C162.008 80.4432 158.482 97.033 149.665 111.465C141.779 124.373 127.691 133.066 111.765 138.179C96.7459 143.001 80.7494 142.696 65.4798 138.491C48.9261 133.932 33.7441 126.509 24.1925 114.063C12.5585 98.9043 -1.16608 79.942 7.99101 63.5844C17.0573 47.3889 44.4761 50.5212 63.7376 43.4221C82.8734 36.3692 98.6802 18.1058 118.796 22.745Z" fill="#AFAFAF" fillOpacity="0.1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M58.2866 126.279C58.2139 126.316 57.032 124.155 55.648 121.451C54.264 118.747 53.202 116.524 53.2747 116.487C53.3479 116.45 54.5289 118.611 55.9129 121.315C57.2969 124.019 58.3598 126.241 58.2866 126.279Z" fill="#00CA81"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M60.6782 118.876C60.7169 118.952 58.5564 120.135 55.8525 121.519C53.1485 122.903 50.925 123.964 50.8863 123.888C50.8478 123.813 53.0083 122.63 55.7123 121.246C58.4163 119.862 60.6397 118.801 60.6782 118.876Z" fill="#00CA81"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M111.264 42.4429C111.138 42.4766 110.051 38.8349 108.838 34.3084C107.625 29.7818 106.746 26.0845 106.872 26.0508C106.998 26.0168 108.084 29.659 109.297 34.1855C110.51 38.7121 111.391 42.4089 111.264 42.4429Z" fill="#00CA81"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M117.543 31.9763C117.577 32.103 113.811 33.2224 109.131 34.4763C104.451 35.7303 100.63 36.6438 100.596 36.5171C100.562 36.3912 104.328 35.2718 109.008 34.0179C113.688 32.764 117.509 31.8504 117.543 31.9763Z" fill="#00CA81"/>
            <path d="M6.6347 143C6.50012 145 6.01395 76.1065 68.4025 78C146 80.3551 141.965 42.267 146 13" stroke="#00CA81" strokeLinecap="round" strokeDasharray="7 7"/>
            <path d="M119.414 72.1281L85.2178 61.6154V93.1537L108.893 101.014C114.072 102.734 119.414 98.8785 119.414 93.422V72.1281Z" fill="#B3B3B3"/>
            <path d="M85.2178 61.6154L85.3647 61.1374C85.2131 61.0908 85.0483 61.1189 84.9207 61.2132C84.7931 61.3075 84.7178 61.4567 84.7178 61.6154H85.2178ZM119.414 72.1281H119.914C119.914 71.9085 119.771 71.7147 119.561 71.6502L119.414 72.1281ZM108.893 101.014L108.736 101.489L108.893 101.014ZM85.2178 93.1537H84.7178V93.5146L85.0602 93.6283L85.2178 93.1537ZM85.0708 62.0933L119.267 72.606L119.561 71.6502L85.3647 61.1374L85.0708 62.0933ZM118.914 72.1281V93.422H119.914V72.1281H118.914ZM109.051 100.54L85.3753 92.6792L85.0602 93.6283L108.736 101.489L109.051 100.54ZM85.7178 93.1537V61.6154H84.7178V93.1537H85.7178ZM118.914 93.422C118.914 98.5375 113.906 102.152 109.051 100.54L108.736 101.489C114.238 103.316 119.914 99.2195 119.914 93.422H118.914Z" fill="#4F4F4F"/>
            <path d="M54.1323 72.5488L86.2179 61.6154V93.1539L64.7127 100.482C59.5228 102.25 54.1323 98.3924 54.1323 92.9095V72.5488Z" fill="#858585"/>
            <path d="M86.2179 61.6154L86.0566 61.1421C86.2093 61.0901 86.3777 61.1149 86.5089 61.2088C86.64 61.3027 86.7179 61.4541 86.7179 61.6154H86.2179ZM54.1323 72.5488H53.6323V72.1909L53.9711 72.0755L54.1323 72.5488ZM64.7127 100.482L64.5514 100.009L64.7127 100.482ZM86.2179 93.1539H86.7179V93.5117L86.3791 93.6272L86.2179 93.1539ZM86.3791 62.0887L54.2936 73.022L53.9711 72.0755L86.0566 61.1421L86.3791 62.0887ZM54.6323 72.5488V92.9095H53.6323V72.5488H54.6323ZM64.5514 100.009L86.0566 92.6806L86.3791 93.6272L64.8739 100.955L64.5514 100.009ZM85.7179 93.1539V61.6154H86.7179V93.1539H85.7179ZM54.6323 92.9095C54.6323 98.0497 59.6859 101.667 64.5514 100.009L64.8739 100.955C59.3597 102.834 53.6323 98.7351 53.6323 92.9095H54.6323Z" fill="#4F4F4F"/>
            <path d="M86.64 114.6L86.2179 83.4821L119.992 72.5487L120.334 98.4667C120.382 102.042 118.051 105.214 114.626 106.237L86.64 114.6Z" fill="#E5E5E5"/>
            <path d="M86.2179 83.4821L86.0639 83.0064L85.7129 83.12L85.7179 83.4889L86.2179 83.4821ZM86.64 114.6L86.1401 114.607C86.1422 114.764 86.2177 114.91 86.3441 115.003C86.4704 115.096 86.633 115.124 86.7832 115.079L86.64 114.6ZM114.626 106.237L114.769 106.717L114.626 106.237ZM120.334 98.4667L119.835 98.4733L120.334 98.4667ZM119.992 72.5487L120.492 72.5421L120.483 71.8642L119.838 72.073L119.992 72.5487ZM85.7179 83.4889L86.1401 114.607L87.14 114.593L86.7178 83.4753L85.7179 83.4889ZM86.7832 115.079L114.769 106.717L114.482 105.758L86.4969 114.121L86.7832 115.079ZM120.834 98.4601L120.492 72.5421L119.492 72.5553L119.835 98.4733L120.834 98.4601ZM119.838 72.073L86.0639 83.0064L86.3719 83.9578L120.146 73.0244L119.838 72.073ZM114.769 106.717C118.408 105.629 120.885 102.258 120.834 98.4601L119.835 98.4733C119.879 101.825 117.694 104.799 114.482 105.758L114.769 106.717Z" fill="#4F4F4F"/>
            <path d="M86.64 114.6L86.2179 83.4821L54.1323 72.5487V98.6147C54.1323 102.119 56.4133 105.216 59.7603 106.255L86.64 114.6Z" fill="#F8F8F8"/>
            <path d="M86.2179 83.4821L86.3791 83.0088L86.713 83.1226L86.7178 83.4753L86.2179 83.4821ZM86.64 114.6L87.14 114.593C87.1422 114.753 87.0676 114.905 86.9393 115.001C86.8111 115.096 86.6447 115.125 86.4918 115.078L86.64 114.6ZM59.7603 106.255L59.9086 105.777L59.7603 106.255ZM54.1323 72.5487H53.6323C53.6323 72.3874 53.7101 72.236 53.8413 72.1421C53.9725 72.0483 54.1409 72.0234 54.2936 72.0755L54.1323 72.5487ZM86.7178 83.4753L87.14 114.593L86.1401 114.607L85.7179 83.4889L86.7178 83.4753ZM86.4918 115.078L59.6121 106.732L59.9086 105.777L86.7883 114.123L86.4918 115.078ZM53.6323 98.6147V72.5487H54.6323V98.6147H53.6323ZM54.2936 72.0755L86.3791 83.0088L86.0566 83.9553L53.9711 73.022L54.2936 72.0755ZM59.6121 106.732C56.0559 105.628 53.6323 102.338 53.6323 98.6147H54.6323C54.6323 101.9 56.7708 104.803 59.9086 105.777L59.6121 106.732Z" fill="#4F4F4F"/>
            <path d="M79.072 51.7862L86.2178 61.6154L54.1323 72.1282L48.7287 64.3238C47.2095 62.1297 48.2241 59.0915 50.7569 58.2506L74.5762 50.3421C76.2294 49.7933 78.0477 50.3773 79.072 51.7862Z" fill="#D9D9D9" stroke="#4F4F4F"/>
            <path d="M77.8547 95.2831L86.2178 83.482L54.1323 72.5487L47.2414 81.7003C45.6274 83.8438 46.5399 86.9372 49.0588 87.8615L73.2131 96.7254C74.9085 97.3475 76.8105 96.7565 77.8547 95.2831Z" fill="#D9D9D9" stroke="#4F4F4F"/>
            <path d="M93.8018 94.5772L85.7956 83.4821L120.414 72.5487L125.692 82.1859C126.897 84.3867 125.802 87.141 123.414 87.9131L98.2763 96.0425C96.6249 96.5765 94.8175 95.9847 93.8018 94.5772Z" fill="#D9D9D9" stroke="#4F4F4F"/>
            <path d="M93.943 51.2695L86.2178 61.6154L120.414 72.1282L126.129 63.9962C127.687 61.779 126.636 58.6867 124.05 57.8784L98.3411 49.8448C96.7213 49.3386 94.9584 49.9097 93.943 51.2695Z" fill="#D9D9D9" stroke="#4F4F4F"/>
        </svg>
    );
};

export default EmptyProjects;
