import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import useDocument from "../../Hooks/useDocument";
import Actions from "@actions";
import useRecoveryProgress from "@root/Modules/HTML/Hooks/useRecoveryProgress";
import HTMLComponent from "@modules/HTML/HTML";
import {iActions} from "@root/Redux/Anonymize/types";
import {Anonymization} from "@types";

const HTML = () => {
    const data = useAppSelector((state) => state.Anonymize.anonymizedHtml.data);
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const document = useDocument();
    const dispatch = useAppDispatch();

    useRecoveryProgress();

    if (!data) return null;
    if (!document) return null;
    if (!openedDocument) return null;

    let markup_list: Anonymization.AnonHTML["original_markup"] | Anonymization.AnonHTML["replacement_markup"];
    let html: Anonymization.AnonHTML["original_html"] | Anonymization.AnonHTML["anonymized_html"];

    if (document.settings.dataMode === "original") {
        markup_list = data.original_markup;
        html = data.original_html;
    } else {
        markup_list = data.replacement_markup;
        html = data.anonymized_html;
    }
    const editingDisable = document.settings.dataMode === "anonymized";

    const saveChanges = (props: Pick<iActions.confirmVerification, "to_delete" | "to_add">) => {
        dispatch(Actions.Anonymize.confirmVerification({
            ...props,
            anonymized_document_id: openedDocument.anonymized_document_id,
            anonymization_request_id: openedDocument.anonymization_request_id,
            id: openedDocument.id,
            type: "singleDocument"
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(Actions.Anonymize.eraseAnonHtml());
        };
    }, []);

    return (
        <HTMLComponent
            editingDisable={editingDisable}
            htmlDocument={html}
            markup_list={markup_list}
            saveChanges={saveChanges}
        />
    );
};

export default HTML;
