import {styles} from "./Styles/styles";

type addMarkupStylesProps = {
    highlights: HTMLSpanElement[],
}

const addMarkupStyles = (props: addMarkupStylesProps) => {
    const {highlights} = props;
    if (highlights.length === 1) {
        highlights[0].style.cssText = styles.singleToken;
        return;
    } 
    if (highlights.length > 1) {
        let fullEmpty = true;

        highlights.forEach((highlight, index) => {
            const end = index === highlights.length - 1;
            const notEmptyContent = highlight.innerText.replace(/\s+/g, "");
            if (end && !fullEmpty) { //end
                highlight.style.cssText = styles.endToken;
                return;
            }
            if (!end && notEmptyContent && fullEmpty) { // middle
                fullEmpty = false;
                highlight.style.cssText = styles.firstToken;
                return;
            }
            if (!end && notEmptyContent && !fullEmpty) { // first
                highlight.style.cssText = styles.usualToken;
            }
        }
        );
    }
};

export default addMarkupStyles;
