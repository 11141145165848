import React, {useCallback, useEffect, useState} from "react";
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryTheme, VictoryTooltip} from "victory";

type LineChartMarkedEntitiesProps = {
    anon_stat: {
        quarter: number;
        earnings: number;
        fill: string;
    }[];
    barValues: string[]
    width: number
}

const LineChartMarkedEntities = (props: LineChartMarkedEntitiesProps) => {
    const [width, setWidth] = useState(0);

    const graphRef = useCallback((node) => {
        if (node !== null) {
            setWidth(node.getBoundingClientRect().width - 20);
        }
    }, []);
  
    const updateWidth = () => {
        const chart = document.getElementById("linechart");
        if (!chart?.clientWidth) {
            return;
        }
        setWidth(chart.clientWidth - 20);
    };
  
    useEffect(() => {
        window.addEventListener("resize", updateWidth);
  
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);
    
    const zeroAnonStat = [...Array(8)].map((item, index) => {
        return {
            quarter: index + 1,
            earnings: 20,
            fill: "var(--chart-empty-fill)"
        };
    });
    const zeroBarValues = ["Физ\nЛицо", "Адрес", "Дата", "ИНН", "Цифры", "НЮЛ", "Гос\nОрган", "Ссылка"];

    return (
        <div style={{width: "100%",
            height: "100%"}} ref={graphRef} id="linechart">
            <VictoryChart
                domainPadding={50}
                height={170}
                width={width}
                padding={{top: 20,
                    bottom: 50,
                    right: 0,
                    left: 30}}
                theme={VictoryTheme.material}>
                <VictoryAxis
                    standalone={false}
                    style={{
                        grid: {stroke: "transparent"},
                        axis: {
                            stroke: "var(--chart-label-stroke)"
                        },
                        tickLabels: {
                            fill: "var(--chart-label-fill)"
                        },  
                    }}
                    tickValues={props.barValues.length === 0 ? zeroBarValues : props.barValues}
                />
                <VictoryAxis
                    standalone={false}
                    dependentAxis
                    style={{
                        grid: {stroke: "transparent"},
                        axis: {
                            stroke: "var(--chart-label-stroke)"
                        },
                        tickLabels: {
                            fill: "var(--chart-label-fill)"
                        }, 
                    }}
                    tickFormat={(x: number) => (`${x}`)}
                />
                <VictoryBar
                    standalone={false}
                    cornerRadius={{topLeft: 8,
                        topRight: 8}}
                    style={{
                        data: {
                            fill: ({datum}) => datum.fill,
                        }
                    }}
                    barWidth={50}
                    labels={props.anon_stat.length !== 0 ? ({datum}) => datum.earnings : undefined}
                    labelComponent={<VictoryTooltip
                        style={{
                            fontFamily: "SourceSansPro",
                            fontWeight: 700,
                            fill: "var(--tooltip-color)"
                        }}
                        flyoutStyle={{
                            stroke: "var(--tooltip-bg)",
                            fill: "var(--tooltip-bg)",
                            color: "var(--text-primary)"
                        }}
                    />}
                    data={props.anon_stat.length === 0 ? zeroAnonStat : props.anon_stat}
                    x="quarter"
                    y="earnings"
                />
            </VictoryChart>
        </div>
    );
};

export default LineChartMarkedEntities;
