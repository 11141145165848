export default {
    authorization: {
        auth: "/v1/auth/login",
        registration: "/v1/users/create-user",
        recovery: "/v1/auth/reset_password",
        refresh: "/v1/auth/refresh-token",
        reset: "/v1/auth/reset_password_confirm",
        logout: "/v1/auth/logout",
    },
};
