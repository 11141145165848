import {useEffect} from "react";
import useLastPathStorage from "./Hooks/useLastPathStorage/useLastPathStorage";

const checkLogoutStatus = (lastPath: string) => {
    return lastPath === "\"logout\"";
};

// ready - готов к записи последнего пути
// logout - пользователь вышел через логаут -> путь не записывается
// получение через ls.getItem необходимо для получения актуальных значений тк при использовании хука данные полученные при инициализации не обновляются (тк компонент не перерендеривается)

const useSaveLastPath = () => {
    const [_, setLastPath] = useLastPathStorage();
    useEffect(() => {
        const savePathOnUnload = () => {
            const actualStateLastPath = localStorage.getItem("lastPath") || "ready";
            if (checkLogoutStatus(actualStateLastPath)) {
                setLastPath("ready");
                return;
            } 
            const lastPath = window.location.pathname;
            if (lastPath.includes("auth")) return;
            setLastPath(lastPath);
        };
        window.addEventListener("beforeunload", savePathOnUnload);
        return () => {
            window.removeEventListener("beforeunload", savePathOnUnload);
        };
    }, []);
};

export default useSaveLastPath;
