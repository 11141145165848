import React, {useEffect} from "react";
import {useAppSelector, useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import {Button, Modal} from "@root/Components/Controls";
import styles from "./RecoveryModal.module.less";
import GridContainer from "@root/Components/GridContainer/GridContainer";
import {htmlTypes} from "@modules/HTML/Types/types";
import recoveryEditing from "./Utils/recoveryEditing";
import recoveryMarkuping from "./Utils/recoveryMarkuping";
import {Anonymization} from "@types";

type RecoveryModalProps = {
    onAddEntity: (item: Anonymization.MarkupItem) => void,
    workIframe: React.RefObject<HTMLIFrameElement>,
};

const RecoveryModal = (props: RecoveryModalProps) => {

    const body = props.workIframe.current?.contentDocument?.body;
    const workIframeDoc = props.workIframe.current?.contentDocument;
    const workiframeWindow = props.workIframe.current?.contentWindow;

    const status = useAppSelector((state) => state.Common.recoveryStatus);
    const loadedIframe = useAppSelector((state) => state.HTML.isFrameLoaded);

    const dispatch = useAppDispatch();

    // проверка на режим, если ласт режим был null или он не сохранился выходим из восстановления
    useEffect(() => {
        const recoveryStorageString = sessionStorage.getItem("editRecovery");
        if (!recoveryStorageString) {
            dispatch(Actions.Common.setRecoveryStatus(false));
            sessionStorage.removeItem("editRecovery");
            return;
        }
        const recoveryStorageJSON = JSON.parse(recoveryStorageString) as htmlTypes.editRecovery;
        if (recoveryStorageJSON.workMode === null) {
            dispatch(Actions.Common.setRecoveryStatus(false));
            sessionStorage.removeItem("editRecovery");
            return;
        }
    });

    // Если отменяем восстановление - чистим sessionStorage
    const onCancel = () => {
        dispatch(Actions.Common.setRecoveryStatus(false));
        sessionStorage.removeItem("editRecovery");
        sessionStorage.removeItem("recoveryNewEntities");
    };

    const onRecovery = () => {
        if (!body || !workIframeDoc || !workiframeWindow) return; 

        const recoveryStorageString = sessionStorage.getItem("editRecovery");
        if (!recoveryStorageString) return;
        const recoveryStorageJSON = JSON.parse(recoveryStorageString) as htmlTypes.editRecovery;
        
        // RecoveryForEdititng (удаление сущностей)
        if (recoveryStorageJSON.workMode === "editing") {
            recoveryEditing({
                recoveryStorageJSON,
                body,
                dispatch,
            });
        }

        // RecoveryForMarkuping (Восстановление сущностей)
        if (recoveryStorageJSON.workMode === "markuping") {
            recoveryMarkuping({
                workiframeWindow,
                onAddEntity: props.onAddEntity,
                dispatch,
                body
            });
        }
    };

    return (
        <Modal
            antProps={{
                open: status,
                title: "Восстановление прогресса редактирования документа",
                footer: null,
                onCancel,   
                closable: false,
                centered: true
            }}>
            <GridContainer
                className={styles.grid}>
                <div className={styles.wrapper}>
                    <Button 
                        onClick={onCancel}
                        className={styles.cancelButton}
                        type="stroke">
                        Отмена
                    </Button>
                    <Button 
                        loading={!loadedIframe}
                        onClick={() => {
                            onRecovery();
                            dispatch(Actions.Common.setRecoveryStatus(false));
                            sessionStorage.removeItem("editRecovery");
                        }}
                        type="primary">
                        Восстановить прогресс
                    </Button>
                </div>
            </GridContainer>
        </Modal>
    );
};

export default RecoveryModal;
