import {Col, Popconfirm} from "antd";
import React, {useEffect, useState} from "react";
import styles from "./BackLink.module.less";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import {Arrow} from "@root/Components/LastBlock/Icons/Arrow";
import useGetSaveChanges from "../Hooks/useGetSaveChanges";
import {Anonymization, Projects, Documents} from "@types";

type BackLinkProps = {
    projectId: Projects.Item["id"],
    documentId: Documents.Item["id"],
    anonymizationRequestId: string,
    anonymizedDocumentId: string,
    addedEntities: Anonymization.MarkupItem[],
    selectedMarkups: number[]
}

const BackLink = (props: BackLinkProps) => {
    const {
        projectId,
        documentId,
        anonymizationRequestId,
        addedEntities,
        anonymizedDocumentId,
        selectedMarkups
    } = props;
    const [confirmOk, setConfirmOk] = useState(false);
    const history = useHistory();
    const saveChanges = useGetSaveChanges({
        anonymized_document_id: anonymizedDocumentId,
        anonymization_request_id: anonymizationRequestId,
        id: documentId
    });
    const hasChanges = addedEntities.length !== 0 || selectedMarkups.length !== 0;

    useEffect(() => {
        return () => {
            if (confirmOk) {
                history.push(routes.lk.project.document(projectId, documentId));
            }
        };
    },[confirmOk]);

    return (
        <Popconfirm
            disabled={!hasChanges}                     
            placement="bottomRight"
            title="Сохранить изменения?"
            onConfirm={() => {
                setConfirmOk(true);
                saveChanges({
                    to_delete: selectedMarkups,
                    to_add: addedEntities,
                });
            }}
            onCancel={() => history.push(routes.lk.project.document(projectId, documentId))}
            okText="Да"
            cancelText="Нет">
            <Col className={styles.backLink}
                onClick={() => !hasChanges && history.push(routes.lk.project.document(projectId, documentId))}>
                <Arrow />
            </Col>
        </Popconfirm>
    );
};

export default BackLink;

