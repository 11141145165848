import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iActions, iState} from "./types";
import {getFullState, requestError, requestStart, requestSuccess} from "@root/Utils/Redux/getRequestState";

const initialState: iState.Value  = {
    labels: getFullState(),
    entities: {
        newEntities: [],
        chosenEntity: null,
    },
    addEntityModal: {
        visible: false,
    },
    editMode: null,
    selectedMarkups: [],
    isFrameLoaded: false,
    sendEditingStatus: null, 
};

const Slice = createSlice({
    initialState,
    name: "HTML",
    reducers: {
        setEditMode: (state, action: PayloadAction<iActions.setEditMode>) => {
            state.editMode = action.payload;
        },
        setIsFrameLoaded: (state, action: PayloadAction<iActions.setIsFrameLoaded>) => {
            state.isFrameLoaded = action.payload;
        },
        addNewEntity: (state, action: PayloadAction<iActions.addNewEntity>) => {
            state.entities.newEntities.push(action.payload);
        },
        eraseNewEntitiesList: (state) => {
            state.entities.newEntities = [];
        },
        addChosenEntity: (state, action: PayloadAction<iActions.addChosenEntity>) => {
            state.entities.chosenEntity = action.payload;
        },
        deleteChosenEntity: (state, action: PayloadAction<iActions.deleteChosenEntity>) => {
            state.entities.newEntities = state.entities.newEntities.filter((entity) => 
                entity.id !== action.payload.id
            );
        },
        setSelectedMarkups: (state, action: PayloadAction<iActions.setSelectedMarkups> ) => {
            state.selectedMarkups.push(action.payload);
        },
        deleteSelectedMarkups: (state, action: PayloadAction<iActions.deleteSelectedMarkups>) => {
            state.selectedMarkups = state.selectedMarkups.filter((markup) => markup !== action.payload);
        },
        setSendEditingStatus: (state, action: PayloadAction<iActions.setSendEditingStatus>) => {
            state.sendEditingStatus = action.payload;
        },
        getLabels: (state) => {
            requestStart(state.labels);
        },
        _getLabelsSuccess: (state, action: PayloadAction<iActions.getLabelsSuccess>) => {
            requestSuccess(state.labels, action.payload);
        },
        _getLabelsError: (state) => {
            requestError(state.labels);
        },
        clearSelectedMarkups: (state) => {
            state.selectedMarkups = [];
        },
        openAddEntityModal: (state) => {
            state.addEntityModal.visible = true;
        },
        closeAddEntityModal: (state) => {
            state.addEntityModal.visible = false;
        },
        eraseState: (state) => {
            state.addEntityModal.visible = false;
            state.editMode = null;
            state.entities.newEntities = [];
            state.entities.chosenEntity = null;
            state.selectedMarkups = [];
            state.isFrameLoaded = false;
        }
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
