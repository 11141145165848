import Request from "../Request";
import {User} from "./types";
import Endpoints from "./endpoints";

export default {
    getUserInfo: () => {
        return Request.get<User.oGetUserInfo>(`${Endpoints.user.base}`);
    },
    changeUserInfo: (params: User.iChangeUserInfo) => {
        return Request.patch(Endpoints.user.change_userinfo, params);
    },
    changePassword: (params: User.iChangePassword) => {
        return Request.post(Endpoints.user.change_password, {
            old_password: params.old_password,
            new_password: params.new_password
        });
    },
};
