import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./ProjectCard.module.less";
import routes from "@routes";
import {useHistory} from "react-router-dom";
import Delete from "@assets/Icons/Delete/Delete";
import Edit from "@assets/Icons/Edit/Edit";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import Scrollbars from "react-custom-scrollbars";
import {DropdownMenu} from "@components/Controls";
import {MenuModuleItem} from "@components/Controls/DropdownMenu/Modules/MenuModule/MenuModule";
import FavoriteStar from "../../Components/FavoriteStar/FavoriteStar";
import getDate from "@root/Utils/Date";
import {Projects} from "@types";

type ProjectCardProps = {
    item: Projects.Item,
    disabledRate: boolean,
    menuView: boolean,
}

const ProjectCard = (props: ProjectCardProps) => {
    const {item, disabledRate, menuView} = props;
    const dispatch = useAppDispatch();
    const history = useHistory();

    const changeRate = (value: boolean) => {
        dispatch(
            Actions.Projects.setFavorite({
                project_id: item.id,
                favorite: value,
            })
        );
    };

    const dropDownMenuData: Array<MenuModuleItem> = [
        {
            icon: <Edit />,
            title: "Редактировать",
            function: () => dispatch(Actions.Projects.setEditModal(true)),
        },
        {
            icon: <Delete />,
            title: "Удалить проект",
            function: () => dispatch(Actions.Projects.setDeleteModal(true)),
        },
    ];

    return (
        <>
            <div
                onClick={() => {
                    history.push(routes.lk.project.documents(item.id));
                }}
                className={styles.wrapper}>
                <div className={styles.header}>
                    <div
                        className={styles.icon}
                        style={{backgroundColor: item.color}}>
                        {item.title
                            .replace(/[^a-zA-Z0-9а-яА-Я]/g, "")
                            .slice(0, 2)
                            .toUpperCase()}
                    </div>
                    <div className={styles.rightSide}>
                        <div className={styles.date}>
                            {getDate.short({date: item.created_date})}
                        </div>
                        <div
                            className={styles.rate}
                            onClick={(event) => {
                                event.stopPropagation();
                                !disabledRate && changeRate(!item.favorite);
                            }}>
                            <FavoriteStar active={item.favorite}/>
                        </div>
                        {menuView && (
                            <DropdownMenu
                                menuItems={dropDownMenuData}
                                handlePopoverVisibleExpand={() =>
                                    dispatch(Actions.Projects.setSelectedProject(item))
                                }
                            />
                        )}
                    </div>
                </div>
                <Title
                    className={styles.title}
                    variant="h2">
                    {item.title}
                </Title>
                <div className={styles.desc}>
                    <Scrollbars autoHide>{item.description ? item.description : null}</Scrollbars>
                </div>
                <div className={styles.info}>
                    <div className={styles.text}>Документы:</div>
                    <div className={styles.value}>{item.docs_count ? item.docs_count : 0}</div>
                </div>
            </div>
        </>
    );
};

export default ProjectCard;
