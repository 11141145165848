import React from "react";
import styles from "./HeadBar.module.less";
import TableCreateTemplateButton from "../../Components/TableCreateTemplateButton/TableCreateTemplateButton";
import {useAppSelector, useGetValidNoun} from "@root/Hooks";

const nounTypes = {
    type1: "шаблон",
    type2: "шаблона",
    type3: "шаблонов"
};

const HeadBar = () => {
    const templatesLength = useAppSelector((state) => state.Templates.templates.data?.length);
    const noun = useGetValidNoun({
        nounTypes,
        number: templatesLength
    });

    return (
        <div className={styles.wrapper}> 
            <div className={styles.titleBlock}>
                <span className={styles.title}>
                    Шаблоны
                </span>
                <div className={styles.templatesCountBlock}>
                    {`${templatesLength ? templatesLength : 0} ${noun}`}
                </div>
            </div>

            <TableCreateTemplateButton  />
        </div>
    );
};

export default HeadBar;
