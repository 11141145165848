import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import {Menu} from "antd";
import styles from "./SidebarItem.module.less";
import classNames from "classnames";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import Icon from "@ant-design/icons";

type SidebarItemProps = {
    icon: JSX.Element;
    tooltipTitle: string;
    disabled?: boolean
    path?: string;
}
// TODO переписать логику работы с роутами на потенциальное усложнение вложенности
// TODO текущие роуты это покрыть не могут тк там много зависимости от id 
// TODO возможно есть смысл вынести это в enum/type и отдать как пример выбора
// TODO в дальшейнем требования к ним будут менятся !пока cырая версия!
const SidebarItem = (props: SidebarItemProps) => {
    const location = useLocation();
    const disabled = props.disabled ? props.disabled : false;
    const path = props.path ? props.path : "";
    // TODO продумать для вложенности *варианты выше*
    const isActive = location.pathname.includes(path) && path !== "";
    return (
        <Tooltip
            className={styles.tooltip}
            placement="right"
            title={disabled ? "Пока недоступно" : props.tooltipTitle}>
            <span>
                <div
                    className={classNames(
                        styles.sidebarItem,
                        {[styles.sidebarItemActive]: isActive},
                        {[styles.sidebarItemDisabled]: disabled || path === ""}
                    )}
                >
                    {props.icon}
                    <NavLink 
                        className={classNames(
                            {[styles.linkActive]: isActive}
                        )}
                        to={path} 
                    />

                </div>
            </span>
        </Tooltip>
    );
};

export default SidebarItem;
