import wrapWithSpan from "./wrapWithSpan";

type applyStyleHighlightProps = {
    container: Node,
    startOffset: number,
    endOffset: number,
    markup_id: number,
}

const getHighlight = (props: applyStyleHighlightProps) => {
    const {container, startOffset, endOffset, markup_id} = props;

    let spanHighlight;
    const text = container.textContent;
    const parent = container.parentNode;

    if (text) {
        if (text !== " ") {
            const content = text.substring(startOffset, endOffset);
            // Create text node that will be highlighted
            const highlitedNode = container.ownerDocument?.createTextNode(content);
            // Split the container in three parts
            const noseNode = container.cloneNode();
            const tailNode = container.cloneNode();
            // Add all the text BEFORE selection
            noseNode.textContent = text.substring(0, startOffset);
            tailNode.textContent = text.substring(endOffset, text.length);

            const textFragment = container.ownerDocument?.createDocumentFragment();

            if (highlitedNode) spanHighlight = wrapWithSpan({
                node: highlitedNode,
                markup_id
            });
            
            if (noseNode.textContent.length) textFragment?.appendChild(noseNode);
            if (spanHighlight) textFragment?.appendChild(spanHighlight);
            if (tailNode.textContent.length) textFragment?.appendChild(tailNode);

            if (textFragment) parent?.replaceChild(textFragment, container);
        }
    }

    return spanHighlight;
};

export default getHighlight;
