import React from "react";
import styles from "./Logo.module.less";
import LogoText from "./Icons/TextLogo";
import IconLogo from "./Icons/IconLogo";

const Logo = () => {
    return (
        <div className={styles.logo}>
            <IconLogo />
            <LogoText />
        </div>
    );
};

export default Logo;
