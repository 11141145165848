type iAuthUserInfo = {
    email: string;
    auth: boolean;
    user_id: number;
};

type iUnauthUserInfo = {
    auth: boolean;
};

export type iUserInfo = iUnauthUserInfo | iAuthUserInfo;

export const Guard = {
    User: {
        isAuthUserInfo: (info: iUserInfo | null): info is iAuthUserInfo =>
            !!(info && "email" in info),
    }
};
