import React, {useState} from "react";
import styles from "./DropdownMenu.module.less";
import {Popover} from "antd";
import {ButtonIcon} from "@root/Components/Controls";
import {MoreOutlined} from "@ant-design/icons";
import MenuModule, {MenuModuleItem} from "./Modules/MenuModule/MenuModule";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import {TooltipPlacement} from "antd/es/tooltip";
import {App} from "@types";

type DropdownMenuProps = {
    handlePopoverVisibleExpand?: Function;
    menuItems: MenuModuleItem[];
    icon?: React.ReactNode;
    loading?: boolean;
    tooltip?: string;
    tooltipPlacement?: TooltipPlacement;
    customButton?: App.children
}

const DropdownMenu = (props: DropdownMenuProps) => {

    const [popover, setPopover] = useState<boolean>(false);

    const hidePopover = () => {
        setPopover(false);
    };
    const handlePopoverVisible = (visible: boolean) => {
        props.handlePopoverVisibleExpand && props.handlePopoverVisibleExpand();
        setPopover(visible);
    };

    return (
        <Popover
            key="more"
            content={
                <MenuModule 
                    hidePopover={hidePopover} 
                    menuItems={props.menuItems}/>
            }
            trigger="click"
            open={popover}
            onOpenChange={handlePopoverVisible}
            placement="bottomLeft"
            className={styles.popover}>
            <Tooltip
                placement={props.tooltipPlacement ?? "bottomLeft"}
                title={props.tooltip}>
                {
                    props.customButton 
                        ? props.customButton
                        : <ButtonIcon
                            type="text"
                            icon={props.icon ? props.icon 
                                : <MoreOutlined 
                                    style={{
                                        fontSize: 20,
                                        lineHeight: 0
                                    }}/>}
                            loading={props.loading}
                            onClick={(e) => e.stopPropagation()}
                            className={styles.button}
                        />
                }
            </Tooltip>
        </Popover>
    );
};

export default DropdownMenu;
