import rootSagaCreator from "../rootSagaCreator";
import UserLoginSaga from "./UserLoginSaga";
import UserRecoverySaga from "./UserRecoverySaga";
import UserRegistrationSaga from "./UserRegistrationSaga";
import UserResetPassword from "./UserResetPassword";
import UserLogoutSaga from "./UserLogoutSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        UserLoginSaga,
        UserRecoverySaga,
        UserRegistrationSaga,
        UserResetPassword,
        UserLogoutSaga
    ], "AUTH");
}
