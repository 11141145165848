import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import {getFullState, requestStart, requestError, requestSuccess} from "@root/Utils/Redux/getRequestState";

const initialState:iState.Value  = {
    projects: getFullState(),
    sorted: false,
    sort: {
        sorted: false,
        variant: "default",
        dates: {
            from: null,
            to: null
        }
    },
    selectedProject: getFullState(),
    createModal: false,
    createFetching: false,
    deleteModal: false,
    editModal: false,
    viewMode: "cards"
};

const Slice = createSlice({
    initialState,
    name: "Projects",
    reducers: {
        createProject: (state, action:PayloadAction<iActions.createProject>) => {
            state.createFetching = true;
        },
        _createProjectSuccess: (state, action:PayloadAction<iActions._createProjectSuccess>) => {
            state.createFetching = false;
            if (state.projects.data) state.projects.data.unshift(action.payload);
            else state.projects.data = [action.payload];
        },
        _createProjectError: (state) => {
            state.createFetching = false;
        },
        getSelectedProject: (state, action:PayloadAction<iActions.getSelectedProject>) => {
            requestStart(state.selectedProject);
        },
        _getSelectedProjectSuccess: (state, action:PayloadAction<iActions._getSelectedProjectSuccess>) => {
            requestSuccess(state.selectedProject, action.payload);
        },
        _getSelectedProjectError: (state) => {
            requestError(state.selectedProject);
        },
        setSelectedProject: (state, action: PayloadAction<iActions.setSelectedProject>) => {
            state.selectedProject.data = action.payload;
        },
        setCreateModal: (state, action: PayloadAction<iActions.setCreateModal>) => {
            state.createModal = action.payload;
        },
        getProjects: (state) => {
            requestStart(state.projects);
        },
        _getProjectsSuccess: (state, action:PayloadAction<iActions._getProjectsSuccess>) => {
            requestSuccess(state.projects, action.payload);
        },
        _getProjectsError: (state) => {
            requestError(state.projects);
        },
        deleteProject: (state, action:PayloadAction<iActions.deleteProject>) => {
            return state;
        },
        _deleteProjectSuccess: (state, action:PayloadAction<iActions.deleteProject>) => {
            if (state.projects.data) {
                state.projects.data = state.projects.data.filter((item) => item.id !== action.payload.id);
            }
        },
        _deleteProjectError: (state) => {
            return state;
        },
        setDeleteModal: (state, action:PayloadAction<iActions.setDeleteModal>) => {
            state.deleteModal = action.payload;
        },
        setEditModal: (state, action:PayloadAction<iActions.setEditModal>) => {
            state.editModal = action.payload;
        },
        setFavorite: (state, action: PayloadAction<iActions.setFavorite>) => {
            return state;
        },
        _setFavoriteSuccess: (state, action: PayloadAction<iActions.setFavorite>) => {
            if (!state.projects.data) return state;
            state.projects.data.forEach((item) => {
                if (item.id === action.payload.project_id) {
                    item.favorite = action.payload.favorite;
                }
            });
        },
        _setFavoriteError: (state) => {
            return state;
        },
        changeProjectInfo: (state, action: PayloadAction<iActions.changeProjectInfo>) => {
            return state;
        },
        _changeProjectInfoSuccess: (state, action: PayloadAction<iActions._createProjectSuccess>) => {
            if (!state.projects.data) return state;
            state.projects.data.forEach((item) => {
                if (item.id === action.payload.id) {
                    item.title = action.payload.title;
                    item.description = action.payload.description;
                }
            });
        },
        _changeProjectInfoError: (state) => {
            return state;
        },
        setSort: (state, action: PayloadAction<iActions.setSort>) => {
            state.sort = {...state.sort,
                ...action.payload};
        },
        setViewMode: (state, action: PayloadAction<iActions.setViewMode>) => {
            state.viewMode = action.payload;
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
