import React from "react";
import PasswordChangeForm from "./Components/PasswordChangeForm/PasswordChangeForm";
import Title from "@root/Components/Title/Title";
import styles from "./PasswordChange.module.less";

const PasswordChange = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                variant="h2"
                className={styles.title}>
                Смена пароля
            </Title>
            <PasswordChangeForm />
        </div>
    );
};

export default PasswordChange;
