import {AxiosRequestConfig} from "axios";
import Cookies from "universal-cookie";
import endpoints from "../endpoints";
import refreshToken from "./Utils/RefreshToken";

const cookies = new Cookies();

const authHeaderInterceptor = async (config: AxiosRequestConfig) => {
    if (config.url === endpoints.authorization.refresh) return config;
    await refreshToken();
    const accessToken = cookies.get("access_token");
    if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    }
    delete config.headers.Authorization;
    return config;
};

export default authHeaderInterceptor;
