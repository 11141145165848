import React from "react";
import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import Api from "../../Api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const GetAnonHtml = function* (action: PayloadAction<iActions.getAnonHtml>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        const {data} = yield call(Api.Anonymize.getAnonHtml, payload);
        yield put(Actions.Anonymize._getAnonHtmlSuccess(data));
    } catch (error: any) {
        yield put(Actions.Anonymize._getAnonHtmlError());
        notification({
            type: "error",
            message: error.response.data.error 
                ? error.response.data.error 
                : "При получении HTML документа произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Anonymize.getAnonHtml, GetAnonHtml);
}
