import Axios, {AxiosRequestConfig} from "axios";
import Qs from "qs";
import authHeaderInterceptor from "./AuthApi/Interceptors/AuthHeaderInterceptor";
import errorInterceptor from "./AuthApi/Interceptors/ErrorInterceptor";

const localAxios = Axios.create({
    baseURL: "/api",
    paramsSerializer: (params) => Qs.stringify(params, {arrayFormat: "repeat"})
});

localAxios.interceptors.request.use(authHeaderInterceptor);
localAxios.interceptors.response.use((response) => response, (error) => errorInterceptor(error, localAxios));

export default {
    post: <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
        return localAxios
            .post<T>(url, data, config);
    },
    get: <T>(url: string, params?: any, config?: AxiosRequestConfig) => {
        return localAxios
            .get<T>(
                url, 
                {
                    params,
                    ...config
                }
            );
    },
    put: <T>(url: string, params?: any) => {
        return localAxios
            .put<T>(
                url, 
                params
            );
    },
    delete: <T>(url: string, params?: any, config?: AxiosRequestConfig) => {
        return localAxios
            .delete<T>(
                url, 
                {
                    params,
                    ...config
                }
            );
    },
    patch: <T>(url: string, params?: any) => {
        return localAxios
            .patch<T>(
                url, 
                params
            );
    },
};
