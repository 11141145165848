import {Button} from "@root/Components/Controls";
import React from "react";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import styles from "./TableCreateTemplateButton.module.less";
import Plus from "./Icons/Plus";

const TableCreateTemplateButton = () => {
    const dispatch = useAppDispatch();
    const showCreateTemplateModal = () => {
        dispatch(Actions.Templates.setCreateTemplateModal(true));
    };

    return (    
        <div className={styles.createButton}>
            <Button 
                icon={<Plus />}
                size="large"
                type="primary"
                onClick={showCreateTemplateModal}>
                Создать шаблон
            </Button>
        </div>    
    );
};

export default TableCreateTemplateButton;
