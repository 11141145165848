type getLabelStylesProps = {
    id: number;
    labelText: string;
}

const getLabelStyles = (props: getLabelStylesProps) => {
    const labelStyle = `
        text.label${props.id} {
            position: relative;
            &:after {
                background-color: #383A3E;
                border-radius: 8px 8px 8px 0px;
                box-shadow: 0px 4px 8px rgba(69, 71, 79, 0.18);
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* body fonts */
                color: #FEFEFE;
                content: '${props.labelText}';
                padding: 0 1vw;
                position: absolute;
                visibility: hidden;
                opacity: 0;
                text-indent: 0;
                transition: all 0.2s ease-in-out; 
                width: max-content;
                display: none;
                font-style: normal;
                bottom: 100%;
                margin-left: 8px;
                left: 0;
            }
            &.visible::after {
                display: inline;
            }
            &.revers::after {
                border-radius: 8px 8px 0px 8px;
                right: 10px;
                left: auto;
            }
            &.showEffect::after {
                opacity: 1; 
                visibility: visible;
            }
        }
    `;
    return labelStyle;
};

export default getLabelStyles;
