import Projects from "./Projects/ProjectsPage";
import Project from "./Project";
import DocumentPage from "./Document/Document";
import Anonymization from "./Anonymization/Anonymization";
import Profile from "./Settings/Content/Profile/Profile";
import Settings from "./Settings/Settings";
import Statistics from "./Statistics/Statistics";
import Templates from "./Templates/Templates";

export default  {
    Projects,
    Project,
    DocumentPage,
    Anonymization,
    Profile,
    Settings,
    Statistics,
    Templates
};
