import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";
import {useEffect} from "react";

const autoNotSelectedColumns = [
    "Дата изменения документа",
    "Создатель документа"
];

const usePresetColumnsFilter = () => {
    const dispatch = useAppDispatch();
    const deletedColumns = useAppSelector((state) => state.Common.deletedDocumentsColumns);
    const [deletedDocumentsColumns] = useLocalStorage<string[], string>([], "deleted-documents-columns");

    useEffect(() => {
        if (deletedDocumentsColumns) {
            dispatch(Actions.Common.setDocumentsDeletedColumns([...deletedDocumentsColumns]));
        } else if (deletedColumns === null) {
            dispatch(Actions.Common.setDocumentsDeletedColumns([...autoNotSelectedColumns]));
        }
    }, []);
};

export default usePresetColumnsFilter;
