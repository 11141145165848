import clamp from "./clamp";

type findOnPositionProps = {
    originalIframeBody: HTMLBodyElement,
    position: number,
    borderSide: "right" | "left"
}

const findOnPosition = (props: findOnPositionProps) => {
    const {originalIframeBody, position, borderSide} = props;
    
    const walker = originalIframeBody.ownerDocument.createTreeWalker(
        originalIframeBody,
        NodeFilter.SHOW_ALL
    );

    let lastPosition = 0;
    let currentNode = walker.nextNode();
    let nextNode = walker.nextNode();
    let finishHere = false;

    while (currentNode) {
        const isText = currentNode.nodeType === Node.TEXT_NODE;
        const isBR = currentNode.nodeName === "BR";

        if (isBR) {
            lastPosition += 1;
        }

        if (isText && finishHere) {
            return {
                node: currentNode,
                position: 0
            };
        }

        if (isText) {

            const length = currentNode.textContent?.length;

            if (!length) return;

            if (length + lastPosition >= position || !nextNode) {
                if (borderSide === "right" && length + lastPosition === position && nextNode) {
                    finishHere = true;
                } else {
                    return {
                        node: currentNode,
                        position: isBR ? 0 : clamp({
                            positionDifference: position - lastPosition,
                            max: length
                        }),
                    };
                }
            }
            lastPosition += length;
        }

        currentNode = nextNode;
        nextNode = walker.nextNode();
    }
};

export default findOnPosition;
