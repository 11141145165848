import moment from "moment";

type isServerBuildNewerProps = {
    serverDate: number,
    cacheDate: number
}

const isServerBuildNewer = (props: isServerBuildNewerProps) => {
    const {serverDate, cacheDate} = props;
    const serverDateDateTime = moment(serverDate);
    const cacheDateDateTime = moment(cacheDate);

    if (serverDateDateTime.isAfter(cacheDateDateTime)) return true;
    return false;
};

export default isServerBuildNewer;
