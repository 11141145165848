type useArrowToBeginForIframeProps = {
    window: Window,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
}

const useArrowToBeginForIframe = (props: useArrowToBeginForIframeProps) => {
    const {window, setShow} = props;
    const checkScrollPosition = () => {
        if (window.scrollY !== 0) {
            setShow(true);
            return;
        }
        if (window.scrollY === 0) {
            setShow(false);
        }
    };

    window.onscroll = checkScrollPosition;

    return () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior:"smooth"
        });
    }; 
};

export default useArrowToBeginForIframe;
