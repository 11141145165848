import * as React from "react";

const Close = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.53033 5.46961C6.23744 5.17672 5.76256 5.17672 5.46967 5.46961C5.17678 5.7625 5.17678 6.23738 5.46967 6.53027L10.9393 11.9999L5.46967 17.4696C5.17678 17.7625 5.17678 18.2374 5.46967 18.5303C5.76256 18.8232 6.23744 18.8232 6.53033 18.5303L12 13.0606L17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7625 18.5303 17.4696L13.0607 11.9999L18.5303 6.53027C18.8232 6.23738 18.8232 5.7625 18.5303 5.46961C18.2374 5.17672 17.7626 5.17672 17.4697 5.46961L12 10.9393L6.53033 5.46961Z" fill="currentColor"/>
        </svg>
    );
};

export default Close;
