import {ButtonIcon} from "@root/Components/Controls";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import React from "react";
import styles from "./ButtonChangeMode.module.less";
import classNames from "classnames";
import useGetButtonData from "./Hooks/useGetButtonData";

type ButtonChangeModeProps = {
    type: Type,
    onClick: () => void,
    editModeActive?: boolean,
    disabled?: boolean,
}

export enum Type {
    startEditing = "startEditing",
    save = "save",
}

const ButtonChangeMode = ({
    type,
    onClick,
    editModeActive = false,
    disabled = false,
}: ButtonChangeModeProps) => {
    const buttonData = useGetButtonData(type);

    const click = () => {
        if (!disabled) {
            onClick();
        }
    };

    if (!buttonData) return null;

    if (type === Type.startEditing) buttonData.style = `${classNames(buttonData.style, {[styles.buttonEditActive]: editModeActive})}`;

    return (
        <Tooltip 
            lineTooltip={true}
            placement="right"
            title={buttonData.toolTipTitle}>
            <ButtonIcon   
                size="middle"
                onClick={click}
                className={classNames(buttonData.style, {[styles.disabled]: disabled})}>
                {buttonData.icon}
            </ButtonIcon> 
        </Tooltip>
    );
};

export default ButtonChangeMode;
