import SelectCheckboxesTypes, {OptionGuard} from "../../SelectCheckboxes/types";

type getValueFromOptionsProps = {
    options: string[] | number[] | SelectCheckboxesTypes.LabelValueType[]
}

const getValueFromOptions = (props: getValueFromOptionsProps) => {
    const {options} = props;
    const valueArrayFromOptions =  options.map((option) => {
        if (OptionGuard.isLabelValueType(option)) return option.value;
        else {
            return option;
        }
    });
    return valueArrayFromOptions;
};

export default getValueFromOptions;
