import * as xpath from "xpath-range";

type selectionProps = {
    iframeWindow: Window;
};

const getSelection = (props: selectionProps) => {
    const {iframeWindow} = props;
    if (iframeWindow !== null) {
        const sel = iframeWindow.getSelection();
        if (sel) {
            const range = sel.getRangeAt(0);
            const xPath = xpath.fromRange(range);
            return {
                xPath,
                range,
            };
        }
    }
    return null;
};

export default getSelection;
