type findNodesBetweenProps = {
    startNode: Node,
    endNode: Node,
    root: Node
}

const findNodesBetween = (props: findNodesBetweenProps) => {
    const {startNode, endNode, root} = props;
    // Tree walker creates flat representation of DOM
    // it allows to iterate over nodes more efficiently
    // as we don't need to go up and down on a tree

    // Also we iterate over Text nodes only natively. That's
    // the only type of nodes we need to highlight.
    // No additional checks, long live TreeWalker :)
    const walker = root.ownerDocument?.createTreeWalker(root, NodeFilter.SHOW_ALL);

    // Если нет верхнего уровня логично, что и
    if (!walker) return [];

    // Flag indicates that we're somwhere between `startNode` and `endNode`
    let inRange = false;

    // Here we collect all nodes between start and end
    // including ends
    const nodes: Node[] = [];
    let {currentNode} = walker;

    while (currentNode) {
        if (currentNode === startNode) inRange = true;
        if (inRange && currentNode.nodeType === Node.TEXT_NODE) nodes.push(currentNode);
        if (inRange && currentNode === endNode) break;
        currentNode = walker.nextNode()!;
    }

    return nodes;
};

export default findNodesBetween;
