import React from "react";
import {useAppSelector} from "@root/Hooks";
import styles from "./ChoosenEntity.module.less";

const ChoosenEntity = () => {
    const entity = useAppSelector((state) => state.HTML.entities.chosenEntity);

    if (!entity) return null;

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Выбранный текст</div>
            <div className={styles.value}>{entity.text}</div>
        </div>
    );
};

export default ChoosenEntity;
