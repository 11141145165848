import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";

type useLastPathStorageReturnValue = [string, React.Dispatch<React.SetStateAction<string>>]

const lastPathStorageKey = "lastPath";

const useLastPathStorage = (): useLastPathStorageReturnValue => {
    const [lastPath, setLastPath] = useLocalStorage<string, string>("ready", lastPathStorageKey);
    return [lastPath, setLastPath];
};

export default useLastPathStorage;
