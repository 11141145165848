import React from "react";
import {Alert} from "antd";

const Error = () => {
    return (
        <Alert 
            message="Ошибка при загрузке данных"
            type="error"
        />
    );
};

export default Error;
