const getLkRoutes = (prefix: string = "") => {
    const lkprefix = `${prefix}/lk`;
    const staticRoutes = {
        root: lkprefix,
        projects: `${lkprefix}/projects`,
        settings: `${lkprefix}/settings`,
        // statistics: `${lkprefix}/statistics`,
        templates: `${lkprefix}/templates`
    };
    const result = {
        ...staticRoutes,
        project: {
            root: (name: string = ":projectID") => `${staticRoutes.projects}/${name}`,
            documents: (name: string = ":projectID") => `${staticRoutes.projects}/${name}/documents`,
            document: (
                projectID: string = ":projectID",
                documentID: string = ":documentID"
            ) => `${result.project.root(projectID)}/documents/${documentID}`,
            anonymize: (
                projectID: string = ":projectID",
                documentID: string = ":documentID"
            ) => `${result.project.root(projectID)}/documents/${documentID}/anonymize`,
        }
    };
    return result;
};

export default getLkRoutes;
