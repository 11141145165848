import React from "react";
import styles from "./Logo.module.less";

const Logo = () => (
    <svg width="24" height="24" viewBox="22 10 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.sidebarLogo}>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.9166 23.3451C42.9723 22.778 43.0001 22.1876 43.0001 21.574C43.0001 19.0451 42.489 16.9251 41.4667 15.214C40.4445 13.4921 39.0045 12.1954 37.1469 11.3237C35.2892 10.4412 33.0963 10 30.5682 10H23.0002V33.6001H23.0008C23.8218 33.3802 24.586 33.1786 25.3015 32.9898L25.3017 32.9898C33.5119 30.8238 35.3063 30.3505 42.9165 23.345C42.9165 23.345 42.9166 23.3451 42.9166 23.3451ZM36.8996 17.4093C37.4272 18.5285 37.691 19.9598 37.691 21.7032C37.691 24.2752 37.0809 26.2123 35.8608 27.5144C34.6517 28.8166 32.7996 29.4676 30.3044 29.4676H28.1115V14.1002H30.832C32.3269 14.1002 33.58 14.3692 34.5912 14.9073C35.6135 15.4453 36.3829 16.2794 36.8996 17.4093Z" fill="currentColor"/>
        <g opacity="0" filter="url(#filter0_f_1944_3070)">
            <path d="M23 33.5267C26.6704 25.9043 33.4868 22.9726 42.8875 23.2658" stroke="black" strokeOpacity="0.36" strokeWidth="7"/>
        </g>
        <path d="M23.0003 33.6C33.2624 30.8513 34.6107 30.9979 42.9252 23.339C33.6744 22.4961 25.6969 26.8203 23.0003 33.6Z" fill="#00D98B"/>
        <path d="M23.0003 33.6C33.2624 30.8513 34.6107 30.9979 42.9252 23.339C33.6744 22.4961 25.6969 26.8203 23.0003 33.6Z" fill="url(#paint0_linear_1944_3070)" fillOpacity="0.2"/>
        <defs>
            <filter id="filter0_f_1944_3070" x="0.846558" y="0.745911" width="61.15" height="53.2992" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="9.5" result="effect1_foregroundBlur_1944_3070"/>
            </filter>
            <linearGradient id="paint0_linear_1944_3070" x1="32.9628" y1="24.7682" x2="35.569" y2="29.8715" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F8F8F8"/>
                <stop offset="0.478893" stopColor="#F8F8F8"/>
                <stop offset="1"/>
            </linearGradient>
        </defs>
    </svg>
);

export default Logo;
