import getSelection from "./getSelection";
import highlightRange from "./HighlightRange/highlightRange";
import findNodesBetween from "./findNodesBetween";
import rangeToGlobalOffset from "./RangeToGlobalOffset/rangeToGlobalOffset";
import findRangeNative from "./FindRangeNative/findRangeNative";

export default {
    getSelection,
    findRangeNative,
    highlightRange,
    rangeToGlobalOffset,
    findNodesBetween,
};
