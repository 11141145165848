import Wrapper from "./Components/Wrapper/Wrapper";
import Col from "./Components/Row/Row";
import Tag from "./Components/Tag/Tag";
import Title from "./Components/Title/Title";

const Toolbar = {
    Wrapper,
    Col,
    Tag,
    Title
};

export default Toolbar;
