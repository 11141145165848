import React, {useEffect} from "react";
import {Button, FormItem, Input, Modal} from "@components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import CloseIcon from "@assets/Icons/Close/Close";
import styles from "./EditModal.module.less";
import GridContainer from "@components/GridContainer/GridContainer";
import {Form} from "antd";
import SelectWithSelectAll from "@root/Components/Controls/SelectWithSelectAll/SelectWithSelectAll";
import {iActions} from "@root/Redux/Templates/types";

const EditModal = () => {
    const editModal = useAppSelector((state) => state.Templates.editTemplateModal);
    const editTemplateStatus = useAppSelector((state) => state.Templates.editTemplateStatus);
    const entities = useAppSelector((state) => state.Common.entities.data);
    const selectedTemplate = useAppSelector((state) => state.Templates.selectedTemplate);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => form.resetFields();
    }, [editModal, form]);

    if (!selectedTemplate) return null;
    if (!entities) return null;

    const onCloseModal = () => {
        dispatch(Actions.Templates.setEditModal(false));
    };

    const onChange = () => {
        const data: iActions.editTemplate = {
            id: selectedTemplate.id,
            title: form.getFieldValue("title"),
            entities: form.getFieldValue("entities"),
            description: form.getFieldValue("description"),
        };
        dispatch(Actions.Templates.editTemplate(data));
    };

    return (
        <Modal
            antProps={{
                destroyOnClose: true,
                open: editModal,
                title: "Редактировать шаблон",
                footer: null,
                onCancel: onCloseModal,
                closable: true,
                width: 440,
                getContainer: false,
                closeIcon: <CloseIcon />,
                centered: true,
                forceRender: true,
            }}>
            <GridContainer className={styles.grid}>
                <Form
                    name="EditTemplate"
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    onFinish={onChange}
                >
                    <FormItem
                        name="title"
                        initialValue={selectedTemplate.title}
                        rules={[
                            {
                                required: true,
                                message: "Поле не может быть пустым",
                            },
                        ]}
                        label="Название шаблона">
                        <Input
                            disabled={editTemplateStatus.fetching}
                            placeholder={"Введите название"}
                            maxLength={40}
                            allowClear
                        />
                    </FormItem>
                    <FormItem
                        required={false}
                        name="entities"
                        rules={[{
                            required: true,
                            message: "Выберите сущности"
                        }]}
                        label={
                            <div className={styles.label}>
                                Сущности на обезличивание
                            </div>}> 
                        <SelectWithSelectAll 
                            disabled={editTemplateStatus.fetching}
                            setValue={(value) => form.setFieldsValue({["entities"]: value})}
                            options={entities}
                            initialValue={selectedTemplate.entities}
                            treeSelectProps={{
                                placeholder: "Выберите сущности"
                            }}/>
                    </FormItem>
                    <FormItem
                        className={styles.inputProjectDescription}
                        name="description"
                        initialValue={selectedTemplate.description}
                        label="Комментарий">
                        <Input.TextArea
                            disabled={editTemplateStatus.fetching}
                            autoSize={{
                                minRows: 3,
                                maxRows: 3,
                            }}
                            placeholder={"Напишите комментарий"}
                        />
                    </FormItem>
                    <div className={styles.buttons}>
                        <FormItem className={styles.lastFormItem}>
                            <Button
                                className={styles.backButton}
                                type="stroke"
                                size="large"
                                onClick={onCloseModal}>
                                        Назад
                            </Button>
                        </FormItem>
                        <FormItem className={styles.lastFormItem}>
                            <Button
                                className={styles.button}
                                type="primary"
                                size="large"
                                htmlType="submit">
                                        Сохранить
                            </Button>
                        </FormItem>
                    </div>
                </Form>
            </GridContainer>
        </Modal>
    );
};

export default EditModal;
