import React from "react";
import styles from "./TopToolbar.module.less";
import {Col} from "antd";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Arrow} from "@root/Components/LastBlock/Icons/Arrow";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import Toolbar from "@root/Components/Toolbar/Toolbar";
import Actions from "@actions";
import {Button} from "@root/Components/Controls";
import DownloadIcon from "@root/Assets/Icons/Download/Download";

const TopToolbar = () => {
    const history = useHistory();
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const dispatch = useAppDispatch();

    const downloadDocument = () => {
        if (openedDocument) {
            dispatch(Actions.Document.downloadOriginalDocument({
                documents_ids: [openedDocument.id],
            }));
        }
    };

    return (
        <Toolbar.Wrapper>
            <Toolbar.Col>
                <>
                    <Tooltip overlay="В проект">
                        <Col className={styles.backCol}
                            onClick={() => history.push(routes.lk.project.documents(openedDocument?.project_id))}>
                            <Arrow />
                        </Col>
                    </Tooltip>
                    <Toolbar.Title title={openedDocument?.document_name || ""}/>
                </>
            </Toolbar.Col>
            <Toolbar.Col>
                <Tooltip overlay={"Cкачать"}>
                    <Button 
                        className={styles.downloadButton}
                        type="link" 
                        onClick={downloadDocument} 
                        icon={<DownloadIcon />} />
                </Tooltip>
            </Toolbar.Col>
        </Toolbar.Wrapper>
    );
};

export default TopToolbar;
