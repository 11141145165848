import React from "react";
import styles from "./Title.module.less";
import {Col} from "antd";
import ellipsisString from "@root/Utils/ellipsisString/ellipsisString";

type TitleProps = {
    title: string
}

const Title = (props: TitleProps) => {
    return (
        <Col className={styles.nameCol}>
            {ellipsisString({
                text: props.title,
                type: "component",
                tooltip: true,
                length: 40
            })}
        </Col>
    );
};

export default Title;
