import React from "react";
import {useAppSelector} from "@root/Hooks";
import {Type} from "../ButtonChangeMode";

type useGetToolTipDataProps = {
    disabled: boolean,
    defaultToolTip: string,
    type: Type
}

const useGetToolTipData = (props: useGetToolTipDataProps) => {
    const currentMode = useAppSelector((state) => state.HTML.editMode);
    const {disabled, defaultToolTip} = props;
    
    if ((props.type === Type.save || props.type === Type.stop) && disabled) return "Необходимо зайти в режим изменения";
    if (currentMode !== null && disabled) return "Для изменения режима редактирования завершите работу в предыдущем режиме";
    if (disabled) return "Работа с документом доступна только в режиме оригинала";
    return defaultToolTip;
};

export default useGetToolTipData;
