const getAuthRoutes = (prefix:string = "") => {
    const lprefix = `${prefix}/auth`;
    return {
        login: `${lprefix}/login`,
        registration: `${lprefix}/registration`,
        recovery: `${lprefix}/recovery`,
        root: lprefix,
        reset: (
            uid: string = ":uid",
            token: string = ":token"
        ) => `${lprefix}/reset/${uid}/${token}`,
    };
};

export default getAuthRoutes;
