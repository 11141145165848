import React from "react";

const Template = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7366 2.76175H8.08455C6.00455 2.75375 4.29955 4.41075 4.25055 6.49075V17.3397C4.21555 19.3897 5.84855 21.0807 7.89955 21.1167C7.96055 21.1167 8.02255 21.1167 8.08455 21.1147H16.0726C18.1416 21.0937 19.8056 19.4087 19.8026 17.3397V8.03975L14.7366 2.76175Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.4746 2C14.8888 2 15.2246 2.33579 15.2246 2.75V5.659C15.2246 6.66483 16.0381 7.48066 17.0448 7.484H19.7976C20.2118 7.484 20.5476 7.81979 20.5476 8.234C20.5476 8.64821 20.2118 8.984 19.7976 8.984H17.0436L17.0415 8.984C15.2086 8.97883 13.7246 7.49286 13.7246 5.659V2.75C13.7246 2.33579 14.0604 2 14.4746 2Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5945 10.4639C12.5945 10.0497 12.2587 9.71387 11.8445 9.71387C11.4303 9.71387 11.0945 10.0497 11.0945 10.4639V12.1642H9.39355C8.97934 12.1642 8.64355 12.5 8.64355 12.9142C8.64355 13.3284 8.97934 13.6642 9.39355 13.6642H11.0945V15.3639C11.0945 15.7781 11.4303 16.1139 11.8445 16.1139C12.2587 16.1139 12.5945 15.7781 12.5945 15.3639V13.6642H14.2936C14.7078 13.6642 15.0436 13.3284 15.0436 12.9142C15.0436 12.5 14.7078 12.1642 14.2936 12.1642H12.5945V10.4639Z" fill="currentColor" />
        </svg>
    );
};

export default Template;
