import rootSagaCreator from "../rootSagaCreator";
import UserInfoSaga from "./UserInfoSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import ChangeUserInfoSaga from "./ChangeUserInfoSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        UserInfoSaga,
        ChangePasswordSaga,
        ChangeUserInfoSaga,
    ], "USER");
}
