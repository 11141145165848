import {Key} from "react";

declare namespace SelectCheckboxesTypes {
    type RawValueType = number | string;
    type LabelValueType = {
        key: Key;
        value: RawValueType;
        label?: React.ReactNode;
        halfChecked?: boolean;
    };
    type Option = SelectCheckboxesTypes.LabelValueType | SelectCheckboxesTypes.RawValueType;
    type DefaultValueType = RawValueType | RawValueType[] | LabelValueType | LabelValueType[];
}

const OptionGuard = {
    isLabelValueType: (option: SelectCheckboxesTypes.Option): option is SelectCheckboxesTypes.LabelValueType => 
        (option as SelectCheckboxesTypes.LabelValueType).value !== undefined && 
        (option as SelectCheckboxesTypes.LabelValueType).label !== undefined &&
        (option as SelectCheckboxesTypes.LabelValueType).key !== undefined  
};

export {OptionGuard};
export default SelectCheckboxesTypes;
