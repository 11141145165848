import React from "react";
import {MenuModuleItem} from "@root/Components/Controls/DropdownMenu/Modules/MenuModule/MenuModule";
import {DropdownMenu as DropdownMenuDefault} from "@components/Controls";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import Edit from "@root/Assets/Icons/Edit/Edit";
import Delete from "@root/Assets/Icons/Delete/Delete";
type DropdownMenuProps = {
    id: number
}

const DropdownMenu = (props: DropdownMenuProps) => {
    const {id} = props;
    const dispatch = useAppDispatch();
    const dropDownMenuData: Array<MenuModuleItem> = [
        {
            icon: <Edit />,
            title: "Редактировать",
            function: () => dispatch(Actions.Templates.setEditModal(true)),
        },
        {
            icon: <Delete />,
            title: "Удалить",
            function: () => dispatch(Actions.Templates.setDeleteModal(true)),
        }
    ];

    return (
        <DropdownMenuDefault
            handlePopoverVisibleExpand={() => dispatch(Actions.Templates.setSelectedTemplate(id))} 
            menuItems={dropDownMenuData} />
    );
};

export default DropdownMenu;
