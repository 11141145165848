import React, {useState, useEffect} from "react";
import {Button, Select} from "@root/Components/Controls";
import {Form} from "antd";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {iForm} from "./types";
import styles from "./Content.module.less";
import Actions from "@actions";
import {Anonymization} from "@types";

type ContentProps = {
    onAddEntity: (item: Anonymization.MarkupItem) => void;
};

const Content = (props: ContentProps) => {
    const [formState, setFormState] = useState<Partial<iForm>>({});
    const state = useAppSelector((state) => state.Common.entities);
    const entity = useAppSelector((state) => state.HTML.entities.chosenEntity);
    const addEntityModal = useAppSelector((state) => state.HTML.addEntityModal);

    const [form] = Form.useForm<iForm>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        form.resetFields();
        setFormState({});
    }, [addEntityModal.visible]);

    if (!state.data) return null;

    return (
        <Form<iForm>
            form={form}
            initialValues={formState}
            onValuesChange={(changed, allValues) => setFormState(allValues)}
            onFinish={() => {
                if (!document) return;
                if (!entity) return;
                //Данные
                const values: Partial<iForm> = form.getFieldsValue(true);
                if (!values.entity) return;
                dispatch(
                    Actions.HTML.addNewEntity({
                        ...entity,
                        label: values.entity,
                    })
                );
                dispatch(Actions.HTML.closeAddEntityModal());
                props.onAddEntity({
                    ...entity,
                    label: values.entity,
                });
            }}>
            <Form.Item name="entity">
                <Select
                    className={styles.input}
                    placeholder="Выберите сущность">
                    {Object.entries(state.data).map(([key, value]) => (
                        <Select.Option
                            key={key}
                            value={value}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                shouldUpdate
                noStyle>
                {() => {
                    const values: iForm = form.getFieldsValue(true);
                    if (!values.entity) return null;
                    return (
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit">
                            Добавить
                        </Button>
                    );
                }}
            </Form.Item>
        </Form>
    );
};

export default Content;
