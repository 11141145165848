import React from "react";
import useColumns from "../../Hooks/useColumns";
import Table from "@root/Components/Controls/Table/Table";
import {
    useGetValidNoun,
    useAppDispatch,
    useAppSelector
} from "@root/Hooks";
import styles from "./TemplatesTable.module.less";
import Actions from "@actions";
import EmptyProjects from "@root/Assets/Icons/EmptyProjects/EmptyProjects";

const TemplatesTable = () => {
    const templates = useAppSelector((state) => state.Templates.templates.data);
    const tableConfig = useAppSelector((state) => state.Common.templatesTableConfig);
    const dispatch = useAppDispatch();
    const columns = useColumns();

    const nounTypes = {
        type1: "шаблон",
        type2: "шаблона",
        type3: "шаблонов",
    };

    if (!templates) return null;

    return (
        <Table
            dataSource={templates}
            columns={columns}
            rowKey="id"
            size="small"
            className={styles.table}
            pagination={{
                current: tableConfig ? tableConfig.currentPage : 1,
                defaultPageSize: tableConfig ? tableConfig.defaultPageSize : 10,
                pageSizeOptions: ["10", "20", "50"],
                showSizeChanger: true,
                locale: {items_per_page: ""},
                showTotal: (total) =>
                    `Всего ${total} ${useGetValidNoun({
                        nounTypes,
                        number: total,
                    })}`,
                selectPrefixCls: styles.test,
            }}
            onChange={(config) => {
                if (!config.current || !config.pageSize) return; 
                dispatch(Actions.Common.setTableConfig({
                    config: {
                        currentPage: config.current,
                        defaultPageSize: config.pageSize
                    },
                    table: "templates"
                }));
            }}
            scroll={{
                y: "calc(100vh - 330px)",
                x: true,
            }}
            locale={{emptyText: (<EmptyProjects />)}}
        />
    );
};

export default TemplatesTable;
