import CommonLayout from "./CommonLayout/CommonLayout";
import LkLayout from "./LkLayout/LkLayout";
import DocumentLayout from "./DocumentLayout/DocumentLayout";
import SidebarLayout from "./SidebarLayout/SidebarLayout";
import {App} from "@types";

export type iLayoutProps = {
    children: App.children | App.children[];
    layoutClassname?: string;
    contentClassname?: string;
    headerClassname?: string;
}

export default {
    CommonLayout,
    LkLayout,
    DocumentLayout,
    SidebarLayout,
};

