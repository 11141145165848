import React from "react";
import styles from "./Divider.module.less";
import {Col, Divider as DefaultDivider} from "antd";

const Divider = () => {
    return (
        <Col className={styles.dividerColumn}>
            <DefaultDivider className={styles.divider } />
        </Col>
    );
};

export default Divider;
