import React from "react";

const Info = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.66543 0C3.96914 0 2.53391 0.605621 1.52642 1.6865C0.52439 2.76152 0.000427246 4.24677 0.000427246 5.916V14.084C0.000427246 15.7523 0.521581 17.2377 1.52271 18.3131C2.52949 19.3947 3.96512 20 5.66543 20H14.3334C16.0341 20 17.4702 19.3948 18.4774 18.3133C19.479 17.2378 20.0004 15.7524 20.0004 14.084V5.916C20.0004 4.2476 19.479 2.76222 18.4776 1.68678C17.4706 0.605298 16.0347 0 14.3344 0H5.66543ZM1.50043 5.916C1.50043 4.55823 1.92346 3.46048 2.62368 2.70925C3.31844 1.96388 4.34072 1.5 5.66543 1.5H14.3344C15.6641 1.5 16.6863 1.9642 17.3798 2.70897C18.0789 3.45978 18.5004 4.5574 18.5004 5.916V14.084C18.5004 15.4426 18.0789 16.5402 17.3797 17.291C16.6861 18.0357 15.6637 18.5 14.3334 18.5H5.66543C4.33573 18.5 3.31386 18.0358 2.62064 17.2911C1.92177 16.5403 1.50043 15.4427 1.50043 14.084V5.916ZM14.0902 8.15733C14.3831 7.86444 14.3831 7.38956 14.0902 7.09667C13.7973 6.80378 13.3224 6.80378 13.0296 7.09667L8.81377 11.3125L6.9701 9.46956C6.67714 9.17673 6.20227 9.17683 5.90944 9.46978C5.61661 9.76274 5.61671 10.2376 5.90966 10.5304L8.28366 12.9034C8.57657 13.1962 9.05136 13.1962 9.34421 12.9033L14.0902 8.15733Z" fill="#00CA81"/>
        </svg>
    );
};

export default Info;

