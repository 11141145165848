import clearRecoveryStorage from "@root/Modules/HTML/Modules/RecoveryModal/Utils/clearRecoveryStorage";
import {htmlTypes} from "@root/Modules/HTML/Types/types";

const getRecoveryStatus = () => {
    const location = window.location.pathname;
    // Регулярки чтобы проверить что обновление было со страницы где мы редактируем, чтобы не забивать хранилище в обычном режиме
    const regValidRoutesSingleDoc = /\/lk\/projects\/\d*\/documents\/\d*\/anonymize/;
    const regValidRoutesTaskDoc = /\/lk\/projects\/\d*\/tasks\/\d*\/task-documents\/\d*\/markup|anonymize/;
    const validRoute = regValidRoutesSingleDoc.test(location) || regValidRoutesTaskDoc.test(location);
    const editRecovery = sessionStorage.getItem("editRecovery");

    if (!validRoute) {
        clearRecoveryStorage();
        return false;
    }

    if (!editRecovery) {
        clearRecoveryStorage();
        return false;
    }

    const editRecoveryJSON = JSON.parse(editRecovery) as htmlTypes.editRecovery;
    if (editRecoveryJSON.path !== location) {
        clearRecoveryStorage();
        return false;
    }

    return true;
};

export default getRecoveryStatus;
