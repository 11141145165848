import findGlobalOffset from "./Utils/findGlobalOffset";

type rangeToGlobalOffsetProps = {
    range: Range,
    workIframeBody: HTMLBodyElement
};

const rangeToGlobalOffset = (props: rangeToGlobalOffsetProps) => {
    const {range, workIframeBody} = props;
    const globalOffsets = [
        findGlobalOffset({
            container: range.startContainer,
            position: range.startOffset,
            workIframeBody
        }),
        findGlobalOffset({
            container: range.endContainer,
            position: range.endOffset,
            workIframeBody
        }),
    ];

    return globalOffsets;
};

export default rangeToGlobalOffset;
