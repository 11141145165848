import React, {useState} from "react";
import styles from "./Dropdown.module.less";
import {Popover} from "antd";
import {ButtonIcon} from "@root/Components/Controls";
import {MoreOutlined} from "@ant-design/icons";
import {Documents} from "@types";

type DropdownProps = {
    item: Documents.Item;
}

const Dropdown = (props: DropdownProps) => {
    const [popover, setPopover] = useState<boolean>(false);
    const {item} = props;

    const handlePopoverVisible = (visible: boolean) => {
        setPopover(visible);
    };

    const getAnonType = (anon_type: "dict_anonymization" | "const_anonymization" | "pdf_anonymization" | null) => {
        if (anon_type === "pdf_anonymization") {
            return "Закрашивание";
        } else if (anon_type === "dict_anonymization") {
            return "Замена из словаря";
        } else if (anon_type === "const_anonymization") {
            return "Замена константами";
        } else {
            return "Тип отсутствует";
        }
    };

    const makeContent = () => {
        //TODO Все изменится когда будет 10
        const entities = item.anonymization_requests[item.anonymization_requests.length - 1]?.entities || [];
        const anonymization_type = item.anonymization_requests[item.anonymization_requests.length - 1]?.anonymization_type;
        if (entities.length === 0) {
            return <div className={styles.empty}>Запуск анонимизации не производился</div>;
        }
        return (
            <div className={styles.content} onClick={(event) => event.stopPropagation()}>
                <div className={styles.type}>{getAnonType(anonymization_type)}</div>
                <div className={styles.entities}>
                    <ul>
                        {
                            entities.map((entity, index) => (
                                <li key={index}>{entity}</li>
                            ))
                        }
                    </ul>
 
                </div>
            </div>
        );
    };

    return (
        <Popover
            key="more"
            content={makeContent()}
            trigger="click"
            open={popover}
            onOpenChange={handlePopoverVisible}
            placement="bottomLeft"
            overlayClassName={styles.popover}>
            <ButtonIcon
                type="text"
                icon={
                    <MoreOutlined 
                        style={{
                            fontSize: 20,
                            lineHeight: 0,
                            transform: "rotate(90deg)"
                        }}/>
                }
                onClick={(e) => e.stopPropagation()}
                className={styles.button}
            />
        </Popover>
    );
};

export default Dropdown;
