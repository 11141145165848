import React, {useEffect} from "react";
import ContentController from "./Controllers/ContentController";
import useTitle from "@root/Hooks/useTitle/useTitle";
import DeleteModal from "./Modules/DeleteModal/DeleteModal";
import EditModal from "./Modules/EditModal/EditModal";
import CreateModal from "./Modules/CreateModal/CreateModal";
import Actions from "@actions";
import {useAppDispatch} from "@root/Hooks";

const TemplatesPage = () => {
    useTitle("DeepDocs - Шаблоны");
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(Actions.Common.getEntities());
        dispatch(Actions.Templates.getTemplates());
        return () => {
            dispatch(Actions.Templates.stopGetTemplates());
        };
    }, []);

    return (
        <>
            <ContentController />
            <CreateModal />
            <EditModal />
            <DeleteModal />
        </>
    );
};

export default TemplatesPage;
