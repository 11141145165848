import React, {lazy, Suspense} from "react";
import {PageProps} from "react-pdf";

const Page = lazy(() => import("react-pdf/dist/esm/entry.webpack").then((module) => ({default: module.Page}))); 

type PagePDFProps = {
    index: number,
    pageProps?: PageProps
}

const PagePDF = (props: PagePDFProps) => {
    const {index, pageProps} = props;
    return (
        <Suspense fallback="loading...">
            <Page
                loading={""}
                {...pageProps}
                renderMode="svg"
                pageNumber={index + 1}/>
        </Suspense>

    );
};

export default PagePDF;
