import rootSagaCreator from "../rootSagaCreator";
import AnonymizeWatchFlow from "./AnonymizeWatchFlow";
import AnonymizeFile from "./AnonymizeFile";
import ConfirmVerification from "./ConfirmVerification";
import GetColoredPdf from "./GetColoredPdf";
import GetAnonHtml from "./GetAnonHtml";
import DownloadAnonymizeDocumentsResultSaga from "./DownloadAnonymizeDocumentsResultSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        AnonymizeWatchFlow,
        AnonymizeFile,
        ConfirmVerification,
        GetColoredPdf,
        GetAnonHtml,
        DownloadAnonymizeDocumentsResultSaga
    ], "ANONYMIZE");
}
