import React from "react";
import {useAppSelector} from "@root/Hooks";
import Empty from "@root/Modules/Empty/Empty";
import Projects from "../Contents/Projects/Projects";
import EmptyProjects from "@root/Assets/Icons/EmptyProjects/EmptyProjects";
import Loading from "@root/Components/Loading/Loading";
import ProjectsTable from "../Contents/ProjectsTable/Views/ProjectsTable/ProjectsTable";

const ContentController = () => {
    const projects = useAppSelector((state) => state.Projects.projects.data);
    const getFetching = useAppSelector((state) => state.Projects.projects.fetching);
    const viewMode = useAppSelector((state) => state.Projects.viewMode);

    if (getFetching) return <Loading />;
    if (projects && projects.length > 0) {
        if (viewMode === "table") {
            return <ProjectsTable />;
        } 
        if (viewMode === "cards") {
            return <Projects />;
        }
    }

    if (!projects || projects.length === 0) return (
        <Empty 
            description="У вас пока нет проектов"
            image={<EmptyProjects />} />
    );
    return null;
};

export default ContentController;
