type clampProps = {
    positionDifference: number,
    max: number,
}

const clamp = (props: clampProps) => {
    const {positionDifference, max} = props;
    return Math.min(max, Math.max(0, positionDifference));
};

export default clamp;
