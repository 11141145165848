import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Auth/types";
import React from "react";
import useNotification from "@root/Hooks/useNotification/useNotification";

const UserResetPassword = function* (action: PayloadAction<iActions.userResetPassword>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield call(Api.Auth.reset, payload);
        yield put(Actions.Auth._userResetPasswordSuccess());
        notification({
            type: "info",
            message: "Пароль изменен"
        });
        action.payload.redirect && action.payload.redirect();
    } catch (error) {
        yield put(Actions.Auth._userResetPasswordError());
        notification({
            type: "error",
            message: "При изменении пароля произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Auth.userResetPassword, UserResetPassword);
}
