import {iParams} from "./types";
import Request from "../Request";
import paths from "./endpoints";

export default {
    Anonymize: (params: iParams.Anonymize) => {
        const url =  paths.anonymize;
        return Request.post(
            url,
            params
        );
    },
    anonymizeVerification: (params: iParams.iAnonymizeVerification) => {
        const url = paths.anonymizeVerification.replace("{anonymization_request_id}", params.anonymization_request_id);
        return Request.post<void>(url, params);
    },
    getColoredPdf: (params: iParams.iGetColoredPdf) => {
        const url = paths.getColoredPdf.replace("{docId}", params.document_id);
        return Request.get<iParams.oGetColoredPdf>(
            url,
            undefined,
            {
                responseType: "blob"
            });
    },
    getAnonHtml: (props: iParams.iGetAnonHtml) => {
        const url = paths.getAnonHtml.replace("{docId}", props.docId);
        return Request.get<iParams.oGetAnonHtml>(
            url
        );
    },
    downloadAnonymizeDocumentsResult: (params: iParams.iDownloadAnonymizeDocumentsResult) => {
        const url = paths.downloadAnonymizeDocumentsResult;
        return Request.post(
            url,
            params,
            {
                responseType: "blob"
            }
        );
    },
};
