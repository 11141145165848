type codePointsToCharsProps = {
    node: Node,
    position: number
};

const codePointsToChars = (props: codePointsToCharsProps) => {
    const {node, position} = props;
    
    if (!node) return;
    if (!node.textContent) return;

    const codePoints = node.textContent?.split("").slice(0, position);
    const chars = codePoints.join("").length;

    return {
        node,
        position: chars,
    };
};

export default codePointsToChars;

