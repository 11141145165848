export default {
    documents: {
        uploadDocument: "/v1/projects/{project_id}/documents",
        getDocuments: "/v1/projects/{project_id}/documents",
        getDocumentInfo: "/v1/documents/{document_id}",
        deleteDocuments: "/v1/documents"
    },
    projects: {
        getProjectInfo: "/v1/projects",
        setFavorite: "/v1/projects/{id}/favorite",
    },
};
