import React from "react";

const Error = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#F86262" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 9L9 15" stroke="#F86262" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 9L15 15" stroke="#F86262" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Error;
