import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import UploadDrawer from "./Modules/UploadDrawer/UploadDrawer";
import DocumentsTable from "./Content/DocumentsTable/DocumentsTable";
import GridContainer from "@components/GridContainer/GridContainer";
import styles from "./Documents.module.less";
import Loading from "@components/Loading/Loading";
import {useParams, useLocation} from "react-router-dom";
import {HeadBar} from "./Modules/HeadBar";
import {TableToolBar} from "./Modules/TableToolBar/TableToolBar";
import Breadcrumbs from "@root/Components/Controls/BreadCrumbs/Breadcrumbs";
import Error from "@root/Modules/Error/Error";
import useTitle from "@root/Hooks/useTitle/useTitle";
type iParams = {
    projectID: string;
};

const Documents = () => {
    const dispatch = useAppDispatch();
    const {projectID} = useParams<iParams>();
    const location = useLocation();
    const documents = useAppSelector((state) => state.Project.documents.data);
    const getDocumentsError = useAppSelector((state) => state.Project.documents.error);
    const tableConfig = useAppSelector((state) => state.Common.documentsTableConfig);
    useTitle("DeepDocs - Документы проекта");

    useEffect(() => {
        dispatch(Actions.Common.getEntities());
        dispatch(Actions.Templates.getTemplates());
        dispatch(
            Actions.Projects.getSelectedProject({
                project_id: projectID,
            })
        );
    }, [location]);

    useEffect(() => {
        dispatch(Actions.Project.stopGetDocuments());
        dispatch(Actions.Project.getDocuments({
            project_id: projectID,
            size: tableConfig?.defaultPageSize || 10,
            page: tableConfig?.currentPage || 1,
            order_by: tableConfig?.order_by,
            name: tableConfig?.name
        }));
    }, [tableConfig]);

    useEffect(() => {
        dispatch(Actions.Project.eraseDocuments());
        return () => {
            dispatch(Actions.Project.stopGetDocuments());
            dispatch(Actions.Templates.stopGetTemplates());
        };
    }, []);

    if (getDocumentsError) return <Error>При получении документов произошла ошибка</Error>;
    if (!documents) return <Loading>Документы загружаются...</Loading>;
    return (
        <GridContainer className={styles.wrapper}>
            <>
                <Breadcrumbs />
                <div className={styles.content}>
                    <HeadBar />
                    <TableToolBar />
                    <DocumentsTable />
                </div>
                <UploadDrawer />
            </>
        </GridContainer>
    );
};

export default Documents;
