import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@redux/User/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {Api as ApiTypes} from "@root/Types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const ChangeUserInfo = function* (action: PayloadAction<iActions.changeUserInfo>) {
    const notification = useNotification();
    
    try {
        yield call(Api.User.changeUserInfo, action.payload);
        yield put(Actions.User.getUserInfo());
        yield put(Actions.User._changeUserInfoSuccess());
        notification({
            type: "info",
            message: "Основная информация изменена"
        });
    } catch (error: any) {
        const errorData = error.response.data as ApiTypes.ErrorResponse;
        if (errorData) {
            notification({
                type: "error",
                message: errorData.detail.message,
            });
        } else {
            notification({
                type: "error",
                message: "При изменении Email произошла ошибка"
            });
        }
        yield put(Actions.User._changeUserInfoError());
    }
};

export default function* () {
    yield takeLatest(Actions.User.changeUserInfo, ChangeUserInfo);
}
