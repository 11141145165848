import React, {useRef, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import styles from "./HTML.module.less";
import {htmlUtils} from "@root/Utils";
import * as xpath from "xpath-range";
import AddEntityModal from "./Modules/AddEntityModal/AddEntityModal";
import Actions from "@actions";
import SwitchModeButtons from "./Modules/SwitchModeButtons/SwitchModeButtons";
import useGetSwitchModeButtonsData from "./Hooks/useGetSwitchModeButtonsData";
import Loading from "@root/Components/Loading/Loading";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {ArrowToBegin} from "@root/Components/Controls";
import RecoveryModal from "./Modules/RecoveryModal/RecoveryModal";
import useRecoveryProgress from "./Hooks/useRecoveryProgress";
import {htmlTypes} from "./Types/types";
import clearRecoveryStorage from "./Modules/RecoveryModal/Utils/clearRecoveryStorage";
import setIframeDefaultStyles from "@root/Utils/HTML/setIframeDefaultStyles";
import {Anonymization} from "@types";

const {
    getSelection,
    findRangeNative,
    highlightRange,
    rangeToGlobalOffset,
    findNodesBetween,
} = htmlUtils;

type HTMLProps = {
    htmlDocument: string,
    markup_list: Omit<Anonymization.MarkupItem, "text">[],
    editingDisable: boolean, 
    saveChanges: (props: Pick<Anonymization.DocumentsMarkupsChange, "to_delete" | "to_add">) => void,
} 

const HTML = (props: HTMLProps) => {
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const {htmlDocument, markup_list, editingDisable, saveChanges} = props;
    const editMode = useAppSelector((state) => state.HTML.editMode);
    const newEntities = useAppSelector((state) => state.HTML.entities.newEntities);
    const loadedIframe = useAppSelector((state) => state.HTML.isFrameLoaded);
    const verifyStatus = useAppSelector((state) => state.HTML.sendEditingStatus);
    const scroll = useAppSelector((state) => state.Common.scroll);
    const [iframeLoading, setIframeLoading] = useState<boolean>(false);
    useRecoveryProgress();

    const workIframe = useRef<HTMLIFrameElement>(null);
    const originalIframe = useRef<HTMLIFrameElement>(null);
    const stopLoad = useRef<boolean>(false);

    const checkSpan = (iframeWindow: Window) => {
        const selection = getSelection({iframeWindow});
        if (selection) {
            const {startContainer, endContainer, commonAncestorContainer} = selection.range;
            const nodes = findNodesBetween({
                startNode: startContainer,
                endNode: endContainer,
                root: commonAncestorContainer
            });
            let attribute = false;
            nodes.forEach((item: Node) => {
                if (item.parentElement?.getAttribute("data-label")) {
                    attribute = true;
                }
            });
            if (attribute) {
                notification({
                    type: "error",
                    message: "Вы выбрали текст, который уже размечен"
                });
                return true;
            }
            return false;
        }
        return false;
    };

    const getMaxId = () => {
        const entitiesArray = newEntities.length === 0 ? markup_list : newEntities;
        return entitiesArray.reduce((acc, markup) => {
            let maxId = acc;
            if (maxId < markup.id) {
                maxId = markup.id;
            }
            return maxId;
        }, 0);
    };

    let idEntity = getMaxId();

    const getSelect = (iframeWindow: Window) => {
        const selection = getSelection({iframeWindow});
        const workIframeDoc = workIframe.current?.contentDocument;
        if (selection) {
            const text = selection.range.toString();
            const workIframeBody = workIframeDoc?.getElementsByTagName("body")[0];
            if (!workIframeBody) return; 
            const [start, end] = rangeToGlobalOffset({
                range: selection.range,
                workIframeBody
            });
            const originalIframeDoc = originalIframe.current?.contentDocument;
            const originalIframeBody = originalIframeDoc?.getElementsByTagName("body")[0];
            if (!originalIframeBody) return; 
            const rangeFromOriginalIframe = findRangeNative({
                start,
                end,
                originalIframeBody
            });
            const originalXpath: htmlTypes.xPath = xpath.fromRange(rangeFromOriginalIframe);
            if (
                originalXpath.start != originalXpath.end ||
                originalXpath.startOffset != originalXpath.endOffset
            ) {
                if (!checkSpan(iframeWindow)) {
                    dispatch(
                        Actions.HTML.addChosenEntity({
                            start: originalXpath.startOffset,
                            end: originalXpath.endOffset,
                            xpath_start: originalXpath.start.split("/").join("//"),
                            xpath_end: originalXpath.end.split("/").join("//"),
                            text,
                            id: idEntity += 1,
                        })
                    );
                }
            }
        }
    };

    const onAddEntity = (item: Anonymization.MarkupItem) => {
        const iframeWindow = workIframe.current?.contentWindow;
        if (iframeWindow !== null && iframeWindow !== undefined) {
            const selection = getSelection({iframeWindow});
            const recoveryNewEntitiesString = sessionStorage.getItem("recoveryNewEntities");
            const newItem = {
                item,
                xPath: selection?.xPath as htmlTypes.xPath
            };
            if (!recoveryNewEntitiesString) {
                sessionStorage.setItem("recoveryNewEntities", JSON.stringify([newItem]));
            } else {
                const recoveryNewEntitiesJSON = JSON.parse(recoveryNewEntitiesString) as htmlTypes.recoveryEntity[];
                sessionStorage.setItem("recoveryNewEntities", JSON.stringify([...recoveryNewEntitiesJSON, newItem]));
            }
            //
            const workIframeDoc = workIframe.current?.contentDocument;
            const range = selection?.range;
            const onDelete = () => {
                const recoveryNewEntitiesString = sessionStorage.getItem("recoveryNewEntities");
                if (!recoveryNewEntitiesString) {
                    sessionStorage.setItem("recoveryNewEntities", JSON.stringify([newItem.item.id]));
                } else {
                    const recoveryNewEntitiesJSON = JSON.parse(recoveryNewEntitiesString) as htmlTypes.recoveryEntity[];
                    sessionStorage.setItem("recoveryNewEntities", JSON.stringify([...recoveryNewEntitiesJSON, newItem.item.id]));
                }
                dispatch(Actions.HTML.deleteChosenEntity({
                    id: item.id,
                }));
            };
            if (range && workIframeDoc) highlightRange({
                range,
                markup: item,
                workIframe: workIframeDoc,
                onDelete
            });
            iframeWindow.getSelection()?.removeAllRanges();
        }
    };

    const onIframeLoad = async () => {
        setIframeLoading(true);

        const workIframeDoc = workIframe.current?.contentDocument;
        const body = workIframeDoc?.getElementsByTagName("body")[0];
        const workIframeWindow = workIframe.current?.contentWindow;
        
        if (body != undefined) {
            body.style.padding = "40px 64px";
        }
        const applyHighlighting = async (items: Anonymization.MarkupItem[] | Omit<Anonymization.MarkupItem, "text">[]) => {
            if (!workIframeDoc) return; 
            if (stopLoad.current){
                stopLoad.current = false;
                return;
            }
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (stopLoad.current){
                        stopLoad.current = false;
                        return;
                    }
                    items
                        .slice()
                        .reverse()
                        .forEach((item: Anonymization.MarkupItem | Omit<Anonymization.MarkupItem, "text">) => {
                            if (workIframeDoc) {
                                const startContainer = workIframeDoc
                                    .evaluate(item.xpath_start, workIframeDoc, null, XPathResult.ANY_TYPE, null)
                                    .iterateNext();
                                const endContainer = workIframeDoc
                                    .evaluate(item.xpath_end, workIframeDoc, null, XPathResult.ANY_TYPE, null)
                                    .iterateNext();
                                const range = new Range();

                                if (startContainer && endContainer) {
                                    range.setStart(startContainer, item.start);
                                    range.setEnd(endContainer, item.end);
                                }
                                const onDelete = () => {
                                    dispatch(Actions.HTML.setSelectedMarkups(item.id));
                                };
                                const onCancelDelete = () => {
                                    dispatch(Actions.HTML.deleteSelectedMarkups(item.id));
                                };
                                if (range) highlightRange({
                                    range,
                                    markup: item,
                                    workIframe: workIframeDoc,
                                    onDelete,
                                    onCancelDelete
                                });
                            }
                        });
                    resolve(true);
                }, 0);
            });
        };
          
        const applyHighlightingAsync = async () => {
            for (let i = markup_list.length;i >= 0;i -= 10) {
                const items = markup_list.slice(i - 10 < 0 ? 0 : i - 10, i);
                if (workIframeDoc) {
                    await applyHighlighting(items);
                }
            }
            if (scroll && workIframeWindow) {
                workIframeWindow.scrollTo({top: scroll});
                dispatch(Actions.HTML.setIsFrameLoaded(true));
            } else {
                dispatch(Actions.HTML.setIsFrameLoaded(true));
            }
        };
        await applyHighlightingAsync();
        if (workIframeDoc) setIframeDefaultStyles({workIframeDoc});
        setIframeLoading(false);
    };

    const workIframeWindow = workIframe.current?.contentWindow;
    const body = workIframe.current?.contentDocument?.body;
    if (body && workIframeWindow) {
        const markup_button = body.getElementsByClassName("markup_control_button") as HTMLCollectionOf<HTMLElement>;
        if (editMode === "markuping") {
            body.onmouseup = () => {
                getSelect(workIframeWindow);
            };
        } 
        if (editMode === "editing") {
            for (let i = 0;i < markup_button.length;i += 1) {
                markup_button[i].style.visibility = "initial";
            }
        } 
        if (editMode === null) {    
            for (let i = 0;i < markup_button.length;i += 1) {
                markup_button[i].style.visibility = "hidden";
            }
        }
    }

    const switchModeButtonsData = useGetSwitchModeButtonsData({
        iFrame: workIframe,
        verifyStatus,
        editingDisable,
        saveChanges,
    });

    if (verifyStatus === "success") {
        workIframe.current?.contentWindow?.location.reload();
        dispatch(Actions.HTML.setSendEditingStatus(null));
    }

    useEffect(() => {
        dispatch(Actions.Common.getEntities());
        dispatch(Actions.HTML.setEditMode(null));
        dispatch(Actions.HTML.setIsFrameLoaded(false));
        dispatch(Actions.HTML.closeAddEntityModal());
        return () => {
            clearRecoveryStorage();
            stopLoad.current = true;
            dispatch(Actions.HTML.eraseState());
            dispatch(Actions.HTML.setIsFrameLoaded(false));
        };
    }, []);

    useEffect(() => {
        dispatch(Actions.HTML.eraseNewEntitiesList());
    }, [editMode === "editing"]);

    useEffect(() => {
        dispatch(Actions.HTML.clearSelectedMarkups());
    }, [editMode === "markuping"]);

    useEffect(() => {
        if (iframeLoading) stopLoad.current = true;
        if (workIframeWindow) dispatch(Actions.Common.setScrollPosition(workIframeWindow.scrollY));
    }, [editMode, htmlDocument]);

    return (
        <div className={styles.wrapper}>
            <RecoveryModal 
                onAddEntity={onAddEntity}
                workIframe={workIframe}/>
            {switchModeButtonsData &&
                <SwitchModeButtons {...switchModeButtonsData} />
            }
            <div className={styles.iframeWrapper}>
                <AddEntityModal onAddEntity={onAddEntity} />
                <div className={styles.workIframeWrapper}>
                    <iframe
                        ref={workIframe}
                        srcDoc={htmlDocument}
                        width={"100%"}
                        height={"100%"}
                        className={styles.workIframe}
                        frameBorder="0"
                        onLoad={onIframeLoad}
                    /> 
                    <ArrowToBegin refLink={workIframe} type="iFrame"/>
                </div>
                {!loadedIframe && ( 
                    <div className={styles.iFrameLoader}>
                        <Loading />
                    </div> 
                )}
                <iframe
                    ref={originalIframe}
                    srcDoc={htmlDocument}
                    className={styles.originalIframe}
                />
            </div>
        </div>
    );
};

export default HTML;
