const getStringStatus = (status: number) => {
    if (status === 0 || status === 100) {
        return "В процессе";
    } else if (status === 400) {
        return "Ошибка загрузки";
    } else {
        return "Загружен";
    }
};

export default getStringStatus;
