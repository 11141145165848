import moment from "moment";

type getDateShortProps = {
    date: string;
}

const momentFormat = "DD.MM.YYYY";

const getDateShort = (props: getDateShortProps) => {
    const {date} = props;
    if (date === "Данные отсутствуют" || date === "-") {
        return date;
    } else {
        return moment(date).format(momentFormat);
    }
};

export default getDateShort;
