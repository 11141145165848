import {useAppSelector, useNotification} from "@root/Hooks";

const useGetWarningNotification = () => {
    const status = useAppSelector((state) => state.Project.openedDocument.data?.doc_anonymization_status);
    const notification = useNotification();

    const warningNotification = (value: boolean) => {
        if (status !== 200 && value) {
            notification({
                type: "error",
                message:"Документ находится в состоянии доанонимизации, размеченные данные заменены константами, как только доанонимизация закончится, константы будут заменены на обезличенные сущности",
                duration: 5
            });
        }
    };

    return warningNotification;
};

export default useGetWarningNotification;
