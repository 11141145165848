import React from "react";

const useGetHintContent = () => {
    const const_replacementHint = (
        <div>
            {
                `3 варианта замен на выбор: 
                из словаря (Иван→ Андрей), 
                константами (Иван→ [Имя]),
                закрашиванием (Иван→ ▉▉▉).`
            }
        </div>
    );     
    const configsHint = (
        <div>
            {
                `Вы можете сами указать, какие
                данные нужно обезличить
                или выбрать готовые наборы.`
            }
        </div>
    );
    const chosePipelineHint = (
        <div>
            {
                `Вы можете выбрать набор сущностей
                или отдельные сущности для
                обезличивания.`
            }
        </div>
    );
    return {
        const_replacementHint,
        configsHint,
        chosePipelineHint,
    };
};

export default useGetHintContent;
