import {takeLatest, call, put, delay} from "redux-saga/effects";
import Actions from "@actions";
import Api from "@api";
import {Api as ApiTypes} from "@types";
import React from "react";

const GetLabels = function*() {
    const request = Api.HTML.getLabels;
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data}:ApiTypes.UnboxPromise<ReturnType<typeof request>> = yield call(request);
            yield put(Actions.HTML._getLabelsSuccess(data));
            break;
        } catch (ex) { 
            yield delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield put(Actions.HTML._getLabelsError());
            }
        }
    }
};

export default function* () {
    yield takeLatest(Actions.HTML.getLabels, GetLabels);
}
