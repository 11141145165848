import React, {ReactNode} from "react";
import {notification} from "antd";
import {NotificationPlacement} from "antd/lib/notification";
import Info from "./Icons/Info";
import Close from "./Icons/Close";
import Error from "./Icons/Error";
import Warning from "./Icons/Warning";

const placement: NotificationPlacement = "bottomLeft";
const duration: number = 4;

const closeIcon: ReactNode = <Close />; 
const infoIcon: ReactNode = <Info />;
const errorIcon: ReactNode = <Error />;
const warningIcon: ReactNode = <Warning />;

const notificationConfig = {
    placement,
    closeIcon,
    duration,
    icons: {
        info: infoIcon,
        error: errorIcon,
        warning: warningIcon
    },
    
};

type notificationProps = {
    type: "info" | "error" | "warning",
    message: string,
    duration?: number,
    description?: string
};

const getIcon = (type: "info" | "error" | "warning") => {
    if (type === "error") return notificationConfig.icons.error;
    if (type === "warning") return notificationConfig.icons.warning;
    return notificationConfig.icons.info;
};

const useNotification = () => {
    return (props: notificationProps) => {
        const {message, type, duration, description} = props;
        const icon = getIcon(type);
        notification[type]({
            message,
            icon,
            duration: duration !== undefined ? duration : notificationConfig.duration,
            placement: notificationConfig.placement,
            closeIcon: notificationConfig.closeIcon,
            description: description ? description : null
        });
    };
};

export default useNotification;
