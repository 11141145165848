import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Project/types";
import React from "react";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DocumentUpload = function* (action: PayloadAction<iActions.uploadDocument>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield call(Api.Project.uploadDocument, payload);
        yield put(Actions.Project._uploadDocumentSuccess());
        yield put(Actions.Project.stopGetDocuments());
        payload.onSuccess && payload.onSuccess();
        notification({
            type: "info",
            message: "Документы успешно загружены"
        });
    } catch (error) {
        yield put(Actions.Project._uploadDocumentError());
        notification({
            type: "error",
            message: "При загрузке документов произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Project.uploadDocument, DocumentUpload);
}
