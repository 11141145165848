import React from "react";
import {Layout} from "antd";
import styles from "./UnauthView.module.less";
import Loading from "@components/Loading/Loading";

const UnauthView = () => {
    return (
        <Layout className={styles.unauthView}>
            <Loading>
                    Загрузка...
            </Loading>
        </Layout>
    );
};

export default UnauthView;
