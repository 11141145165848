import {iActions} from "@root/Redux/Anonymize/types";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import {Documents} from "@root/Types";

type useGetSaveChangesProps = {
    anonymization_request_id: Documents.ItemDetail["anonymization_request_id"],
    anonymized_document_id: Documents.ItemDetail["anonymized_document_id"],
    id: string
}

const useGetSaveChanges = (props: useGetSaveChangesProps) => {
    const {anonymization_request_id, anonymized_document_id, id} = props;

    const dispatch = useAppDispatch();

    const saveChanges = (props: Pick<iActions.confirmVerification, "to_delete" | "to_add">) => {
        dispatch(Actions.HTML.setIsFrameLoaded(false));
        dispatch(Actions.Anonymize.confirmVerification({
            ...props,
            anonymization_request_id,
            anonymized_document_id,
            id,
            type: "singleDocument"
        }));
    };
    
    return saveChanges;
};

export default useGetSaveChanges;
