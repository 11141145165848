import * as React from "react";

const Documents = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4" fill="#48B7F7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.3909 6.8442C18.3513 6.83709 18.3104 6.83337 18.2687 6.83337C18.227 6.83337 18.1862 6.83709 18.1466 6.8442H12.4121C10.1319 6.83616 8.26278 8.65389 8.20905 10.9337L8.20886 10.9499V20.763C8.16272 23.0758 9.99731 24.9911 12.3112 25.0426L12.3265 25.0428L12.3427 25.0429L12.3631 25.0431C12.3774 25.0432 12.3973 25.0433 12.4201 25.0428H19.7341C19.7434 25.0428 19.7527 25.0426 19.762 25.0422C22.051 24.9493 23.8549 23.0589 23.8399 20.7678V12.368C23.8399 12.1906 23.7712 12.0199 23.6483 11.8919L19.0054 7.05558C18.8758 6.92054 18.6966 6.8442 18.5094 6.8442H18.3909ZM17.5812 8.2192H12.4082C10.8783 8.21331 9.62395 9.4309 9.58386 10.9585V20.7702L9.5837 20.7852C9.54982 22.3412 10.782 23.6307 12.3371 23.6678L12.3547 23.6679L12.3743 23.6681L12.3855 23.6682C12.3865 23.6682 12.3873 23.6682 12.3878 23.6682C12.3885 23.6681 12.3888 23.6681 12.3887 23.6681C12.3961 23.6679 12.4035 23.6678 12.4109 23.6678H19.7194C21.2623 23.5982 22.4759 22.3212 22.4649 20.7751L22.4648 20.7702L22.4649 13.2354H20.6227L20.6208 13.2354C18.9403 13.2306 17.5812 11.8681 17.5812 10.1875V8.2192ZM21.712 11.8604H20.6238C19.7014 11.8573 18.9562 11.1098 18.9562 10.1875V8.98984L21.712 11.8604ZM12.4597 19.0787C12.4597 18.699 12.7675 18.3912 13.1472 18.3912H18.0972C18.4769 18.3912 18.7847 18.699 18.7847 19.0787C18.7847 19.4584 18.4769 19.7662 18.0972 19.7662H13.1472C12.7675 19.7662 12.4597 19.4584 12.4597 19.0787ZM13.1465 14.9514C12.7668 14.9514 12.459 15.2592 12.459 15.6389C12.459 16.0186 12.7668 16.3264 13.1465 16.3264H16.2228C16.6025 16.3264 16.9103 16.0186 16.9103 15.6389C16.9103 15.2592 16.6025 14.9514 16.2228 14.9514H13.1465Z" fill="#FEFEFE"/>
        </svg>
    );
};

export default Documents;
