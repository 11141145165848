import React from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Radio, RadioChangeEvent} from "antd";
import Actions from "@actions";
import Cards from "@root/Assets/Icons/Projects/Cards/Cards";
import Table from "@root/Assets/Icons/Projects/Table/Table";
import styles from "./ViewSwitch.module.less";
import Icon from "@ant-design/icons/lib/components/Icon";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";

const ViewSwitch = () => {
    const dispatch = useAppDispatch();
    const viewMode = useAppSelector((state) => state.Projects.viewMode);
    const [_, setMode] = useLocalStorage<"cards" | "table", string>("cards", "project-view");

    const onChange = (event: RadioChangeEvent) => {
        if (event.target.value !== "statistics") {
            setMode(event.target.value);
        }
        dispatch(Actions.Projects.setViewMode(event.target.value));
    };
    
    return (
        <Radio.Group
            buttonStyle="solid"
            onChange={onChange}
            value={viewMode}
            className={styles.radio}>
            <Radio.Button
                className={styles.radioButton}
                value="cards">
                <Tooltip overlay="Карточки">
                    <div className={styles.icon}>
                        <Icon component={Cards} />
                    </div>
                </Tooltip>
            </Radio.Button>
            <Radio.Button
                className={styles.radioButton}
                value="table">
                <Tooltip overlay="Таблица">
                    <div className={styles.icon}>
                        <Icon component={Table} />
                    </div>
                </Tooltip>
            </Radio.Button>
        </Radio.Group>
    );
};

export default ViewSwitch;
