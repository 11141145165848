import {Anonymization} from "@types";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import clearRecoveryStorage from "@root/Modules/HTML/Modules/RecoveryModal/Utils/clearRecoveryStorage";

type Result = {
    sendEditing: () => void,
    startEditing: (type: NonNullable<Anonymization.EditMode>) => void,
    stopEditing: () => void,
    isLoading: boolean,
    currentMode: Anonymization.EditMode,
    editingDisable: boolean,
    hasChanges: boolean
} | null

type useGetSwitchModeButtonsDataProps = {
    iFrame: React.RefObject<HTMLIFrameElement>,
    verifyStatus: "error" | "success" | "loading" | null, 
    editingDisable: boolean,
    saveChanges: (props: Pick<Anonymization.DocumentsMarkupsChange, "to_delete" | "to_add">) => void
}

const useGetSwitchModeButtonsData = (props: useGetSwitchModeButtonsDataProps): Result => {
    const dispatch = useAppDispatch();
    const currentMode = useAppSelector((state) => state.HTML.editMode);
    const selectedMarkups = useAppSelector((state) => state.HTML.selectedMarkups);
    const addedEntities = useAppSelector((state) => state.HTML.entities.newEntities);
    const iFrameWindow = props.iFrame.current?.contentWindow;
    const verifyStatus = props.verifyStatus;
    const editingDisable = props.editingDisable;
    const isLoading = verifyStatus === "loading" ? true : false;
    const hasChanges = addedEntities.length !== 0 || selectedMarkups.length !== 0;

    const sendEditing = () => {
        dispatch(Actions.Common.setScrollPosition(0));
        dispatch(Actions.HTML.setIsFrameLoaded(false));
        props.saveChanges({
            to_delete: selectedMarkups,
            to_add: addedEntities,
        });
        clearRecoveryStorage();
    };
    const startEditing = (type: NonNullable <Anonymization.EditMode>) => {
        if (iFrameWindow) dispatch(Actions.Common.setScrollPosition(iFrameWindow.scrollY));
        if (currentMode !== type) {
            dispatch(Actions.HTML.setIsFrameLoaded(false));
            iFrameWindow?.location.reload();
        }
        dispatch(Actions.HTML.setEditMode(type));
    };
    const stopEditing = () => {
        if (iFrameWindow) dispatch(Actions.Common.setScrollPosition(iFrameWindow.scrollY));
        dispatch(Actions.HTML.eraseState());
        iFrameWindow?.location.reload();
        clearRecoveryStorage();
    };

    return {
        startEditing,
        sendEditing,
        stopEditing,
        isLoading,
        currentMode,
        editingDisable,
        hasChanges
    };
};

export default useGetSwitchModeButtonsData;
