// TODO РЕФАКТОРИНГ КОГДА БУДЕТ ГОТОВ ФУНКЦИОНАЛ
import React from "react";
import {useAppSelector} from "@root/Hooks";
import Loading from "@root/Components/Loading/Loading";
import Error from "../Modules/Error/Error";
import {LastDocumentItem} from "@root/Modules/LastDocumentItem/LastDocumentItem";
import LastBlock from "@root/Components/LastBlock/LastBlock";
import Empty from "@root/Modules/Empty/Empty";

const LastDocumentsController = () => {
    const documents = useAppSelector((state) => state.Common.lastDocuments.data);
    const LoadingError = useAppSelector((state) => state.Common.lastDocuments.error);
    const documentsIsLoading = useAppSelector((state) => state.Common.lastDocuments.fetching);
    return (
        <LastBlock
            title="Последние документы">
            {
                documentsIsLoading 
                    ? (<Loading> </Loading>)
                    : documents?.length == 0
                        ? <Empty 
                            description="Здесь будут показаны последние документы, которые вы загружали"
                            typeSmallText/>
                        : LoadingError 
                            ? <Error /> 
                            : (
                                documents?.map((item, key) =>
                                    <LastDocumentItem  
                                        id={item.id}
                                        project_id={item.project_id}   
                                        parseStatus={item.doc_parse_status}                              
                                        key={item.id}
                                        docPdfStatus={item.doc_pdf_status}
                                        documentName={item.document_name}
                                        documentCreateDate={item.created_date.slice(0, 10)} 
                                        documentType={"Документ"} // TODO ПОДУМАТЬ НУЖНО ЛИ НАМ ЭТО (ИСПОЛЬЗОВАЛОСЬ В ФУНКЦИОНАЛЕ ИЗВЛЕЧЕНИЯ ДЛЯ АВТОМАТИЧЕСКОЙ ПОДСТАНОВКИ ТИПА ДОКУМЕНТА)
                                    />
                                )
                                
                            )
            }
        </LastBlock>
    );
};
export default LastDocumentsController;
