import React from "react";
import ContentController from "./Controllers/ContentController";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Profile = () => {
    useTitle("DeepDocs - Профиль");
    
    return (
        <ContentController />
    );
};

export default Profile;
