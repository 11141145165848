import React from "react";
import styles from "./SwitchModeButtons.module.less";
import {Anonymization} from "@types";
import ButtonsBlock from "./Modules/ButtonsBlock/ButtonsBlock";
import ButtonChangeMode, {Type} from "./Components/ButtonChangeMode/ButtonChangeMode";
import {Popconfirm} from "antd";
import CreateTemplateButton from "@root/Pages/Lk/Templates/Components/CreateTemplateButton/CreateTemplateButton";

type SwitchModeBarProps = {
    startEditing: (type: NonNullable<Anonymization.EditMode>) => void,
    stopEditing: () => void,
    sendEditing: () => void,
    currentMode: Anonymization.EditMode,
    isLoading: boolean,
    editingDisable: boolean,
    hasChanges: boolean,
}

const SwitchModeButtons = (props: SwitchModeBarProps) => {
    return (
        <div className={styles.switchModeBar}>
            <ButtonsBlock  {...props} type="markuping"/>
            <ButtonsBlock  {...props} type="editing"/>
            <div className={styles.controlButtons}>
                <ButtonChangeMode 
                    type={Type.save}
                    onClick={props.sendEditing}
                    disabled={!props.currentMode}
                    isLoading={props.isLoading}/>
                <Popconfirm
                    disabled={!props.hasChanges}
                    placement="bottomRight"
                    title="Сохранить изменения?"
                    onConfirm={() => props.sendEditing()}
                    onCancel={() => props.stopEditing()}
                    okText="Да"
                    cancelText="Нет">
                    <ButtonChangeMode 
                        disabled={!props.currentMode}
                        onClick={() => !props.hasChanges && props.stopEditing()}
                        type={Type.stop}/>
                </Popconfirm>
            </div>
            <CreateTemplateButton />
        </div>
    );
};

export default SwitchModeButtons;
