import React from "react";
import styles from "./CreateTemplateButton.module.less";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import {ButtonIcon} from "@root/Components/Controls";
import Template from "@root/Assets/Icons/Template/Template";
import {LoadingOutlined} from "@ant-design/icons";
import classnames from "classnames";

type CreateTemplateButtonProps = {
    active: boolean,
    loading: boolean,
    onClick: () => void,
}

const CreateTemplateButton = (props: CreateTemplateButtonProps) => {
    const {active, loading, onClick} = props;

    return (
        <Tooltip
            placement="right"
            lineTooltip={true}
            title="Создать шаблон на основе задания">
            <ButtonIcon   
                size="middle"
                onClick={onClick}
                className={classnames(
                    styles.CreateTemplateButton, 
                    {[styles.CreateTemplateButtonActive]: active}
                )}>
                {loading ? <LoadingOutlined /> : <Template />}
            </ButtonIcon> 
        </Tooltip>
    );
};

export default CreateTemplateButton;
