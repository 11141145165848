import React from "react";
import styles from "./Reset.module.less";
import Actions from "@actions";
import {Button, FormItem, Input} from "@root/Components/Controls";
import {Form} from "antd";
import {useAppDispatch} from "@root/Hooks";
import Title from "@root/Components/Title/Title";
import Navigation from "../../Modules/Navigation/Navigation";
import routes from "@routes";
import {useParams} from "./Hooks/useParams";
import {useHistory} from "react-router-dom";

const Reset = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [form] = Form.useForm();
    const history = useHistory();

    const redirect = () => {
        history.push(routes.auth.login);
    };

    const onReset = () => {

        dispatch(Actions.Auth.userResetPassword({
            new_password: form.getFieldValue("password"),
            uid: params.uid,
            token: params.token,
            redirect
        }));
    };

    return (
        <div className={styles.wrapper}>
            <Title 
                variant="h1"
                className={styles.title}>
                Восстановление
            </Title>
            <Form
                form={form}
                name="Recovery"
                layout="vertical"
                onFinish={onReset}>
                <FormItem
                    name="password"
                    className={styles.item}
                    label="Пароль"
                    required>
                    <Input.Password 
                        className={styles.input}
                        placeholder="Введите пароль"
                    />
                </FormItem>
                <FormItem
                    name="confirm"
                    className={styles.item}
                    label="Подтверждение пароля"
                    dependencies={["password"]}
                    required
                    rules={[
                        ({getFieldValue}) => ({
                            validator(rule,value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Пароли не совпадают"));
                            },
                        }),
                    ]}>
                    <Input.Password
                        className={styles.input}
                        placeholder="Повторите пароль"
                    />
                </FormItem>
                <div className={styles.controls}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.button}>
                            Сохранить
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Navigation route={routes.auth.login}>
                            Авторизоваться
                        </Navigation>
                    </Form.Item>
                    <Form.Item>
                        <Navigation route={routes.auth.registration}>
                            Зарегистрироваться
                        </Navigation>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default Reset;
