import React from "react";
import {Button, Modal} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import CloseIcon from "@assets/Icons/Close/Close";
import styles from "./DeleteModal.module.less";

const DeleteModal = () => {
    const deleteModal = useAppSelector((state) => state.Templates.deleteTemplateModal);
    const selectedTemplate = useAppSelector((state) => state.Templates.selectedTemplate);
    const deleteTemplateStatus = useAppSelector((state) => state.Templates.deleteTemplateStatus);

    const dispatch = useAppDispatch();
    if (!selectedTemplate) return null;

    const onCloseModal = () => {
        dispatch(Actions.Templates.setDeleteModal(false));
    };
    const onDeleteTemplate = () => {
        dispatch(Actions.Templates.deleteTemplate(selectedTemplate.id));
    };

    return (
        <Modal 
            antProps={{
                open: deleteModal,
                title: "Удалить шаблон?",
                footer: null,
                onCancel: onCloseModal,
                closeIcon: <CloseIcon />,
                centered: true
            }}>
            <div className={styles.modalWrapper}>
                <div className={styles.modalText}>
                    удаление шаблона безвозвратно
                </div>
                <div className={styles.modalButtons}>
                    <Button
                        loading={deleteTemplateStatus.fetching}
                        onClick={onDeleteTemplate}
                        className={styles.modalButton}
                        size="large"
                        type="primary">
                        Удалить
                    </Button>
                    <Button
                        loading={deleteTemplateStatus.fetching}
                        onClick={onCloseModal}
                        className={styles.modalButton}
                        size="large"
                        type="stroke">
                        Отмена
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
