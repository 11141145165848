import React from "react";
import styles from "./CoreToolbar.module.less";
import {Row, Col} from "antd";
import AnonymizeDo from "./Modules/AnonymizeDo/AnonymizeDo";
import PreviousAnonymize from "./Modules/PreviousAnonymize/PreviousAnonymize";

const CoreToolbar = () => (
    <div className={styles.wrapper}>
        <Row
            className={styles.row}
            justify="center" 
            align="middle"
            gutter={8}
            wrap={false}>
            <Col >
                <AnonymizeDo />
            </Col>
            <Col>
                <PreviousAnonymize />
            </Col>
        </Row>
    </div>
);

export default CoreToolbar;
