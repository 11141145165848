import React, {useEffect} from "react";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import LastDocumentsController from "./Controller/LastDocumentsController";

const LastDocuments = () => {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(Actions.Common.getLastDocuments());
        return () => {
            dispatch(Actions.Common.stopGetLastDocuments());
        };
    }, []);

    return (
        <LastDocumentsController />
    );
};

export default LastDocuments;
