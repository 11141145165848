import React from "react";
import Empty from "@root/Modules/Empty/Empty";
import EmptyProjects from "@root/Assets/Icons/EmptyProjects/EmptyProjects";
import TemplatesTable from "../Content/Views/TemplatesTable/TemplatesTable";
import Loading from "@root/Components/Loading/Loading";
import {useAppSelector} from "@root/Hooks";
import GridContainer from "@root/Components/GridContainer/GridContainer";
import HeadBar from "../Modules/HeadBar/HeadBar";

const ContentController = () => {
    const templates = useAppSelector((state) => state.Templates.templates.data);
    const templatesFetching = useAppSelector((state) => state.Templates.templates.fetching);

    if (templatesFetching) return (
        <Loading>
            Шаблоны загружаются...
        </Loading>
    );
    if (templates && templates.length > 0) {
        return (
            <GridContainer>
                <>
                    <HeadBar />
                    <TemplatesTable />
                </>
            </GridContainer>
        );
    }
    if (!templates || templates.length === 0) return (
        <GridContainer>
            <>
                <HeadBar />
                <Empty 
                    description="У вас пока нет шаблонов"
                    image={<EmptyProjects />} />
            </>
        </GridContainer>

    );
    return null;
};

export default ContentController;
