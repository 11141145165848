type findGlobalOffsetProps = {
    container: Node,
    position: number,
    workIframeBody: HTMLBodyElement
};

const findGlobalOffset = (props: findGlobalOffsetProps) => {
    const {container, position, workIframeBody} = props;
    const walker = workIframeBody.ownerDocument?.createTreeWalker(workIframeBody, NodeFilter.SHOW_ALL);

    let globalPosition = 0;
    let nodeReached = false;
    let currentNode = walker.nextNode() as any;

    while (currentNode) {
        // Indicates that we at or below desired node
        nodeReached = nodeReached || container === currentNode;
        const atTargetNode = container === currentNode || currentNode.contains(container);
        const isText = currentNode.nodeType === Node.TEXT_NODE;
        const isBR = currentNode.nodeName === "BR";

        // Stop iteration
        // Break if we passed target node and current node
        // is not target, nor child of a target
        if (nodeReached && atTargetNode === false) {
            break;
        }

        if (isText || isBR) {
            let length = currentNode.textContent !== "" ? currentNode.textContent.length : 1;
            if (atTargetNode) {
                length = Math.min(position, length);
            }

            globalPosition += length;
        }

        currentNode = walker.nextNode();
    }

    return globalPosition;
};

export default findGlobalOffset;
