import React from "react";
import styles from "./Title.module.less";
import {useGetValidNoun, useAppSelector} from "@root/Hooks";

const nounTypes = {
    type1: "проект",
    type2: "проекта",
    type3: "проектов"
};

const Title = () => {
    const projectsLength = useAppSelector((state) => state.Projects.projects.data?.length);
    const noun = useGetValidNoun({
        nounTypes,
        number: projectsLength
    });

    return (
        <div className={styles.titleBlock}>
            <div className={styles.title}> 
                Проекты
            </div>
            <div className={styles.projectCountBlock}>
                {`${projectsLength ? projectsLength : 0} ${noun}`}
            </div>
        </div>
    );
};

export default Title;
