import React from "react";
import Layouts from "@layouts";
import {Switch, Route} from "react-router-dom";
import {App} from "@types";
import routes from "@routes";

export type iLayoutController = {
    children: App.children | App.children[]
};

const LayoutController = (props:iLayoutController) => {
    return (
        <Switch>
            <Route path={routes.lk.project.anonymize()}>
                <Layouts.DocumentLayout>
                    {props.children}
                </Layouts.DocumentLayout>
            </Route>
            <Route path={routes.lk.project.document()}>
                <Layouts.DocumentLayout>
                    {props.children}
                </Layouts.DocumentLayout>
            </Route>
            <Route path={routes.lk.settings}>
                <Layouts.LkLayout>
                    {props.children}
                </Layouts.LkLayout>
            </Route>
            {/* <Route path={routes.lk.statistics}>
                <Layouts.LkLayout>
                    {props.children}
                </Layouts.LkLayout>
            </Route> */}
            <Route path={routes.lk.templates}>
                <Layouts.LkLayout>
                    {props.children}
                </Layouts.LkLayout>
            </Route>
            <Route path={routes.lk.root}>
                <Layouts.LkLayout>
                    {props.children}
                </Layouts.LkLayout>
            </Route>
            <Route path="*">
                <Layouts.CommonLayout>
                    {props.children}
                </Layouts.CommonLayout>
            </Route>
        </Switch>
    );
};

export default LayoutController;
