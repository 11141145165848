import React from "react";
import {Select, SelectProps} from "antd";
import {BaseOptionType, DefaultOptionType} from "antd/lib/select";
import classnames from "classnames";
import styles from "./Select.module.less";

type OptionType = BaseOptionType | DefaultOptionType;

const SelectComponent = (props: SelectProps<any, OptionType>) => {
    return React.createElement(
        Select,
        {
            ...props,
            className: classnames(
                props.className,
                styles.select,
            ),
        },
    );
};

SelectComponent.Option = Select.Option;

export default SelectComponent;
