import React, {useEffect} from "react";
import DocumentController from "./Controllers/DocumentController";
import GridContainer from "@components/GridContainer/GridContainer";
import styles from "./Document.module.less";
import {useAppDispatch} from "@root/Hooks";
import {useParams} from "./Modules/CoreToolbar/Hooks/useParams";
import Actions from "@actions";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Document = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    useTitle("DeepDocs - Просмотр документа");
    
    useEffect(() => {
        dispatch(Actions.Project.getDocumentInfo({id: params.documentID}));
        dispatch(Actions.Projects.getSelectedProject({project_id: params.projectID}));
        dispatch(Actions.Document.getPdfDocument({id: params.documentID}));
        dispatch(Actions.Common.getEntities());
        dispatch(Actions.Templates.getTemplates());
        return (() => {
            dispatch(Actions.Project.eraseOpenedDoc());
            dispatch(Actions.Document.erasePdfDocument());
            dispatch(Actions.Templates.stopGetTemplates());
        });
    }, []);

    return (
        <GridContainer className={styles.wrapper}>
            <DocumentController />
        </GridContainer>
    );
};

export default Document;
