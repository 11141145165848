type ToggleProps = {
    markup_id: number
}

export const showLabel = (props: ToggleProps) => {
    const iFrame = document.getElementsByTagName("iframe")[0];
    if (!iFrame || !iFrame.contentDocument) return;
    const scrollWidthBefore = iFrame.contentDocument.documentElement.scrollWidth;
    const elements = iFrame.contentDocument?.getElementsByClassName(`label${props.markup_id}`);
    if (!elements || elements.length === 0) return; 
    [...elements].reverse()[0].classList.add("visible");
    const scrollWidthAfter = iFrame.contentDocument.documentElement.scrollWidth;
    [...elements].reverse()[0].classList.add("showEffect");
    if (scrollWidthAfter !== scrollWidthBefore) {
        [...elements].reverse()[0].classList.add("revers");
    }
};

export const hideLabel = (props: ToggleProps) => {
    const iFrame = document.getElementsByTagName("iframe")[0];
    if (!iFrame) return;
    const elements = iFrame.contentDocument?.getElementsByClassName(`label${props.markup_id}`);
    if (!elements || elements.length === 0) return; 
    [...elements].reverse()[0].classList.remove("showEffect");
    [...elements].reverse()[0].classList.remove("visible");
    [...elements].reverse()[0].classList.remove("revers");
};
