import React from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {useParams} from "../../Hooks/useParams";
import Icon from "@ant-design/icons";
import Button, {ButtonProps} from "../../Components/Button/Button";
import PreviousAnonymizeIcon from "./Icons/PreviousAnonymize";
import {useHistory} from "react-router-dom";
import getRoute from "@routes";

const PreviousAnonymize = () => {
    const dispatch = useAppDispatch();
    const openedDoc = useAppSelector((state) => state.Project.openedDocument.data);
    const entities = useAppSelector((state) => state.Common.entities);
    const history = useHistory();
    const params = useParams();

    const onClick = () => {
        history.push(getRoute.lk.project.anonymize(
            params.projectID,
            params.documentID
        ));
        dispatch(Actions.Anonymize.closeAnonymizeModal());
    };

    const buttonData: ButtonProps = {
        onClick,
        icon: <Icon component={PreviousAnonymizeIcon} />,
        disabled: entities.error,
        children: "Результат"
    };

    if (openedDoc?.doc_anonymization_status !== 200) return null;

    return <Button {...buttonData} />;
};

export default PreviousAnonymize;
