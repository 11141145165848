import React, {useEffect} from "react";
import {App, Documents} from "@types";
import AnonymizeModal from "./Modules/AnonymizeModal";

type AnonymizeDoProps = {
    anonymizeModalOpen: boolean,
    onCancelModal: () => void,
    anonymizeDoButton: App.children,
    documentsIds: Documents.Item["id"][],
    projectIds: number,
    loading?: boolean,
    onSuccessAction?: () => void,
    onErrorAction?: () => void,
}

const AnonymizeDo = (props: AnonymizeDoProps) => {
    useEffect(() => {
        return () => {
            props.onCancelModal();
        };
    }, []);
    return (    
        <>            
            <AnonymizeModal 
                anonymizeModalOpen={props.anonymizeModalOpen}
                documentsIds={props.documentsIds} 
                onCancelModal={props.onCancelModal}
                projectIds={props.projectIds}
                onSuccessAction={props.onSuccessAction}/>
            {props.anonymizeDoButton}
        </>    
    );
};

export default AnonymizeDo;
