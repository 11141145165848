import {AnyAction} from "@reduxjs/toolkit";
import {htmlTypes} from "@modules/HTML/Types/types";
import Actions from "@actions";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {Dispatch} from "react";
const notificaton = useNotification();

type recoveryEditingProps = {
    recoveryStorageJSON: htmlTypes.editRecovery,
    body: HTMLElement,
    dispatch: Dispatch<AnyAction>,
}

const recoveryEditing = (props: recoveryEditingProps) => {
    const {recoveryStorageJSON, body, dispatch} = props;
    try {
        dispatch(Actions.HTML.setEditMode("editing"));

        const markup_button = body.getElementsByClassName("markup_control_button") as HTMLCollectionOf<HTMLElement>;

        // Проходим по всем кнопкам, если у них есть id и они входят в ранее выбранные - восстанавливаем выбор
        [...markup_button].forEach((button: HTMLElement) => {
            const button_id = button.getAttribute("id");
            if (button_id && recoveryStorageJSON.selectedMarkups.includes(+button_id)) {
                button.click();
            }
        });
    } catch (e) {
        notificaton({
            type: "error",
            message: "Во время восстановления прогресса произошла ошибка"
        });
    }
};

export default recoveryEditing;
