import useQuery from "@root/Hooks/Query/useQuery";
import useSetQueryParam from "@root/Hooks/Query/useSetQueryParam";

const Keys = {
    search: "search"
};

export const useQueries = () => {
    const query = useQuery();
    return {
        search: query.get(Keys.search),
    };
};

export const useSetQueries = () => {
    const querySetter = useSetQueryParam();
    return (key: keyof typeof Keys, value: string|null) => querySetter(Keys[key], value);
};

export default useQueries;
