import React from "react";
import styles from "./Tag.module.less";
import {Col, Tag as DefaultTag} from "antd";

type TagProps = {
    title: string
}

const Tag = (props: TagProps) => {
    return (
        <Col className={styles.tagCol}>
            <DefaultTag color={"rgba(179, 179, 179, 0.1)"}>
                {props.title}
            </DefaultTag>
        </Col>
    );
};

export default Tag;
