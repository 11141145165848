import routes from "@root/Routes/Routes";
import Request from "@root/Api/Request";
import endpoints from "../../endpoints";
import Cookies from "universal-cookie";
import {decode, jwtToken} from "@utils/JWT/decode";
import {Auth} from "../../types";

const cookies = new Cookies();

const refreshTokenInAuthPage = () => {
    return Request.post<Auth.oRefresh>(`${endpoints.authorization.refresh}`)
        .then(({data}) => {
            const decodedAccess:jwtToken = decode(data.access_token);
            cookies.set(
                "access_token",
                data.access_token,
                {
                    // TODO Пока нет SSL сертификата, убираю это
                    // secure: true,
                    expires: new Date(decodedAccess.exp * 1000),
                    path: "/"
                });
            window.location.href = routes.lk.projects;
        });
};

export default refreshTokenInAuthPage;
