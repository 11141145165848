import React from "react";
import Sidebar from "@components/Sidebar/Sidebar";
import {useLocation} from "react-router-dom";
import {Menu} from "antd";
import styles from "./Sidebar.module.less";
import SidebarItem from "./Modules/SidebarItem/SidebarItem";
import Folders from "./icons/Folders";
import Settings from "./icons/Settings";
import ThemeSwitcher from "./Modules/ThemeSwitcher/ThemeSwitcher";
import LogoLink from "./Modules/LogoLink/LogoLink";
import LogoutButton from "./Modules/LogoutButton/LogoutButton";
import Statistics from "@root/Assets/Icons/Projects/Statistics/Statistics";
import Templates from "./icons/Templates";

const SidebarContainer = () => {
    const location = useLocation();
    return (
        <div className={styles.wrapper}>
            <Sidebar>
                <LogoLink />
                <div className={styles.sidebarContent}>
                    <Menu
                        inlineIndent={0}
                        className={styles.menu}
                        mode="inline"
                        selectedKeys={[location.pathname]}
                        items={[{
                            label: <SidebarItem 
                                icon={<Folders />}
                                path={"/lk/projects"}
                                tooltipTitle={"Проекты"}/>,
                            key: "1"
                        },
                        // {
                        //     label: <SidebarItem 
                        //         icon={<Statistics />}
                        //         tooltipTitle={"Статистика"}
                        //         path={"/lk/statistics"}/>,
                        //     key: "2"
                        // },
                        {
                            label: <SidebarItem
                                icon={<Templates />}
                                tooltipTitle={"Шаблоны"}
                                path={"/lk/templates"}/>,
                            key: "3"
                        },
                        {
                            label: <SidebarItem 
                                icon={<Settings />}
                                tooltipTitle={"Настройки"}
                                path={"/lk/settings"}/>,
                            key: "4"
                        }]}/>
                    <div>
                        <ThemeSwitcher />
                        <LogoutButton />
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};

export default SidebarContainer;
