import moment from "moment";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "./types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
} from "@root/Utils/Redux/getRequestState";
import {RequestFullState} from "@root/Utils/Redux/types";
import {Documents} from "@types";

export type iState = {
    documents: RequestFullState<{
        documents: Documents.Item[]
        id: string, // ID проекта
        title: string // title проекта
        count: number, // Всего документов в проекте
        page: number, // Текущая страница просмотра 
        pages: number, // Всего доступно страниц при текущем size для просмотра    
        size: number, // Кол-во элементов на странице для просмотра
        order_by?: string,
        name?: string 
    }>;
    selectedDocuments: Documents.Item[];
    showDrawer: boolean;
    uploadFetching: boolean;
    openedDocument: RequestFullState<Documents.ItemDetail>
    searchQuery: string | null; 
}

const initialState:iState  = {
    documents: getFullState(),
    selectedDocuments: [],
    showDrawer: false,
    uploadFetching: false,
    openedDocument: getFullState(),
    searchQuery: null,
};

const Slice = createSlice({
    initialState,
    name: "Project",
    reducers: {
        changeDrawerView: (state, action: PayloadAction<iActions.changeDrawerView>) => {
            state.showDrawer = action.payload;
        },
        uploadDocument: (state, action: PayloadAction<iActions.uploadDocument>) => {
            state.uploadFetching = true;
        },
        _uploadDocumentSuccess: (state) => {
            state.uploadFetching = false;
        },
        _uploadDocumentError: (state) => {
            state.uploadFetching = false;
        },
        getDocuments: (state, action: PayloadAction<iActions.getDocuments>) => {
            requestStart(state.documents);
        },
        stopGetDocuments: (state) => {
            return state;
        },
        _getDocumentsSuccess: (state, action:PayloadAction<iActions._getDocumentsSuccess>) => {
            requestSuccess(state.documents, action.payload);
        },
        _getDocumentsError: (state) => {
            requestError(state.documents);
        },
        getDocumentInfo: (state, action: PayloadAction<iActions.getDocumentInfo>) => {
            requestStart(state.openedDocument);
        },
        _getDocumentInfoSuccess: (state, action: PayloadAction<iActions._getDocumentInfoSuccess>) => {
            requestSuccess(state.openedDocument, action.payload); 
        },
        _getDocumentInfoError: (state) => {
            requestError(state.openedDocument);
        },
        eraseOpenedDoc: (state) => {
            state.openedDocument = initialState.openedDocument;
        },
        eraseDocuments: (state) => {
            state.documents = initialState.documents;
        },
        setSelectedDocuments: (state, action: PayloadAction<iActions.setSelectedDocuments>) => {
            state.selectedDocuments = action.payload;
        },
        eraseSelectedDocuments: (state) => {
            state.selectedDocuments = [];
        },
        deleteDocuments: (state, action: PayloadAction<iActions.DeleteDocuments>) => {
            return state;
        },
        // ВЫПОЛНЯТЬ ПЕРЕЗАПРОС ДАННЫХ НА БЕКЕ (НЕОБХОДИМО УЧИТЫВАТЬ СИТУАЦИИ КОГДА МЫ УДАЛЯЕМ ВСЕ ДОКУМЕНТЫ НА СТРАНИЦЕ - ТОГДА СДВИГАЕМ СТРАНИЦУ в -1)
        _deleteDocumentsSuccess: (state, action: PayloadAction<iActions._DeleteDocumentsSuccess>) => {
            // requestSuccess(state.documents, action.payload);
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
