import {AxiosError, AxiosInstance} from "axios";
import Auth from "../Auth";
import endpoints from "@root/Api/UserApi/endpoints";
import refreshTokenInAuthPage from "./Utils/RefreshTokenInAuthPage";

let refreshTokenRequestInAuth: Promise<void> | null = null;
let refreshTokenRequestInLk: Promise<void> | null = null;
let refreshAuthMeState: boolean = false; // была ли попытка рефреша на auth в случае ошибки /me

const errorInterceptor = async (error: AxiosError, axiosInstance: AxiosInstance) => {
    if (
        error.response?.config.url === endpoints.user.base && 
        error.response.status === 401 && 
        !refreshAuthMeState
    ) {
        refreshAuthMeState = true; // попытка рефреша на 401 от /me была выполнена
        if (refreshTokenRequestInAuth === null) refreshTokenRequestInAuth = refreshTokenInAuthPage();
        await refreshTokenRequestInAuth;
        if (typeof refreshTokenRequestInAuth === "object") refreshTokenRequestInAuth = null;
    } else if (error.response && error.response.status === 401) {
        if (refreshTokenRequestInLk === null) refreshTokenRequestInLk = Auth.refresh();
        await refreshTokenRequestInLk;
        if (typeof refreshTokenRequestInLk === "object") refreshTokenRequestInLk = null;
    }
    return Promise.reject(error);
};

export default errorInterceptor;
