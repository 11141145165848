import {useHistory} from "react-router-dom";
import useQuery from "./useQuery";

const useSetQueryParam = () => {
    const query = useQuery();
    const history = useHistory();

    return (key: string, value: string|null) => {
        if (value) query.set(key, value);
        else query.delete(key);
        history.replace(`?${query.toString()}`);
    };
};

export default useSetQueryParam;
