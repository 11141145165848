import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import styles from "./Loading.module.less";

const Loading = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>
                <LoadingOutlined /> 
            </div>
            Загрузка данных...
        </div>
    );
};

export default Loading;
