import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import "./index.less";
import App from "./App";
import {Provider} from "react-redux";
import store from "./Redux/store";
import CheckUserContainer from "./Containers/CheckUserContainer/CheckUserContainer";
import useLocalStorage from "./Hooks/useLocalStorage/useLocalStorage";
import useGetCheckCacheComponent from "./Hooks/useGetCheckCacheComponent/useGetCheckCacheComponent";
import useCheckUserBrowser from "./Hooks/useCheckUserBrowser/useCheckUserBrowser";
import {useNotification} from "./Hooks";
import useSaveLastPath from "./Hooks/useSaveLastPath/useSaveLastPath";

const RootApp: React.FC = () => {
    const {acceptable, acceptableMessage, acceptableDesc} = useCheckUserBrowser();
    const notification = useNotification();
    const [theme] = useLocalStorage<string, string>("light", "theme");
    useSaveLastPath();
    const browserCheck = sessionStorage.getItem("browser-check");

    if (!acceptable && browserCheck !== "true" && location.pathname !== "/") {
        notification({
            type: "warning",
            message: acceptableMessage,
            duration: 0,
            description: acceptableDesc
        });
        sessionStorage.setItem("browser-check", "true");
    }

    useEffect(() => {
        document.body.setAttribute("data-theme", theme);
    }, []);
  
    return (
        <Provider store={store}>
            <CheckUserContainer>
                <App />
            </CheckUserContainer>
        </Provider>
    );
};

const CheckCacheComponent = useGetCheckCacheComponent(RootApp);

ReactDOM.render(
    <CheckCacheComponent />,
    document.getElementById("root")
);
