type setIframeDefaultStylesProps = {
    workIframeDoc: Document;
}

const setIframeDefaultStyles = (props: setIframeDefaultStylesProps) => { 
    const {workIframeDoc} = props;
    const fontSizeStyles = `
        * {
            font-size: 2.2vw !important;
        }
    `;
    const bodyAdaptive = `
        @media (max-width: 500px) {
            body {
                padding: 20px 32px !important; 
            }
        }
    `;
    const fontSizeSheet = workIframeDoc.createElement("style");
    const BodyAdaptiveSheet = workIframeDoc.createElement("style");
    workIframeDoc.head.appendChild(fontSizeSheet);
    workIframeDoc.head.appendChild(BodyAdaptiveSheet);
    if (fontSizeSheet.sheet) fontSizeSheet.sheet.insertRule(fontSizeStyles);
    if (BodyAdaptiveSheet.sheet) BodyAdaptiveSheet.sheet.insertRule(bodyAdaptive);
};

export default setIframeDefaultStyles;
