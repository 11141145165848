import React, {useState} from "react";
import {Checkbox, Select} from "antd";
import styles from "./ColumnsFilter.module.less";
import classNames from "classnames";

type ColumnsFilterProps = {
    filters: string[];
    deletedColumns: string[];
    dispatch: (values: string[]) => void;
};

const ColumnsFilter = (props: ColumnsFilterProps) => {
    const {filters, deletedColumns, dispatch} = props;
    const [buttonActive, setButtonActive] = useState(false);

    const addValue = (value: string) => {
        dispatch([...deletedColumns, value]);
    };
    const deleteValue = (value: string) => {
        dispatch([...deletedColumns.filter((item) => item !== value)]);
    };
    const onCheckboxChange = (checked: boolean, value: string) => {
        if (checked) deleteValue(value);
        else addValue(value);
    };
    const checkBoxes = () => (
        <div>
            {filters.map((item, key) => {
                return (
                    <Checkbox
                        defaultChecked={deletedColumns?.includes(item) ? false : true}
                        name={item}
                        key={key}
                        onChange={(event) => {
                            onCheckboxChange(event.target.checked, item);
                        }}>
                        {item}
                    </Checkbox>
                );
            })}
        </div>
    );

    return (
        <div className={classNames({[styles.buttonActive]: buttonActive}, styles.button)}>
            <Select
                placeholder="Настроить колонки"
                dropdownRender={checkBoxes}
                popupClassName={styles.dropDown}
                dropdownStyle={{
                    minWidth: "fit-content",
                    maxHeight: 250
                }}
                onDropdownVisibleChange={(open) => setButtonActive(open)}
            />
        </div>
    );
};

export default ColumnsFilter;
