import React from "react";
import {useLocation, Link} from "react-router-dom";
import {Breadcrumb} from "antd";
import styles from "./Breadcrumbs.module.less";
import classNames from "classnames";
import useGetLinkName from "./Hooks/GetLinkName/useGetLinkName";

type BreadcrumbsProps  = {
    render?: boolean
}

type BreadcrumbsStep = {
    content: JSX.Element | string
    routeTo: string 
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const {render = true} = props;
    const pathname = useLocation().pathname;
    const pathnames = pathname.split("/").filter((item: string) => item);
    const ROOTPATHS = [
        "lk",
        "auth",
    ];
    const breadcrumbsSteps: BreadcrumbsStep[] = [];
    pathnames.forEach((pathname: string, index: number) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const prevPathname: string = pathnames[index - 1] ? pathnames[index - 1] : "";
        const content = useGetLinkName({
            pathname,
            prevPathname
        });
        if (content) breadcrumbsSteps.push({
            content,
            routeTo
        });
    });
    return ( 
        <div className={classNames({[styles.hidden]: !render})} 
            data-testid="breadcrumbsHiddenWrapper">
            <div className={styles.wrapper}>
                <Breadcrumb separator="/">
                    {
                        breadcrumbsSteps.map((step, index) => (
                            <Breadcrumb.Item 
                                key={index}
                                data-testid="breadcrumbsItem"> 
                                {
                                    index + 1 === breadcrumbsSteps.length 
                                        ? step.content 
                                        : (
                                            <Link key={index} to={step.routeTo}>
                                                {step.content}
                                            </Link>
                                        )
                                }
                            </Breadcrumb.Item>
                        ))
                    }
                </Breadcrumb>
            </div>
        </div>
    );
};

export default Breadcrumbs;
