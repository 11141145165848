import rootSagaCreator from "../rootSagaCreator";
import GetProjectsSaga from "./ProjectGetSaga";
import CreateProjectSaga from "./ProjectCreateSaga";
import DeleteProjectSaga from "./ProjectDeleteSaga";
import setFavorite from "./ProjectSetFavorite";
import changeProjectInfo from "./ProjectChangeInfo";
import GetSelectedProject from "./GetSelectedProject";

export default function* rootSaga() {
    yield rootSagaCreator([
        GetProjectsSaga,
        CreateProjectSaga,
        DeleteProjectSaga,
        setFavorite,
        changeProjectInfo,
        GetSelectedProject
    ], "PROJECTS");
}
