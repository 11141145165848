import React, {useEffect, useState} from "react";
import {Document, pdfjs} from "react-pdf/dist/esm/entry.webpack";
import styles from "./ViewPDF.module.less";
import classNames from "classnames";
import PagePDF from "./Modules/PagePDF/PagePDF";
import Loading from "@root/Components/Loading/Loading";
import {PageProps} from "react-pdf";
import {VariableSizeList} from "react-window";
import CustomScrollbarsVirtualList from "./Modules/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";

type ViewPDFProps = {
  pathToFile: string;
  corepageStyles?: string;
  pageProps?: PageProps;
};

const ViewPDF = (props: ViewPDFProps) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [sizes, setSizes] = useState<Array<[number, number]>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    
    const {pathToFile, corepageStyles, pageProps} = props;
  
    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    };

    useEffect(() => {

        pdfjs.getDocument(pathToFile).promise.then((pdf) => {
            setLoading(true);
            const allSizes: Array<[number, number]> = [];
            const numPages = pdf.numPages;
            for (let index = 1;index <= numPages;index += 1) {
                pdf.getPage(index).then((page) => {
                    const viewport = page.getViewport({scale: 1});
                    allSizes.push([viewport.height, viewport.width]);
                });
            }
            setLoading(false);
            setSizes(allSizes);
        });

    }, [pathToFile]);

    useEffect(() => {
        if (sizes.length !== 0) {
            setWidth(sizes[0][0]);
            setHeight(sizes[0][1]);
        }
    }, [sizes]);
  
    const PageItem = ({index, style}: {index: number, style: React.CSSProperties}) => (
        <div style={style}>
            <PagePDF pageProps={pageProps} index={index} key={index} />
        </div>
    );

    const getSize = (index: number) => {
        let height = 841;
        let width = 595;
        if (sizes.length !== 0) {
            const size = sizes[index];
            height = size[0];
            width = size[1];
        }
        if (height === 0 || width === 0) return 0;
        return (height * (880 / width)) + 16;
    };
    if (loading) return null;
  
    return (
        <Document
            renderMode="svg"
            file={pathToFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className={classNames(styles.pdf, corepageStyles && corepageStyles)}
            options={{
                cMapPacked: true,
                cMapUrl: "cmaps/",
                verbosity: pdfjs.VerbosityLevel.ERRORS,
            }}
            loading={() => {
                return (
                    <div className={classNames(styles.loading, corepageStyles && corepageStyles)}>
                        <Loading />
                    </div>
                );
            }}
            error={() => {
                return (
                    <div className={styles.error}>
                        <div>При попытке отобразить файл произошла ошибка</div>
                        <div>Попробуйте перезагрузить страницу</div>
                    </div>
                );
            }}
        >
            {numPages !== null && (
                <VariableSizeList
                    height={height}
                    width={"100%"}
                    itemSize={(index) => getSize(index)}
                    itemCount={numPages}
                    className={corepageStyles}
                    outerElementType={CustomScrollbarsVirtualList}
                    style={{
                        background: "var(--document-background)",
                        height: "100%"
                    }}
                >
                    {PageItem}
                </VariableSizeList>
            )}
        </Document>
    );
};
  
export default ViewPDF;
