import React from "react";

const LogoText = () => {
    return (
        <svg 
            width="33"
            height="23" 
            viewBox="0 0 33 23"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M7.49002 5.25081C7.49002 6.25139 7.30368 7.08791 6.93099 7.76036C6.55831 8.42876 6.01751 8.9331 5.30859 9.27338C4.59968 9.60961 3.74494 9.77772 2.74436 9.77772H0.277344V0.894043H3.01172C3.93128 0.894043 4.72526 1.06013 5.39366 1.39231C6.06207 1.72043 6.57856 2.20857 6.94314 2.85672C7.30773 3.50081 7.49002 4.29885 7.49002 5.25081ZM5.97092 5.29335C5.97092 4.56418 5.85547 3.96464 5.62457 3.49474C5.39772 3.02483 5.06149 2.67645 4.61589 2.4496C4.17434 2.2187 3.62949 2.10324 2.98134 2.10324H1.73568V8.55637H2.76866C3.84216 8.55637 4.64424 8.28293 5.17491 7.73606C5.70559 7.18918 5.97092 6.37495 5.97092 5.29335Z"
                fill="currentColor"/>
            <path 
                d="M14.4353 9.77772H9.40408V0.894043H14.4353V2.12147H10.8624V4.5338H14.2105V5.75515H10.8624V8.54422H14.4353V9.77772Z" 
                fill="currentColor"/>
            <path 
                d="M21.3867 9.77772H16.3555V0.894043H21.3867V2.12147H17.8138V4.5338H21.1619V5.75515H17.8138V8.54422H21.3867V9.77772Z" fill="currentColor"/>
            <path 
                d="M25.8893 0.894043C27.0357 0.894043 27.8723 1.129 28.3989 1.5989C28.9255 2.06881 29.1888 2.72709 29.1888 3.57373C29.1888 3.95857 29.128 4.32518 29.0065 4.67356C28.889 5.01789 28.6946 5.32373 28.4232 5.59109C28.1518 5.8544 27.7933 6.06302 27.3477 6.21696C26.9061 6.37089 26.3613 6.44786 25.7131 6.44786H24.7652V9.77772H23.3069V0.894043H25.8893ZM25.7921 2.10324H24.7652V5.23258H25.5612C26.019 5.23258 26.4078 5.17992 26.7279 5.0746C27.0479 4.96522 27.2909 4.79306 27.457 4.55811C27.6231 4.32315 27.7062 4.01326 27.7062 3.62842C27.7062 3.11395 27.5502 2.73114 27.2383 2.47998C26.9304 2.22882 26.4484 2.10324 25.7921 2.10324Z" 
                fill="currentColor"/>
            <path 
                d="M7.49002 17.6953C7.49002 18.6958 7.30368 19.5324 6.93099 20.2048C6.55831 20.8732 6.01751 21.3775 5.30859 21.7178C4.59968 22.0541 3.74494 22.2222 2.74436 22.2222H0.277344V13.3385H3.01172C3.93128 13.3385 4.72526 13.5046 5.39366 13.8368C6.06207 14.1649 6.57856 14.653 6.94314 15.3012C7.30773 15.9453 7.49002 16.7433 7.49002 17.6953ZM5.97092 17.7378C5.97092 17.0086 5.85547 16.4091 5.62457 15.9392C5.39772 15.4693 5.06149 15.1209 4.61589 14.894C4.17434 14.6631 3.62949 14.5477 2.98134 14.5477H1.73568V21.0008H2.76866C3.84216 21.0008 4.64424 20.7274 5.17491 20.1805C5.70559 19.6336 5.97092 18.8194 5.97092 17.7378Z" 
                fill="currentColor"/>
            <path 
                d="M17.2912 17.7682C17.2912 18.4528 17.2041 19.0766 17.03 19.6397C16.8598 20.1987 16.6026 20.6808 16.2583 21.0859C15.9139 21.4869 15.4825 21.7968 14.964 22.0156C14.4455 22.2343 13.8378 22.3437 13.1411 22.3437C12.4321 22.3437 11.8164 22.2343 11.2938 22.0156C10.7753 21.7968 10.3439 21.4849 9.99957 21.0798C9.65929 20.6747 9.40408 20.1906 9.23394 19.6275C9.0638 19.0645 8.97873 18.4406 8.97873 17.756C8.97873 16.8405 9.12862 16.0425 9.42839 15.3619C9.73221 14.6773 10.192 14.1466 10.8077 13.7699C11.4275 13.3891 12.2093 13.1987 13.1532 13.1987C14.0768 13.1987 14.8445 13.3871 15.4562 13.7638C16.0679 14.1406 16.5256 14.6712 16.8294 15.3558C17.1373 16.0364 17.2912 16.8405 17.2912 17.7682ZM10.51 17.7682C10.51 18.4528 10.6032 19.0462 10.7895 19.5486C10.9758 20.0468 11.2614 20.4317 11.6463 20.7031C12.0352 20.9704 12.5334 21.1041 13.1411 21.1041C13.7528 21.1041 14.251 20.9704 14.6359 20.7031C15.0207 20.4317 15.3043 20.0468 15.4865 19.5486C15.6688 19.0462 15.76 18.4528 15.76 17.7682C15.76 16.7271 15.5514 15.9129 15.1341 15.3255C14.7209 14.734 14.0606 14.4383 13.1532 14.4383C12.5456 14.4383 12.0453 14.572 11.6523 14.8394C11.2635 15.1067 10.9758 15.4895 10.7895 15.9878C10.6032 16.482 10.51 17.0755 10.51 17.7682Z"
                fill="currentColor"/>
            <path 
                d="M22.9848 14.4505C22.5595 14.4505 22.1787 14.5274 21.8425 14.6814C21.5103 14.8353 21.2287 15.0581 20.9978 15.3498C20.7669 15.6374 20.5907 15.9878 20.4692 16.401C20.3477 16.8101 20.2869 17.2719 20.2869 17.7864C20.2869 18.4751 20.3841 19.0685 20.5786 19.5668C20.773 20.061 21.0687 20.4418 21.4657 20.7091C21.8627 20.9725 22.365 21.1041 22.9727 21.1041C23.3494 21.1041 23.716 21.0677 24.0725 20.9947C24.429 20.9218 24.7976 20.8226 25.1784 20.697V21.9366C24.8179 22.0784 24.4533 22.1817 24.0846 22.2465C23.716 22.3113 23.2907 22.3437 22.8086 22.3437C21.9012 22.3437 21.1477 22.1553 20.5482 21.7786C19.9527 21.4019 19.5071 20.8712 19.2114 20.1866C18.9197 19.502 18.7739 18.6999 18.7739 17.7803C18.7739 17.1079 18.867 16.4921 19.0534 15.9331C19.2397 15.3741 19.5111 14.892 19.8676 14.4869C20.2241 14.0778 20.6636 13.7638 21.1862 13.5451C21.7128 13.3223 22.3144 13.2109 22.9909 13.2109C23.4365 13.2109 23.874 13.2615 24.3034 13.3628C24.7368 13.46 25.1379 13.5998 25.5065 13.7821L24.9961 14.9852C24.6882 14.8394 24.3662 14.7138 24.03 14.6085C23.6937 14.5031 23.3453 14.4505 22.9848 14.4505Z"
                fill="currentColor"/>
            <path 
                d="M32.2148 19.8038C32.2148 20.3304 32.0852 20.7841 31.826 21.1649C31.5708 21.5457 31.2021 21.8373 30.7201 22.0399C30.242 22.2424 29.6709 22.3437 29.0065 22.3437C28.6824 22.3437 28.3725 22.3255 28.0768 22.289C27.7811 22.2525 27.5016 22.1999 27.2383 22.131C26.979 22.0581 26.742 21.971 26.5273 21.8697V20.4965C26.8879 20.6545 27.2909 20.7983 27.7366 20.9279C28.1822 21.0535 28.6359 21.1163 29.0977 21.1163C29.4744 21.1163 29.7863 21.0677 30.0334 20.9704C30.2846 20.8692 30.4709 20.7274 30.5925 20.5451C30.714 20.3587 30.7747 20.142 30.7747 19.8949C30.7747 19.6316 30.7039 19.4088 30.5621 19.2265C30.4203 19.0442 30.2056 18.8781 29.918 18.7282C29.6344 18.5743 29.2779 18.4102 28.8485 18.2361C28.5569 18.1186 28.2773 17.9849 28.01 17.835C27.7467 17.6851 27.5117 17.5089 27.3051 17.3064C27.0985 17.1038 26.9345 16.8648 26.8129 16.5894C26.6955 16.3098 26.6367 15.9817 26.6367 15.605C26.6367 15.1027 26.7562 14.6733 26.9952 14.3168C27.2383 13.9603 27.5786 13.6869 28.0161 13.4965C28.4576 13.3061 28.9721 13.2109 29.5595 13.2109C30.0253 13.2109 30.4628 13.2595 30.872 13.3567C31.2852 13.4539 31.6903 13.5917 32.0872 13.7699L31.6254 14.9427C31.2609 14.7928 30.9064 14.6733 30.5621 14.5841C30.2218 14.495 29.8734 14.4505 29.5169 14.4505C29.2091 14.4505 28.9478 14.4971 28.7331 14.5902C28.5184 14.6834 28.3543 14.815 28.2409 14.9852C28.1315 15.1513 28.0768 15.3498 28.0768 15.5807C28.0768 15.8399 28.1396 16.0587 28.2652 16.2369C28.3948 16.4111 28.5933 16.5711 28.8607 16.717C29.1321 16.8628 29.4805 17.0248 29.9058 17.2031C30.396 17.4056 30.8112 17.6183 31.1515 17.8411C31.4958 18.0639 31.7591 18.3313 31.9414 18.6432C32.1237 18.951 32.2148 19.3379 32.2148 19.8038Z" 
                fill="currentColor"/>
        </svg>
    );
};

export default LogoText;
