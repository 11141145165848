import * as React from "react";

const OldFirst = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.3333 17.834C20.3333 18.1434 20.2104 18.4402 19.9916 18.659C19.7728 18.8778 19.4761 19.0007 19.1667 19.0007L5.16667 19.0007C4.85725 19.0007 4.5605 18.8778 4.34171 18.659C4.12292 18.4402 4 18.1434 4 17.834C4 17.5245 4.12292 17.2278 4.34171 17.009C4.5605 16.7902 4.85725 16.6672 5.16667 16.6672L19.1667 16.6672C19.4761 16.6672 19.7728 16.7902 19.9916 17.009C20.2104 17.2278 20.3333 17.5245 20.3333 17.834ZM20.3333 12.0003C20.3333 12.3098 20.2104 12.6065 19.9916 12.8253C19.7728 13.0441 19.4761 13.1671 19.1667 13.1671L5.16667 13.1671C4.85725 13.1671 4.5605 13.0441 4.34171 12.8253C4.12292 12.6065 4 12.3098 4 12.0003C4 11.6909 4.12292 11.3941 4.34171 11.1753C4.5605 10.9565 4.85725 10.8336 5.16667 10.8336L19.1667 10.8336C19.4761 10.8336 19.7728 10.9565 19.9916 11.1753C20.2104 11.3941 20.3333 11.6909 20.3333 12.0003ZM13.3333 6.16672C13.3333 6.47616 13.2104 6.77292 12.9916 6.99172C12.7728 7.21053 12.4761 7.33345 12.1667 7.33345L5.16667 7.33345C4.85725 7.33345 4.5605 7.21053 4.34171 6.99172C4.12292 6.77292 4 6.47616 4 6.16672C4 5.85729 4.12292 5.56053 4.34171 5.34172C4.5605 5.12292 4.85725 5 5.16667 5L12.1667 5C12.4761 5 12.7728 5.12292 12.9916 5.34173C13.2104 5.56053 13.3333 5.85729 13.3333 6.16672Z" fill="currentColor"/>
        </svg>
    );
};

export default OldFirst;
