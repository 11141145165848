import Request from "../Request";
import {Auth} from "./types";
import Cookies from "universal-cookie";
import Endpoints from "./endpoints";
import {decode, jwtToken} from "@utils/JWT/decode";
import routes from "@root/Routes/Routes";

const cookies = new Cookies();

export default {
    registration: (params: Auth.iRegistration) => Request.post<Auth.oRegistration>(`${Endpoints.authorization.registration}`, {
        email: params.email,
        password: params.password,
        is_admin: false
    }),
    login: (params: Auth.iLogin) => Request.post<Auth.oLogin>(`${Endpoints.authorization.auth}`, params),
    refresh: () => {
        return Request.post<Auth.oRefresh>(`${Endpoints.authorization.refresh}`)
            .then(({data}) => {
                const decodedAccess:jwtToken = decode(data.access_token);
                cookies.set(
                    "access_token",
                    data.access_token,
                    {
                        // TODO Пока нет SSL сертификата, убираю это
                        // secure: true,
                        expires: new Date(decodedAccess.exp * 1000),
                        path: "/"
                    });
            })
            .catch(() => {
                // обнуляем сторы и предалагаем пользователю авторизоваться если на обновление токена получаем ошибку
                window.location.href = routes.auth.login;
            });
    },
    recovery: (params: Auth.iRecovery) => {
        return Request.post<void>(`${Endpoints.authorization.recovery}`, {...params});
    },
    reset: (params: Auth.iReset) => {
        return Request.post<void>(`${Endpoints.authorization.reset}`, {...params});
    },
    logout: () => Request.post(`${Endpoints.authorization.logout}`),
};
