// TODO КОГДА БУДЕТ ГОТОВА ЛОГИКА АНОНИМИЗАЦИИ ПРОВЕРИТЬ ЗНАЧЕНИЯ PARAMS
import React, {useEffect} from "react";
import styles from "./Anonymization.module.less";
import {usePageParams} from "./Hooks/useParams";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import ContentController from "./Controllers/ContentController";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Anonymization = () => {
    const params = usePageParams();
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.Project.openedDocument.data);
    const anonType = useAppSelector((state) => state.Project.openedDocument.data?.anonymization_type);
    const anonymizeStatus = useAppSelector((state) => state.Project.openedDocument.data?.doc_anonymization_status);
    const history = useHistory();
    useTitle("DeepDocs - Анонимизация");
    
    useEffect(() => {
        const docNum = params.documentID;
        if (!docNum) return;
        dispatch(Actions.Project.getDocumentInfo({
            id: docNum
        }));
        dispatch(Actions.Anonymize.initAnonymizedDoc({
            id: docNum
        }));
        return () => {
            dispatch(Actions.Anonymize.anonymizeWatchStop());
            dispatch(Actions.Common.setScrollPosition(null));
        };
    }, []);

    useEffect(() => {
        if (!data) return;
        const docNum = params.documentID;
        const projectNum = params.projectID;
        if (!docNum) return;
        if (!projectNum) return;

        if (data.doc_anonymization_status >= 200) return;
        if (data.doc_anonymization_status === 0) {
            history.push(routes.lk.project.document(data?.project_id, data?.id));
        }
        if (data.doc_anonymization_status === 100) {
            dispatch(Actions.Anonymize.anonymizeWatchStart({
                id: docNum
            }));
        }
    }, [data]);

    useEffect(() => {
        if (data && anonymizeStatus === 200) {
            if (anonType === "pdf_anonymization") {
                dispatch(Actions.Anonymize.getColoredPdf({document_id: data.anonymized_document_id}));
            } else {
                //TODO Убрать кл
                console.log("Зашел запросил анон хтмл");
                dispatch(Actions.Anonymize.getAnonHtml({docId: data.anonymized_document_id}));
            }
        }
    }, [anonymizeStatus]);

    return (
        <div className={styles.wrapper}>
            <ContentController />
        </div>
    );
};

export default Anonymization;
