import React from "react";
import {Badge} from "antd";
import LoadingBadge from "./Modules/LoadingBadge/LoadingBadge";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import styles from "./DocumentBadge.module.less";

type DocumentBadgeProps = {
    status?: "success" | "error" | "loading" | null | undefined,
    type?: "document" | "anonymization"
}

const DocumentBadge = ({status, type = "document"}: DocumentBadgeProps) => {

    if (type === "document") {
        return (
            <>
                {status === "loading" 
                    ? <LoadingBadge />
                    : status === "error" 
                        ? (
                            <Tooltip
                                title="Ошибка загрузки">
                                <Badge
                                    color="#F86262" 
                                    className={styles.badge}/>
                            </Tooltip>)
                        : (
                            <Tooltip 
                                title="Успешная загрузка">
                                <Badge 
                                    color="#00D98B"
                                    className={styles.badge}/>
                            </Tooltip>
                        )
                }
            </>
        );
    }
    if (type === "anonymization") {
        return (
            <>
                {status === "loading" && (
                    <LoadingBadge type="anonymization" />
                )}
                {status === "error" && (
                    <Badge
                        color="#F86262" 
                        className={styles.badge}/>
                )}
                {status === "success" && (

                    <Badge 
                        color="#00D98B"
                        className={styles.badge}/>
                   
                )}
                {!status && (
                    <Badge 
                        color="#FAAD14"
                        className={styles.badge}/>
                )}
            </>
        );
    }
    return null;
};

export default DocumentBadge;
