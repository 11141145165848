const docsPrefix = {
    docs: "/docs/v1",
};

const DocumentsType = {
    documents: `${docsPrefix.docs}/documents`,
};

export default {
    documents: {
        searchInDocument: `${DocumentsType.documents}/search_text_in_doc/`,
        getPdfDocument: "v1/documents/{docId}/pdf",
        deleteDocument: `${DocumentsType.documents}/{docId}/`,
        downloadOriginalDocument: "v1/documents/downloads",
    }
};
