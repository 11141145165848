import React from "react";

const IconLogo = () => {
    return (
        <svg
            width="19" 
            height="23"
            viewBox="0 0 19 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd"
                clipRule="evenodd" 
                d="M17.1627 17.103C18.0909 15.5151 18.555 13.5397 18.555 11.177C18.555 8.92899 18.1006 7.04452 17.192 5.52356C16.2833 3.99303 15.0034 2.84034 13.3521 2.06551C11.7009 1.28111 9.75165 0.888916 7.50441 0.888916H0.777344V21.8668H0.777767C1.50762 21.6713 2.18695 21.4921 2.82298 21.3243L2.82306 21.3243C10.121 19.399 11.716 18.9782 18.4807 12.7511C18.3159 14.431 17.8765 15.8816 17.1625 17.1031C16.5477 18.1596 15.7468 19.0426 14.7599 19.752C15.7469 19.0426 16.5478 18.1596 17.1627 17.103ZM13.1323 7.47499C13.6013 8.46983 13.8358 9.74209 13.8358 11.2917C13.8358 13.578 13.2935 15.2998 12.209 16.4573C11.1342 17.6148 9.48785 18.1935 7.26992 18.1935H5.32068V4.5335H7.73891C9.06771 4.5335 10.1816 4.77264 11.0805 5.25093C11.9891 5.72922 12.6731 6.47057 13.1323 7.47499Z"
                fill="currentColor"/>
            <path 
                d="M0.777597 21.8668C9.89949 19.4235 11.098 19.5538 18.4886 12.7459C10.2657 11.9967 3.17458 15.8404 0.777597 21.8668Z"
                fill="#00D98B"/>
            <path 
                d="M0.777597 21.8668C9.89949 19.4235 11.098 19.5538 18.4886 12.7459C10.2657 11.9967 3.17458 15.8404 0.777597 21.8668Z"
                fill="url(#paint0_linear_4957_76353)" 
                fillOpacity="0.2"/>
            <defs>
                <linearGradient
                    id="paint0_linear_4957_76353" 
                    x1="9.63312" y1="14.0163"
                    x2="11.9498" 
                    y2="18.5525"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F8F8F8"/>
                    <stop 
                        offset="0.478893"
                        stopColor="#F8F8F8"/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default IconLogo;
