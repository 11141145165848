import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@root/Redux/Templates/types";
import React from "react";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteTemplate = function* (action: PayloadAction<iActions.deleteTemplate>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield call(Api.Templates.deleteTemplates, payload);
        yield put(Actions.Templates._deleteTemplateSuccess(payload));
        yield put(Actions.Templates.setDeleteModal(false));
        notification({
            type: "info",
            message: "шаблон удален"
        });
    } catch (error) {
        yield put(Actions.Templates._deleteTemplateError());
        notification({
            type: "error",
            message: "При удалении шаблона произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Templates.deleteTemplate, DeleteTemplate);
}
