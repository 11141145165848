import React, {useEffect, useRef, useState, useMemo} from "react";
import {PDFPageProxy} from "react-pdf";
import {PDFDocumentProxy} from "pdfjs-dist";
import {Stage, Layer, Rect} from "react-konva";
import Konva from "konva";
import Actions from "@actions";
import {Button} from "@components/Controls";
import styles from "./PdfPage.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {iState as AnonStateTypes} from "@root/Redux/Anonymize/types";

type iPdfPage = {
    index: number;
    url: string;
    result: PDFDocumentProxy | null;
};

const PdfPage = (props: iPdfPage) => {
    const layerDocRef = useRef<Konva.Layer>(null);
    const layerVerificationRef = useRef<Konva.Layer>(null);
    const coloredPdf = useAppSelector((state) => state.Anonymize.coloredPdf);
    const verificationNodes = useAppSelector((state) => state.Anonymize.coloredPdf.coloredNodes);
    const stageRef = useRef<Konva.Stage>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [page, setPage] = useState<PDFPageProxy | null>(null);
    const [selectVisible, setSelectVisible] = useState(false);
    const [selectX2, setSelectX2] = useState(0);
    const [selectY2, setSelectY2] = useState(0);
    const [selectX1, setSelectX1] = useState(0);
    const [selectY1, setSelectY1] = useState(0);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuX, setMenuX] = useState(0);
    const [menuY, setMenuY] = useState(0);
    const [menuKey, setMenuKey] = useState("");
    const dispatch = useAppDispatch();
    const rectSize = useMemo(() => {
        return {
            x: Math.min(selectX1, selectX2),
            y: Math.min(selectY1, selectY2),
            width: Math.abs(selectX2 - selectX1),
            height: Math.abs(selectY2 - selectY1),
        };
    }, [selectX1, selectX2, selectY1, selectY2]);

    useEffect(() => {
        // Получаем информацию о страницах
        if (!coloredPdf.file) return;
        if (!layerDocRef.current) return;
        if (!props.url) return;
        if (!props.result) return;

        props.result.getPage(props.index + 1).then((page) => {
            setPage(page as unknown as PDFPageProxy);
        });
    }, [coloredPdf.file, props.index]);

    useEffect(() => {
        dispatch(Actions.Anonymize.changeColoredPdfEditMode(false));
    }, []);

    useEffect(() => {
    // Рендерим канвас
        if (!page) return;
        if (!layerDocRef.current) return;
        const viewport = page.getViewport({scale: 0.88});
        const context = layerDocRef.current.getContext();
        setWidth(viewport.width);
        setHeight(viewport.height);
        page.render({
            canvasContext: context,
            viewport,
        });
    }, [page]);

    return (
        <div data-id="page-item" data-number={props.index} className={styles.page}>
            <div
                className={styles.menu}
                style={{
                    display: menuVisible ? "block" : "none",
                    position: "absolute",
                    left: `${menuX}px`,
                    top: `${menuY}px`,
                    zIndex: 101,
                }}>
                <div className={styles.deleteButton}>
                    <Button
                        type="menu"
                        onClick={() => {
                            dispatch(
                                Actions.Anonymize.deleteColoredNode({
                                    nodeId: menuKey,
                                })
                            );
                            setMenuVisible(false);
                            setMenuKey("");
                        }}>
                            Удалить             
                    </Button>

                </div>
            </div>
            <Stage
                ref={stageRef}
                width={width}
                height={height}
                onMouseDown={(event) => {
                    if (!coloredPdf.editMode) return;
                    setMenuVisible(false);
                    setSelectVisible(true);
                    if (!stageRef.current) return;
                    const stage = stageRef.current;
                    event.evt.preventDefault();
                    const position = stage?.getPointerPosition();
                    if (!position) return;
                    setSelectX1(position.x);
                    setSelectX2(position.x);
                    setSelectY1(position.y);
                    setSelectY2(position.y);
                }}
                onMouseUp={(event) => {
                    if (!coloredPdf.editMode) return;
                    event.evt.preventDefault();
                    setSelectVisible(false);
                    dispatch(
                        Actions.Anonymize.addColoredNode({
                            x: rectSize.x,
                            y: rectSize.y,
                            width: rectSize.width,
                            height: rectSize.height,
                            pageIndex: props.index,
                        },
                        )
                    );
                    setSelectX1(0);
                    setSelectX2(0);
                    setSelectY1(0);
                    setSelectY2(0);
                }}
                onContextMenu={() => {
                    if (!coloredPdf.editMode) return;
                    setSelectVisible(false);
                }}
                onMouseMove={(event) => {
                    if (!coloredPdf.editMode) return;
                    if (!stageRef.current) return;
                    if (!selectVisible) return;
                    const stage = stageRef.current;
                    event.evt.preventDefault();
                    const position = stage.getPointerPosition();
                    if (!position) return;
                    setSelectX2(position.x);
                    setSelectY2(position.y);
                }}
            >
                <Layer name="test" ref={layerDocRef} />
                <Layer ref={layerVerificationRef}>
                    {coloredPdf.file &&
                    verificationNodes
                        .filter((item: AnonStateTypes.ColoredNode) => item.pageIndex === props.index)
                        .map((item: AnonStateTypes.ColoredNode, index) => (
                            <Rect
                                key={item.key}
                                x={item.x}
                                y={item.y}
                                onMouseEnter={() => {
                                    if (!coloredPdf.editMode) return;
                                    if (!stageRef.current) return;
                                    stageRef.current.container().style.cursor = "pointer";
                                }}
                                onMouseLeave={() => {
                                    if (!coloredPdf.editMode) return;
                                    if (!stageRef.current) return;
                                    stageRef.current.container().style.cursor = "default";
                                }}
                                height={item.height}
                                width={item.width}
                                onContextMenu={(event) => {
                                    if (!coloredPdf.editMode) return;
                                    event.evt.preventDefault();
                                    event.evt.stopPropagation();
                                    if (!stageRef.current) return;
                                    const position = stageRef.current.getPointerPosition();
                                    if (!position) return;
                                    setMenuVisible(true);
                                    setMenuX(position.x);
                                    setMenuY(position.y + 10);
                                    setMenuKey(item.key);
                                }}
                                opacity={1}
                                cornerRadius={0}
                                fill={"#000"}
                            />
                        ))}
                    <Rect
                        fill="#CCC"
                        opacity={0.5}
                        cornerRadius={4}
                        x={rectSize.x}
                        y={rectSize.y}
                        visible={selectVisible}
                        width={rectSize.width}
                        height={rectSize.height}
                    />
                </Layer>
            </Stage>
        </div>
    );
};

export default PdfPage;
