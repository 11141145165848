import {HTML} from "./types";
import Request from "../Request";
import paths from "./endpoints";
export default {
    getLabels: () => {
        const url = paths.getLabels;
        return Request.get<HTML.Labels>(
            url
        );
    },
};
