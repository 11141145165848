import {useAppSelector} from "@root/Hooks";

export type ChartDonatData = {
    x: string,
    y: number,
    fill: string
}[]

export type BarData = {
    quarter: number,
    earnings: number,
    fill: string,
}[]

const useStatisticsData = () => {
    const statistics = useAppSelector((state) => state.Common.statistics.data);
    if (!statistics) return null;

    const colors = [
        "#4885F7",
        "#875EF6",
        "#00D98B",
        "#EEAD45",
        "#F74C48",
        "#00B35F",   
        "#C285FF",
        "#FFC948",
        "#4BA8E8",
        "#FF73A1",
    ];
    const doctype_stat: ChartDonatData = [];
    const extension_stat: ChartDonatData = [];
    const anon_stat: BarData = [];
    const barValues: string[] = [];
    let doctype_sum = 0;
    let extension_sum = 0;

    statistics.doctype_statistic.forEach((item) => {
        doctype_sum = doctype_sum + item.n_occur;
    });

    statistics.extension_statistic.forEach((item) => {
        extension_sum = extension_sum + item.n_occur;
    });

    let counter = 0;

    statistics.doctype_statistic.forEach((item, index) => {
        if (counter >= colors.length) counter = 0;
        doctype_stat.push({
            x: item.obj,
            y: item.n_occur,
            fill: colors[counter]
        });
        counter = counter + 1;
    });

    counter = 0;

    statistics.extension_statistic.forEach((item, index) => {
        if (counter >= colors.length) counter = 0;
        extension_stat.push({
            x: item.obj.toUpperCase(),
            y: item.n_occur,
            fill: colors[counter]
        });
        counter = counter + 1;
    });

    let barCounter = 0;

    statistics.anon_statistic.slice(0, 8).forEach((item, index) => {
        if (barCounter >= colors.length) barCounter = 0;
        anon_stat.push(
            {
                quarter: index + 1,
                earnings: item.n_occur,
                fill: colors[barCounter]
            }
        );
        let entity: string = item.obj;
        if (item.obj === "ФизЛицо") entity = "Физ\nлицо";
        if (item.obj === "ГосОрган") entity = "Гос\nОрган";
        if (item.obj === "Не определено") entity = "Не\nопределено";
        barValues.push(entity);
        barCounter = barCounter + 1;
    });

    barCounter = 0;

    const tasks_total = statistics.task_statistic
        .map((task_type_count) => task_type_count.n_occur)
        .reduce((all_tasks_count, current_task_type_count) => all_tasks_count + current_task_type_count, 0);

    return {
        doctype_stat,
        extension_stat,
        anonymized_entities: statistics.anon_total,
        extracted_entities: statistics.extraction_total,
        docs_total: statistics.docs_total,
        tasks_total,
        doctype_sum,
        extension_sum,
        anon_stat,
        barValues
    };
};

export default useStatisticsData;
