import {useParams as useRouterParams} from "react-router-dom";

type iParams = {
    documentID: string;
    projectID: string;
}

export const useParams = () => useRouterParams<iParams>();

export default useParams;

