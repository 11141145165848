import React from "react";
import ContentController from "./Controllers/ContentController";

const Projects = () => {

    return (
        <ContentController />
    );
};

export default Projects;
