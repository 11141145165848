import React from "react";
import {Table as AntTable, TableProps} from "antd";
import styles from "./Table.module.less";
import classNames from "classnames";

const Table = <T extends object>(props: TableProps<T>) => {
    const {className, ...tableProps} = props;
    return (
        <AntTable
            className={classNames(
                styles.pagination,
                className ? className : ""
            )}
            {...tableProps}/>
    );
};

export default Table;
