const singleToken = `
    border: 1px dashed #32343A;
    background: #B3B3B3;
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: -1px;
    cursor: help
`;

const firstToken = `
    border-left: 1px dashed #32343A;
    border-top: 1px dashed #32343A;
    border-bottom: 1px dashed #32343A;
    background: #B3B3B3;
    border-radius: 4px 0px 0px 4px;
    padding: 4px 0px 4px 8px;
    margin-left: -1px;
    cursor: help
`;
const endToken = `
    border-right: 1px dashed #32343A;
    border-top: 1px dashed #32343A;
    border-bottom: 1px dashed #32343A;
    background: #B3B3B3;
    border-radius: 0px 4px 4px 0px;
    padding: 4px 8px 4px 0px;
    margin-left: -1px;
    cursor: help
`;

const usualToken = `
    border-top: 1px dashed #32343A;
    border-bottom: 1px dashed #32343A;
    background: #B3B3B3;
    border-radius: 0px 4px 4px 0px;
    padding: 4px 0px;
    margin-left: -1px;
    cursor: help
`;

export const styles = {
    usualToken,
    singleToken,
    firstToken,
    endToken
};
