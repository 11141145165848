import React from "react";
import styles from "./LoadingIcon.module.less";

const LoadingIcon = () => {
    return (
        <svg 
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.badgeRotation}>
            
            <path 
                d="M13 7C13 8.38813 12.5187 9.73332 11.6381 10.8064C10.7574 11.8794 9.532 12.6139 8.17054 12.8847C6.80908 13.1555 5.39584 12.9459 4.17162 12.2915C2.9474 11.6372 1.98794 10.5786 1.45672 9.2961C0.925508 8.01363 0.855405 6.58665 1.25836 5.25829C1.66131 3.92993 2.51239 2.78239 3.66658 2.01118C4.82077 1.23998 6.20665 0.892831 7.5881 1.02889C8.96955 1.16495 10.2611 1.7758 11.2426 2.75736" 
                stroke="#858585" 
                strokeLinecap="round"/>
        </svg>
    );
};

export default LoadingIcon;
