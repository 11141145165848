import Actions from "@actions";
import {takeLatest, call, put} from "redux-saga/effects";
import React from "react";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@root/Redux/Project/types";
import Api from "@root/Api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteDocuments = function* (action: PayloadAction<iActions.DeleteDocuments>) {
    const {documents_ids, onSuccess} = action.payload;
    const notification = useNotification();
    
    try {
        const {data} = yield call(Api.Project.deleteDocuments, {documents_ids});
        yield put(Actions.Project.eraseSelectedDocuments());
        yield put(Actions.Project._deleteDocumentsSuccess(data));
        onSuccess && onSuccess();
        notification({
            type: "info",
            message: "Документы успешно удалены"
        });
    } catch (error) {
        notification({
            type: "error",
            message: "При удалении документов произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Project.deleteDocuments, DeleteDocuments);
}
