import React from "react";
import {useAppSelector} from "@root/Hooks";
import Loading from "@root/Components/Loading/Loading";
import Error from "../Modules/Error/Error";
import Statistics from "../Modules/Statistics/Statistics";

const StatisticsController = () => {
    const LoadingError = useAppSelector((state) => state.Common.statistics.error);
    const statisticsIsLoading = useAppSelector((state) => state.Common.statistics.fetching);
    if (statisticsIsLoading) return <Loading />;
    if (LoadingError) return <Error />;
    return <Statistics />;
};
export default StatisticsController;
