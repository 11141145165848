import * as React from "react";

const Plus = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus">
                <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M6.75004 1.33398C6.75004 0.919771 6.41425 0.583984 6.00004 0.583984C5.58583 0.583984 5.25004 0.919771 5.25004 1.33398V5.25065H1.33337C0.91916 5.25065 0.583374 5.58644 0.583374 6.00065C0.583374 6.41486 0.91916 6.75065 1.33337 6.75065H5.25004V10.6673C5.25004 11.0815 5.58583 11.4173 6.00004 11.4173C6.41425 11.4173 6.75004 11.0815 6.75004 10.6673V6.75065H10.6667C11.0809 6.75065 11.4167 6.41486 11.4167 6.00065C11.4167 5.58644 11.0809 5.25065 10.6667 5.25065H6.75004V1.33398Z" fill="currentColor"/>
            </g>
        </svg>

    );
};

export default Plus;
