import AuthSaga from "./Auth/AuthSaga";
import UserSaga from "./User/UserSaga";
import ProjectsSaga from "./Projects/ProjectsSaga";
import ProjectSaga from "./Project/ProjectSaga";
import rootSagaCreator from "./rootSagaCreator";
import DocumentSaga from "./Document/DocumentSaga";
import AnonymizeSaga from "./Anonymize/AnonymizeSaga";
import CommonSaga from "./Common/CommonSaga";
import HTMLSaga from "./HTML/HTMLSaga";
import TemplatesSaga from "./Templates/TemplatesSaga";

export default function* rootSaga() {
    const sagas = [
        AuthSaga,
        UserSaga,
        ProjectsSaga,
        ProjectSaga,
        DocumentSaga,
        AnonymizeSaga,
        CommonSaga,
        HTMLSaga,
        TemplatesSaga
    ];
    yield rootSagaCreator(sagas, "ROOT");
}
