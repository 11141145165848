import styles from "./TableFilterParseStatus.module.less";
import React from "react";
import {Checkbox, Row, Badge} from "antd";
import {FilterDropdownProps} from "antd/es/table/interface";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import DocumentBadge from "../DocumentBadge/DocumentBadge";

type iTableFilterParseStatus =  FilterDropdownProps;

const TableFilterParseStatus = (props:iTableFilterParseStatus) => {
    const filteredData = props.filters ? props.filters : [];

    const addValue = (value: string) => {
        props.setSelectedKeys([
            ...props.selectedKeys,
            value
        ]);
    };

    const deleteValue = (value: string) => {
        props.setSelectedKeys(
            props.selectedKeys.filter((item) => item !== value)
        );
    };
    const onCheckboxChange = (event:CheckboxChangeEvent, value: string) => {
        const checked = event.target.checked;
        if (checked) addValue(value);
        else deleteValue(value);
        props.confirm({closeDropdown: false});
    };

    const getBadge = (value: string | number | boolean) => {
        if (value === "Загружен")  return <DocumentBadge status={"success"} />;
        if (value === "Обезличен")  return <DocumentBadge type="anonymization" status={"success"} />;
        if (value === "В процессе")  return <DocumentBadge status={"loading"} />;
        if (value === "Ошибка загрузки")  return <DocumentBadge status="error" />;
        if (value === "Ошибка") return <DocumentBadge type="anonymization" status="error" />;
        if (value === "Не производилось") return <DocumentBadge type="anonymization" status={null} />;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                {
                    filteredData
                        .map((item, index) => (
                            <Row key={index}>
                                <Checkbox
                                    onChange={(event) => onCheckboxChange(event, item.value.toString())}
                                    checked={props.selectedKeys.includes(item.value.toString())}
                                    className={styles.checkbox}
                                >    <div className={styles.checkboxDescription}>
                                        {getBadge(item.value)} 
                                        {item.text}  
                                    </div>
                                </Checkbox>
                            </Row>
                        ))  
                }
            </div>
        </div>
    );
};

export default TableFilterParseStatus;
