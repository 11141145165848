import React from "react";
import useColumns from "../../Hooks/useColumns";
import  {
    useGetValidNoun,
    useAppDispatch,
    useAppSelector
} from "@root/Hooks";
import Actions from "@actions";
import Table from "@root/Components/Controls/Table/Table";
import styles from "./TableView.module.less";
import EmptyDocuments from "@root/Assets/Icons/EmptyDocuments/EmptyDocuments";
import {Documents} from "@types";
import getOrderBy from "./Utils/getOrderBy";
import onRowClick from "./Utils/onRowClick";

const TableView = () => {
    const deletedColumns = useAppSelector((state) => state.Common.deletedDocumentsColumns);
    const documents = useAppSelector((state) => state.Project.documents.data);
    const documentsProjectID = useAppSelector((state) => state.Project.documents.data?.id);
    const tableConfig = useAppSelector((state) => state.Common.documentsTableConfig);
    const selected = useAppSelector((state) => state.Project.selectedDocuments);
    const columns = useColumns();
    const dispatch = useAppDispatch();
    const nounTypes = {
        type1: "документ",
        type2: "документа",
        type3: "документов",
    };

    const rowSelection = {
        selectedRowKeys: selected.map((item) => item.id),
        onChange: (selectedRowKeys: React.Key[], selectedRows: Documents.Item[]) => { 
            dispatch(Actions.Project.setSelectedDocuments(selectedRows));
        },
        getCheckboxProps: (record: Documents.Item) => {
            return ({
                name: record.document_name,
            });
        },
    };
    const filterSelectedColumns = (options: Array<any>, deletedColumns: string[] | null) => {
        if (deletedColumns && deletedColumns.length !== 0) return options.filter((item) => !deletedColumns.includes(item.title));
        else return options;
    };

    console.log(documents?.documents);

    if (!documents) return null;
    if (!documentsProjectID) return null;
    
    return (
        <Table
            onRow={(record) => onRowClick({
                document: record,
                projectID: documentsProjectID
            })}
            rowSelection={rowSelection}
            className={styles.table}
            dataSource={documents.documents}
            columns={filterSelectedColumns(columns, deletedColumns)}
            pagination={{
                current: documents.page,
                total: documents.count,
                defaultPageSize: tableConfig?.defaultPageSize || 10,
                pageSizeOptions: ["10", "20", "50"],
                showSizeChanger: true,
                locale: {
                    items_per_page: "",
                    page: "Страница",
                    jump_to: "Перейти",
                    prev_page: "Предыдущая страница",
                    next_page: "Следующая страница",
                    prev_3: "Предыдущие 3 страницы",
                    next_3: "Следующие 3 страницы",
                    prev_5: "Предыдущие 5 страниц",
                    next_5: "Следующие 5 страниц"
                },
                showTotal: () =>
                    `Всего ${documents.count} ${useGetValidNoun({
                        nounTypes,
                        number: documents.count,
                    })}`,
            }}
            onChange={(config, filters, sorters) => {
                if (!config.current || !config.pageSize) return; 
                dispatch(Actions.Common.setTableConfig({
                    config: {
                        currentPage: config.current,
                        defaultPageSize: config.pageSize,
                        order_by: getOrderBy(sorters),
                    },
                    table: "documents"
                }));
            }}
            scroll={{
                y: "calc(100vh - 400px)",
                x: true,
            }}
            rowKey="id"
            size="small"
            locale={{emptyText: (<EmptyDocuments />)}}/>
    );
};

export default TableView;
