import React, {useEffect} from "react";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import UnauthView from "./Modules/UnauthView/UnauthView";

interface iCheckUserContainerProps {
    children: JSX.Element
}

const CheckUserContainer = (props: iCheckUserContainerProps) => {
    const {children} = props;
    const dispatch = useAppDispatch();
    const userRequestState = useAppSelector((state) => state.User.info);

    useEffect(() => {
        dispatch(Actions.User.getUserInfo());
    }, []);

    if (userRequestState.loaded || userRequestState.error) return children;
    
    return <UnauthView />;
};

export default CheckUserContainer;
