import {showLabel, hideLabel} from "./toggleVisibleLabel";
type wrapWithSpanProps = {
    node: Text,
    markup_id: number
}
const wrapWithSpan = (props: wrapWithSpanProps) => {
    const {node, markup_id} = props;
    
    const highlight = node.ownerDocument.createElement("span");

    highlight.setAttribute("data-label", `${markup_id}`);

    highlight.addEventListener("mouseover", () => showLabel({markup_id}));
    highlight.addEventListener("mouseout", () => hideLabel({markup_id}));
    highlight.appendChild(node);
    return highlight;
};

export default wrapWithSpan;
