import React from "react";
import styles from "./LastDocumentItem.module.less";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import routes from "@routes";
import {useHistory} from "react-router-dom";
import {Tag} from "antd";
import useNotification from "@root/Hooks/useNotification/useNotification";
import getDate from "@root/Utils/Date";
import {Documents, Projects} from "@root/Types";

type LastDocumentItemProps = {
    id: Documents.Item["id"],
    project_id: Projects.Item["id"],
    documentName: string,
    documentType: string, // TODO ПОДУМАТЬ НУЖНО ЛИ НАМ ЭТО (ИСПОЛЬЗОВАЛОСЬ В ФУНКЦИОНАЛЕ ИЗВЛЕЧЕНИЯ ДЛЯ АВТОМАТИЧЕСКОЙ ПОДСТАНОВКИ ТИПА ДОКУМЕНТА)
    documentCreateDate: string,
    parseStatus: Documents.Status,
    docPdfStatus: Documents.Status
}

export const LastDocumentItem = (props: LastDocumentItemProps) => {
    const history = useHistory();
    const notification = useNotification();
    const onClickRoute = props.parseStatus === 200 && props.docPdfStatus === 200
        ? () => history.push(routes.lk.project.document(props.project_id,props.id)) 
        : props.parseStatus === 100 || props.docPdfStatus === 100
            ? () => {
                notification({
                    type: "error",
                    message: "Документ обрабатывается"
                });
            } 
            : () => {
                notification({
                    type: "error",
                    message: "Загруженный документ имеет ошибки"
                });
            }; 

    const makeTag = (parseStatus: Documents.Status, pdfStatus: Documents.Status) => {
        if (parseStatus === 0 || parseStatus === 100 || pdfStatus === 100) {
            return (
                <Tag
                    className={styles.processTag}>
                    В процессе
                </Tag>
            );
        } else if (parseStatus === 400 || pdfStatus === 400) {
            return (
                <Tag
                    className={styles.errorTag}>
                    Ошибка загрузки
                </Tag>
            );
        } else {
            return (
                <Tag
                    className={styles.successTag}>
                    Загружен
                </Tag>
            );
        }
    };

    return ( 
        <div 
            className={styles.item} 
            onClick={onClickRoute}>
            <div className={styles.itemContent}>
                <div className={styles.itemNameBlock}>
                    <div className={styles.itemCoreTitle}>
                        <Tooltip 
                            title={props.documentName} 
                            className={styles.toolTip}
                            placement="bottomLeft">
                            {props.documentName}
                        </Tooltip>
                    </div>
                    <div className={styles.Tags}>
                        {makeTag(props.parseStatus, props.docPdfStatus)}
                    </div>
                </div>
                {/* TODO ПОДУМАТЬ НУЖНО ЛИ НАМ ЭТО (ИСПОЛЬЗОВАЛОСЬ В ФУНКЦИОНАЛЕ ИЗВЛЕЧЕНИЯ ДЛЯ АВТОМАТИЧЕСКОЙ ПОДСТАНОВКИ ТИПА ДОКУМЕНТА) */}
                <div className={styles.itemTypeBlock}>
                    <div className={styles.itemSubTitle}>
                        Тип документа
                    </div>
                    <div className={styles.itemType}>
                        <Tooltip 
                            title={props.documentType} 
                            className={styles.toolTip}
                            placement="bottomLeft">
                            {props.documentType}
                        </Tooltip >
                    </div>
                    
                </div>
            </div>
            <div className={styles.itemDateBlock}>
                <div className={styles.itemSubTitle}>
                    Дата создания
                </div>
                <div className={styles.itemInfo}>
                    {getDate.short({date: props.documentCreateDate})}
                </div>
            </div>
        </div>
    );
};
