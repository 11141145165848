import * as React from "react";

const LogOut = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 7.21325C2 4.88662 3.88637 3 6.2123 3H10.8436C13.1703 3 15.0568 4.8865 15.0568 7.21325V8.0996C15.0568 8.4931 14.7378 8.8121 14.3443 8.8121C13.9508 8.8121 13.6318 8.4931 13.6318 8.0996V7.21325C13.6318 5.6735 12.3833 4.425 10.8436 4.425H6.2123C4.67363 4.425 3.425 5.67338 3.425 7.21325V17.7867C3.425 19.3266 4.67363 20.575 6.2123 20.575H10.8531C12.3874 20.575 13.6318 19.331 13.6318 17.7972V16.9014C13.6318 16.5078 13.9508 16.1889 14.3443 16.1889C14.7378 16.1889 15.0568 16.5078 15.0568 16.9014V17.7972C15.0568 20.1185 13.1739 22 10.8531 22H6.2123C3.88637 22 2 20.1134 2 17.7867V7.21325ZM8.64675 12.4999C8.64675 12.1064 8.96575 11.7874 9.35925 11.7874H19.0723L17.5135 10.2355C17.2346 9.95787 17.2336 9.50674 17.5112 9.22788C17.7889 8.94901 18.24 8.94801 18.5189 9.22564L21.2877 11.9822C21.425 12.1121 21.5107 12.296 21.5107 12.4999C21.5107 12.7055 21.4237 12.8907 21.2844 13.0207L18.519 15.7749C18.2401 16.0525 17.789 16.0516 17.5113 15.7728C17.2337 15.494 17.2346 15.0428 17.5134 14.7652L19.0725 13.2124H9.35925C8.96575 13.2124 8.64675 12.8934 8.64675 12.4999Z" fill="currentColor"/>
        </svg>
    );
};

export default LogOut;
