import React from "react";
import FavoriteFulfilled from "./Icons/FavoriteFulfilled";
import FavoriteOutlined from "./Icons/FavoriteOutlined";
import styles from "./FavoriteStar.module.less";

type FavoriteStarProps = {
    active: boolean;
}

const FavoriteStar = (props: FavoriteStarProps) => {
    const {active} = props;

    return (
        <div className={styles.wrapper}>
            {
                active ? (
                    <FavoriteFulfilled />
                ) : (
                    <FavoriteOutlined />
                )
            }
        </div>

    );
};

export default FavoriteStar;
