type isLastPathValidProps = {
    lastPath: string
}

const isLastPathValid = (props: isLastPathValidProps) => {
    const {lastPath} = props;
    return lastPath !== "logout" && lastPath !== "ready";
};

export default isLastPathValid;
