import {App} from "@types";
import React from "react";
import styles from "./Error.module.less";
import {Button} from "@root/Components/Controls";
import {useHistory} from "react-router-dom";

type iErrorView = {
  children: App.children | App.children[];
};

const ErrorView = (props: iErrorView) => {
    const history = useHistory(); 

    return (
        <div className={styles.wrapper}>
            {props.children}
            <div className={styles.backButton}>
                <Button
                    type="ghost"
                    size="large"
                    onClick={() => history.goBack()}
                >
                    Вернуться назад
                </Button>
            </div>
        </div>
    );
};

export default ErrorView;
