import React from "react";
import {Link} from "react-router-dom";
import Logo from "@root/Assets/Images/Sidebar/Logo";
import styles from "./LogoLink.module.less";

const LogoLink = () => {
    return (
        <Link to={"/"} className={styles.link}>
            <Logo />
        </Link>
    );
};

export default LogoLink;
