import React from "react";

const ExportIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="downlaod">
                <path id="downlaod_2" fillRule="evenodd" clipRule="evenodd" d="M11.7609 0.332031C12.2141 0.332031 12.5815 0.699387 12.5815 1.15254V13.1603L14.6342 11.1388C14.9571 10.8209 15.4766 10.8249 15.7946 11.1477C16.1125 11.4706 16.1085 11.9901 15.7857 12.3081L12.3368 15.7044C12.0175 16.0189 11.5048 16.0189 11.1854 15.7044L7.73658 12.3081C7.4137 11.9901 7.40972 11.4706 7.72768 11.1477C8.04565 10.8249 8.56515 10.8209 8.88803 11.1388L10.9404 13.16V1.15254C10.9404 0.699387 11.3078 0.332031 11.7609 0.332031ZM18.3437 7.46297C20.3124 7.46297 21.8803 9.02319 21.8803 10.9169V16.582C21.8803 18.4686 20.3172 20.0243 18.3544 20.0243L5.17884 20.0243C3.21005 20.0243 1.64103 18.4628 1.64103 16.5704V10.9041C1.64103 9.01898 3.20502 7.46297 5.16701 7.46297H6.28114C6.73429 7.46297 7.10165 7.09562 7.10165 6.64246C7.10165 6.1893 6.73429 5.82195 6.28114 5.82195H5.16701C2.32952 5.82195 0 8.08216 0 10.9041V16.5704C0 19.3991 2.33396 21.6654 5.17884 21.6654L18.3544 21.6654C21.1933 21.6654 23.5214 19.4049 23.5214 16.582V10.9169C23.5214 8.08723 21.1887 5.82195 18.3437 5.82195H17.2402C16.7871 5.82195 16.4197 6.1893 16.4197 6.64246C16.4197 7.09562 16.7871 7.46297 17.2402 7.46297H18.3437Z" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default ExportIcon;
