type getTitleProps = {
    createStatus: boolean
}

const getTitle = (props: getTitleProps) => {
    if (props.createStatus) return "Шаблон создан!"; 
    return "Создать шаблон";
};

export default getTitle;
