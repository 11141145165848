/* eslint-disable brace-style */
const getBrowserInfo = () => {
    const userAgent: string = navigator.userAgent;
    let browserName: string;
    let browserVersion: string;

    if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
        browserName = "Internet Explorer";
        browserVersion = userAgent.match(/(?:MSIE |rv:)(\d+(\.\d+)?)/)![1];
    }
    // Edge (не-Chromium версия)
    else if (userAgent.indexOf("Edge/") !== -1) {
        browserName = "Edge";
        browserVersion = userAgent.match(/Edge\/(\d+)/)![1];
    }
    // Edge (Chromium версия)
    else if (userAgent.indexOf("Edg/") !== -1) {
        browserName = "Edge (Chromium)";
        browserVersion = userAgent.match(/Edg\/(\d+)/)![1];
    }
    // Firefox
    else if (userAgent.indexOf("Firefox/") !== -1) {
        browserName = "Firefox";
        browserVersion = userAgent.match(/Firefox\/(\d+)/)![1];
    }
    // Opera
    else if (userAgent.indexOf("OPR/") !== -1 || userAgent.indexOf("Opera/") !== -1) {
        browserName = "Opera";
        browserVersion = userAgent.match(/(?:OPR|Opera)\/(\d+)/)![1];
    }
    // Yandex Browser
    else if (userAgent.indexOf("YaBrowser/") !== -1) {
        browserName = "Yandex Browser";
        browserVersion = userAgent.match(/YaBrowser\/(\d+)/)![1];
    }
    // Chrome
    else if (userAgent.indexOf("Chrome/") !== -1) {
        browserName = "Chrome";
        browserVersion = userAgent.match(/Chrome\/(\d+)/)![1];
    }
    // Safari
    else if (userAgent.indexOf("Safari/") !== -1) {
        browserName = "Safari";
        // Для Safari, версия может быть определена иначе
        if (userAgent.indexOf("Version/") !== -1) {
            browserVersion = userAgent.match(/Version\/(\d+)/)![1];
        } else {
            browserVersion = "Не удалось определить версию";
        }
    }
    // Не удалось определить браузер или его версию
    else {
        browserName = "Неизвестный браузер";
        browserVersion = "Не удалось определить версию";
    }

    return {
        name: browserName,
        version: browserVersion
    };
};

export default getBrowserInfo;
