import React from "react";
import Empty from "@root/Modules/Empty/Empty";
import EmptyDocuments from "@root/Assets/Icons/EmptyDocuments/EmptyDocuments";

type EmptyViewProps = {
    description: string;
}

const EmptyView = (props: EmptyViewProps) => {
    const {description} = props;
    return (
        <Empty
            image={<EmptyDocuments />}
            description={description}/>
    );
};

export default EmptyView;
