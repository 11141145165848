import React from "react";
import styles from "./Registration.module.less";
import {Form} from "antd";
import {Input, Button, FormItem, Checkbox} from "@components/Controls";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Auth} from "@root/Api/AuthApi/types";
import Navigation from "../../Modules/Navigation/Navigation";
import routes from "@routes";
import Title from "@root/Components/Title/Title";
import {useHistory} from "react-router-dom";

const Registration = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const state = useAppSelector((state) => state.Auth.registration);

    const redirect = () => {
        history.push(routes.auth.login);
    };

    const onRegistration = (values: Auth.iRegistration) => {
        dispatch(Actions.Auth.userRegistration({
            ...values,
            redirect
        }));
    };

    return (
        <>
            <Title
                variant="h1" 
                className={styles.title}>
                Регистрация
            </Title>
            {state.error && (
                <div className={styles.errorText}>{state.errMsg}</div>
            )}
            <Form
                form={form}
                name="register"
                onFinish={onRegistration}
                layout="vertical"
                scrollToFirstError
                className={styles.form}>
                <FormItem
                    name="email"
                    className={styles.item}
                    label="E-mail"
                    rules={[
                        {
                            required: true,
                            message: "Это поле не может быть пустым."
                        },
                        {
                            type: "email",
                            message: "E-mail не подходит",
                        }]}>
                    <Input
                        allowClear
                        className={styles.input}
                        placeholder="Введите ваш email адрес" 
                        disabled={state.fetching}
                    />
                </FormItem>
                <FormItem
                    name="password"
                    className={styles.item}
                    label="Пароль"
                    rules={[{
                        required: true,
                        message: "Это поле не может быть пустым."
                    }]}
                    dependencies={["confirm"]}>
                    <Input.Password 
                        disabled={state.fetching}
                        className={styles.input}
                        placeholder="Введите пароль"
                    />
                </FormItem>
                <FormItem
                    name="confirm"
                    className={styles.item}
                    label="Подтверждение пароля"
                    dependencies={["password"]}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(rule,value) {
                                if (!value) {
                                    return Promise.reject(new Error("Это поле не может быть пустым."));
                                }
                                if (getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Пароли не совпадают"));
                            },
                        })]}>
                    <Input.Password 
                        disabled={state.fetching}
                        className={styles.input}
                        placeholder="Повторите пароль"
                    />
                </FormItem>
                <FormItem 
                    rules={[
                        {
                            required: true,
                        },
                        () => ({
                            validator(_, value) {
                                if (value === true) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Требуется согласие с условиями работы в сервисе"));
                            },
                        }),
                    ]}
                    name="agreement"
                    valuePropName="checked">
                    <Checkbox disabled={state.fetching}>
                        Я&nbsp;понимаю условия, что сервис работает в&nbsp;демонстрационном режиме и&nbsp;о&nbsp;недопустимости его использования для обработки информации ограниченного доступа.
                    </Checkbox>
                </FormItem>
                <div className={styles.controls}>
                    <FormItem>
                        <Button
                            loading={state.fetching}
                            size="large"
                            type="primary"
                            className={styles.button}
                            htmlType="submit">
                            Зарегистрироваться
                        </Button>
                    </FormItem>
                    <div className={styles.link}>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.login}>
                                Уже зарегистрированы?
                            </Navigation>
                        </Button>
                    </div>
                </div>
            </Form>
        </>

    );
};

export default Registration;
