import React from "react";

const ErrorIcon = () => {
    return (                    
        <svg 
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                fill="#F86262"
                d="M10.2207 2.66602C7.95894 2.66602 6.04531 3.47351 4.70199 4.91468C3.36594 6.34805 2.66733 8.32838 2.66733 10.554V21.4447C2.66733 23.6691 3.3622 25.6496 4.69704 27.0835C6.03941 28.5256 7.95359 29.3327 10.2207 29.3327H21.778C24.0456 29.3327 25.9604 28.5257 27.3033 27.0837C28.6387 25.6498 29.334 23.6692 29.334 21.4447V10.554C29.334 8.32949 28.6387 6.34898 27.3035 4.91505C25.9608 3.47308 24.0464 2.66602 21.7793 2.66602H10.2207ZM4.66733 10.554C4.66733 8.74365 5.23138 7.27998 6.165 6.27835C7.09135 5.28452 8.45438 4.66602 10.2207 4.66602H21.7793C23.5523 4.66602 24.9152 5.28495 25.8398 6.27798C26.772 7.27905 27.334 8.74254 27.334 10.554V21.4447C27.334 23.2562 26.772 24.7196 25.8397 25.7207C24.9149 26.7137 23.5517 27.3327 21.778 27.3327H10.2207C8.44773 27.3327 7.08524 26.7138 6.16095 25.7208C5.22912 24.7198 4.66733 23.2563 4.66733 21.4447V10.554ZM12.0944 12.0823C12.485 11.6918 13.1181 11.6919 13.5086 12.0825L15.9989 14.5732L18.4869 12.0852C18.8774 11.6946 19.5106 11.6946 19.9011 12.0852C20.2917 12.4757 20.2917 13.1089 19.9011 13.4994L17.4129 15.9876L19.9033 18.4785C20.2938 18.869 20.2937 19.5022 19.9031 19.8927C19.5126 20.2832 18.8794 20.2831 18.4889 19.8925L15.9987 17.4018L13.5118 19.8887C13.1213 20.2792 12.4881 20.2792 12.0976 19.8887C11.7071 19.4982 11.7071 18.865 12.0976 18.4745L14.5847 15.9874L12.0943 13.4965C11.7038 13.106 11.7038 12.4728 12.0944 12.0823Z"
            />
        </svg>
    );
};

export default ErrorIcon;
