import React from "react";
import Title from "@components/Title/Title";
import Profile from "./Content/Profile/Profile";
import styles from "./Settings.module.less";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Settings = () =>  {
    useTitle("DeepDocs - Настройки");
    return (
        <div className={styles.wrapper}>
            <Title
                className={styles.title}
                variant="h2">
                    Настройки профиля
            </Title>
            <Profile />
        </div>

    );
};

export default Settings;
