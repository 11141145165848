import {takeLatest, call} from "redux-saga/effects";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import Api from "@api";
import React from "react";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {AxiosResponse} from "axios";

const DownloadAnonymizeDocumentsResult = function* (action: PayloadAction<iActions.downloadAnonymizeDocumentsResult>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        const response: AxiosResponse = yield call(Api.Anonymize.downloadAnonymizeDocumentsResult, payload);
        const data = response.data;
        const docType = response.headers["content-type"];
        if (data) {
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            if (docType === "application/zip" || docType === "application/x-zip-compressed") {
                link.setAttribute("download", "Anonymization_result.zip");
            } else {
                const filename = response.headers["content-disposition"]
                    .replace("attachment; filename*=utf-8''", "")
                    .replaceAll("\"", "");
                link.setAttribute("download", decodeURIComponent(filename));
            }
            document.body.appendChild(link);
            link.click();
        }
        notification({
            type: "info",
            message: "Результаты скачаны"
        });
    } catch (error) {
        notification({
            type: "error",
            message: "При скачивании результатов произошла ошибка"
        });
    }
};

export default function* () {
    yield takeLatest(Actions.Anonymize.downloadAnonymizeDocumentsResult, DownloadAnonymizeDocumentsResult);
}
