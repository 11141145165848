import {Button} from "@root/Components/Controls";
import React from "react";
import {Arrow} from "./Icons/Arrow";
import styles from "./LastBlock.module.less"; 

type LastsBlockProps = React.PropsWithChildren<{
    title: string,
    onClickRoute?: () => void,
}>;

const LastBlock = (props: LastsBlockProps) => {
    return (
        <div className={styles.card}>
            <div className={styles.cardInfo}>
                <span className={styles.cardTitle}>
                    {props.title}
                </span>
                {props.onClickRoute ? (
                    <Button className={styles.cardLink} 
                        type="link"
                        onClick={props.onClickRoute}>
                        <span>
                            Посмотреть все 
                        </span>
                        <div className={styles.svg}>
                            <Arrow />
                        </div>
                    </Button>
                ) : (
                    <></>
                )}

            </div>
            <div className={styles.cardContent}>
                {props.children}
            </div>
        </div>
    );
};
export default LastBlock;
