import rootSagaCreator from "../rootSagaCreator";
import DocumentUploadSaga from "./DocumentUploadSaga";
import DocumentsGetSaga from "./DocumetsGetSaga";
import GetDocumentInfoSaga from "./GetDocumentInfoSaga";
import DeleteDocumentsSaga from "./DeleteDocumentsSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        DocumentUploadSaga,
        DocumentsGetSaga,
        GetDocumentInfoSaga,
        DeleteDocumentsSaga,
    ], "PROJECT");
}
