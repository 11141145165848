import * as React from "react";

const DownloadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8207 4C12.1606 4 12.4361 4.27552 12.4361 4.61538V13.6212L13.9757 12.1051C14.2178 11.8666 14.6074 11.8696 14.8459 12.1118C15.0844 12.3539 15.0814 12.7436 14.8392 12.982L12.2526 15.5293C12.0131 15.7652 11.6286 15.7652 11.389 15.5293L8.80244 12.982C8.56028 12.7436 8.55729 12.3539 8.79576 12.1118C9.03424 11.8696 9.42386 11.8666 9.66602 12.1051L11.2053 13.621V4.61538C11.2053 4.27552 11.4808 4 11.8207 4ZM16.7578 9.34821C18.2343 9.34821 19.4103 10.5184 19.4103 11.9386V16.1875C19.4103 17.6024 18.2379 18.7692 16.7658 18.7692L6.88413 18.7692C5.40754 18.7692 4.23077 17.5981 4.23077 16.1788V11.9291C4.23077 10.5152 5.40377 9.34821 6.87526 9.34821H7.71085C8.05072 9.34821 8.32624 9.07269 8.32624 8.73282C8.32624 8.39295 8.05072 8.11744 7.71085 8.11744H6.87526C4.74714 8.11744 3 9.8126 3 11.9291V16.1788C3 18.3003 4.75047 20 6.88413 20L16.7658 20C18.895 20 20.641 18.3046 20.641 16.1875V11.9386C20.641 9.8164 18.8916 8.11744 16.7578 8.11744H15.9302C15.5903 8.11744 15.3148 8.39295 15.3148 8.73282C15.3148 9.07269 15.5903 9.34821 15.9302 9.34821H16.7578Z" fill="currentColor"/>
        </svg>

    );
};

export default DownloadIcon;
