import React from "react";
import styles from "./ButtonsBlock.module.less";
import ButtonChangeMode, {Type} from "../../Components/ButtonChangeMode/ButtonChangeMode";
import {useAppSelector} from "@root/Hooks";

type SwitchModeBarProps = {
    startEditing: () => void,
    saveEditing: () => void,
}

const ButtonsBlock = (props: SwitchModeBarProps) => {
    const editing = useAppSelector((state) => state.Anonymize.coloredPdf.editMode);
    
    const coreButtonType = Type.startEditing;

    return (
        <div className={styles.wrapper}>
            <ButtonChangeMode 
                type={coreButtonType}
                onClick={() => props.startEditing()}
                editModeActive={editing}
                disabled={editing}/>
        </div>
    );
};

export default ButtonsBlock;
