import Scrollbars from "react-custom-scrollbars";

type useArrowToBeginForScrollbarsProps = {
    scrollbarsContent: React.RefObject<Scrollbars>,
    showState: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    setBehaviorScroll: React.Dispatch<React.SetStateAction<boolean>>,
    visible: boolean,
}

const useArrowToBeginForScrollbars = (props: useArrowToBeginForScrollbarsProps) => {
    const {
        scrollbarsContent,
        showState,
        setShow,
        setBehaviorScroll,
        visible
    } = props;

    if (showState !== visible) setShow(props.visible);

    return async () => {
        await setBehaviorScroll(true);
        scrollbarsContent.current?.scrollTop(0);
        await setBehaviorScroll(false);
    };
};

export default useArrowToBeginForScrollbars;
