import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "@types";
import {iActions} from "./types";
import {
    getShortState,
    requestStart,
    requestSuccess,
    requestError,
    getFullState
} from "@root/Utils/Redux/getRequestState";
import {
    RequestShortState,
    RequestFullState
} from "@root/Utils/Redux/types";

type iState = {
    info: RequestFullState<User.iUserInfo>;
    changeUserInfo: RequestShortState,
    changePassword: RequestShortState,
}

const initialState:iState  = {
    info: getFullState(),
    changeUserInfo: getShortState(),
    changePassword: getShortState(),
};

const Slice = createSlice({
    initialState,
    name: "User",
    reducers: {
        getUserInfo: (state) => {
            requestStart(state.info);
        },
        _getUserInfoSuccess: (state, action:PayloadAction<iActions._getUserInfoSuccess>) => {
            requestSuccess(state.info, action.payload);
        },
        _getUserInfoError: (state, action:PayloadAction<iActions._getUserInfoError>) => {
            requestError(state.info);
        },
        changeUserInfo: (state, action:PayloadAction<iActions.changeUserInfo>) => {
            requestStart(state.changeUserInfo);
        },
        _changeUserInfoSuccess: (state) => {
            requestSuccess(state.changeUserInfo);
        },
        _changeUserInfoError: (state) => {
            requestError(state.changeUserInfo);
        },
        changePassword: (state, action:PayloadAction<iActions.changePassword>) => {
            requestStart(state.changePassword);
        },
        _changePasswordSuccess: (state) => {
            requestSuccess(state.changePassword);
        },
        _changePasswordError: (state) => {
            requestError(state.changePassword);
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;

