import SelectCheckboxesTypes, {OptionGuard} from "../../SelectCheckboxes/types";

type getValidOptionsProps = {
    options: string[] | number[] | SelectCheckboxesTypes.LabelValueType[]
}

// если option примитив собираем option иначе если уже готовый отдаем как есть
const getValidOptions = (props: getValidOptionsProps) => {
    const {options} = props;

    const validOptions = options.map((option) => {
        if (OptionGuard.isLabelValueType(option)) {
            return option;
        }
        return {
            value: option,
            label: option,
            key: option
        };
    }); 
    return validOptions;
}; 

export default getValidOptions;
