import React from "react";
import styles from "./TemplateDescription.module.less";

type TemplateDescriptionProps = {
    description: string;
}

const TemplateDescription = (props: TemplateDescriptionProps) => {
    return (
        <div className={styles.description}>
            <div className={styles.descriptionContent}>
                {props.description}
            </div>
        </div>
    );
};

export default TemplateDescription;
