import React, {useState} from "react";
import {Switch} from "@root/Components/Controls";
import {Popconfirm, Space} from "antd";
import Actions from "@actions";
import styles from "./ViewModeSwitcher.module.less";
import {useAppDispatch} from "@root/Hooks";
import useGetWarningNotification from "./Hooks/useGetWarningNotification";
import useConfirmOkUseEffect from "./Hooks/useConfirmOkUseEffect";
import useGetSaveChanges from "./../Hooks/useGetSaveChanges";
import {iState} from "@root/Redux/Anonymize/types";
import {Anonymization, Documents} from "@types";

type ViewModeSwitcherProps = {
    documentId: Documents.Item["id"],
    anonymizationRequestId: string,
    anonymizedDocumentId: string,
    addedEntities: Anonymization.MarkupItem[],
    selectedMarkups: number[],
    settings: iState.Settings
}

const ViewModeSwitcher = (props: ViewModeSwitcherProps) => {
    const {
        documentId,
        anonymizationRequestId,
        selectedMarkups,
        addedEntities,
        anonymizedDocumentId,
        settings
    } = props;
    const dispatch = useAppDispatch();

    const isViewModeOriginal = settings.dataMode === "original";
    const hasChanges = addedEntities.length !== 0 || selectedMarkups.length !== 0;
    const saveChanges = useGetSaveChanges({
        anonymized_document_id: anonymizedDocumentId,
        anonymization_request_id: anonymizationRequestId,
        id: documentId
    });
    const warningNotification = useGetWarningNotification();

    const [confirmIsOpen, setconfirmIsOpen] = useState(false);
    const [confirmOk, setConfirmOk] = useState(false);

    const defaultBehavior = (value: boolean) => {
        dispatch(Actions.HTML.eraseState());
        dispatch(Actions.Anonymize.changeSettings({
            id: documentId,
            settings: {
                dataMode: value ? "anonymized" : "original"
            }
        }));
        warningNotification(value);
    };

    useConfirmOkUseEffect({confirmOk});

    return (
        <Popconfirm     
            disabled={!hasChanges}                     
            placement="bottomRight"
            title="Сохранить изменения?"
            onConfirm={() => {
                setConfirmOk(true);
                if (hasChanges) {
                    saveChanges({
                        to_delete: selectedMarkups,
                        to_add: addedEntities,
                    });
                }
                dispatch(Actions.HTML.eraseState());
                warningNotification(isViewModeOriginal);
            }}
            onOpenChange={setconfirmIsOpen}
            onCancel={() => defaultBehavior(isViewModeOriginal)}
            okText="Да"
            cancelText="Нет">
            <Space 
                direction="horizontal"
                size={8}
                className={styles.switch}>
                Оригинал
                <Switch 
                    disabled={confirmIsOpen}
                    onChange={(value) => {
                        if (!hasChanges) defaultBehavior(value);
                    }}
                    checked={!isViewModeOriginal}
                />
                Обезличен    
            </Space>
        </Popconfirm>
    );
};

export default ViewModeSwitcher;
