import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {Button, Modal} from "@root/Components/Controls";
import {Dropdown} from "antd";
import ProjectsAddForm from "./Content/ProjectsAddForm/ProjectsAddForm";
import GridContainer from "@root/Components/GridContainer/GridContainer";
import Icon from "@ant-design/icons";
import styles from "./ProjectsPage.module.less";
import Loading from "@root/Components/Loading/Loading";
import Projects from "./Content/Projects/Projects";
import CloseIcon from "@assets/Icons/Close/Close";
import FilterMenu from "./Modules/FilterMenu/FilterMenu";
import ArrowDown from "@root/Assets/Icons/FilterMenu/ArrowDown/ArrowDown";
import Title from "./Modules/Title/Title";
import EditModal from "./Content/Projects/Modules/EditModal/EditModal";
import DeleteModal from "./Content/Projects/Modules/DeleteModal/DeleteModal";
import ViewSwitch from "./Modules/ViewSwitch/ViewSwitch";
import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";
import useTitle from "@root/Hooks/useTitle/useTitle";

const ProjectsPage = () => {
    const showDrawer = useAppSelector((state) => state.Projects.createModal);
    const sorted = useAppSelector((state) => state.Projects.sort.sorted);
    const getFetching = useAppSelector((state) => state.Projects.projects.fetching);
    const viewMode = useAppSelector((state) => state.Projects.viewMode);
    const [mode] = useLocalStorage<"cards" | "table", string>("cards", "project-view");
    useTitle("DeepDocs - Проекты");

    const dispatch = useAppDispatch();
    const [visibleFilter, setVisibleFilter] = useState(false);

    const setCreateModal = (mode: boolean) => {
        dispatch(Actions.Projects.setCreateModal(mode));
    };

    const onCloseModal = () => {
        setCreateModal(false);
    };

    useEffect(() => {
        dispatch(Actions.Projects.getProjects());
        dispatch(Actions.Projects.setViewMode(mode));
        dispatch(Actions.Common.eraseTableConfig("documents"));
        return () => {
            dispatch(Actions.Projects.setSort({
                dates: {
                    from: null,
                    to: null,
                },
                sorted: false,
                variant: "default"
            }));
        };
    }, []);

    const onCreateProject = () => {
        setCreateModal(true);
    };

    if (getFetching) {
        return (
            <Loading>
                Проекты загружаются...
            </Loading>
        );
    }

    return (
        <>
            <GridContainer className={styles.wrapper}>
                <>
                    <div className={styles.subHeader}>
                        <Title />
                        <div className={styles.buttonBlock}>
                            {viewMode === "cards" && (
                                <Dropdown
                                    open={visibleFilter}
                                    onOpenChange={setVisibleFilter}
                                    trigger={["click"]}
                                    overlay={<FilterMenu />}>
                                    <Button
                                        className={styles.filterButton}
                                        onMouseDown={(event) => event.preventDefault()}
                                        size="large"
                                        type="secondary">
                                        <div className={sorted ? styles.filteredCircle : styles.filterCircle}/>
                                        Фильтровать по <Icon className={styles.arrowIcon} component={ArrowDown} />
                                    </Button>
                                </Dropdown>
                            )}
                            <Button
                                disabled={getFetching}
                                size="large"
                                type="primary"
                                onClick={onCreateProject}>
                                Создать проект
                            </Button>
                            <ViewSwitch />
                        </div>
                    </div>
                    <Projects />
                </>
            </GridContainer>
            <Modal
                antProps={{
                    open: showDrawer,
                    title: "Создать проект",
                    footer: null,
                    onCancel: onCloseModal,
                    width: 440,
                    closeIcon: <CloseIcon />,
                    centered: true
                }}>
                <ProjectsAddForm />
            </Modal>
            <EditModal />
            <DeleteModal />
        </>
    );
};

export default ProjectsPage;
