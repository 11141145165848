import React, {useEffect} from "react";
import {useAppSelector} from "@root/Hooks";
import styles from "./PDF.module.less";
import Loading from "@root/Components/Loading/Loading";
import ViewPDF from "@root/Modules/ViewPDF/ViewPDF";

const PDF = () => {
    const pdfDocument = useAppSelector((state) => state.Document.pdfDocument.data);
    if (!pdfDocument) return null;
    const url = URL.createObjectURL(pdfDocument);

    //TODO Разобраться почему есть проблема с порядком вызова хука

    // useEffect(() => {
    //     return () => URL.revokeObjectURL(url);
    // }, []);

    return (
        <div className={styles.wrapper}>
            {!pdfDocument
                ? (
                    <Loading>
                        PDF документ загружается...
                    </Loading>
                )
                : (
                    typeof pdfDocument === "object" 
                        ? <ViewPDF 
                            pathToFile={url}
                            corepageStyles={styles.corepageStyles}
                            pageProps={{
                                width: 880
                            }}/>
                        : <div>При загрузке PDF файла произошла ошибка</div>
                )}
        </div>
    );
};

export default PDF;
