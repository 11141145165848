import Auth from "../../Auth";
import Cookies from "universal-cookie";
import routes from "@root/Routes/Routes";

const cookies = new Cookies();

let refreshTokenRequest: Promise<void> | null = null;

export const refreshToken = async () => {
    const accessToken = cookies.get("access_token");
    if (
        window.location.pathname === routes.auth.login ||
        window.location.pathname === routes.auth.registration ||
        window.location.pathname === routes.auth.recovery || 
        window.location.pathname === routes.auth.reset() ||
        window.location.pathname === routes.auth.root
    ) return;
    if (accessToken === undefined) {
        if (refreshTokenRequest === null) refreshTokenRequest = Auth.refresh();
        await refreshTokenRequest;
        if (typeof refreshTokenRequest === "object") refreshTokenRequest = null;
    }
    
};

export default refreshToken;
