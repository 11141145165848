import React, {useState} from "react";
import styles from "./ArrowToBegin.module.less";
import ArrowIcon from "./Icons/ArrowIcon";
import Scrollbars from "react-custom-scrollbars";
import useArrowToBeginForIframe from "./Hooks/useArrowToBeginForIframe";
import useArrowToBeginForScrollbars from "./Hooks/useArrowToBeginForScrollbars";
import classNames from "classnames";

type ArrowToBeginProps = {
    refLink: React.RefObject<HTMLIFrameElement>,
    type: "iFrame"
} | {
    refLink: React.RefObject<Scrollbars>,
    setBehaviorScroll: React.Dispatch<React.SetStateAction<boolean>>,
    type: "Scrollbars",
    visible: boolean,
};

const ArrowToBegin = (props: ArrowToBeginProps) => {
    const [visible, setVisible] = useState(false);
    let setScrollToStart;
    let style;

    if (props.type === "iFrame") {
        const iFrameWindow = props.refLink.current?.contentWindow;
    
        if (!iFrameWindow) return null;

        style = styles.Iframe;

        setScrollToStart = useArrowToBeginForIframe({
            window: iFrameWindow,
            setShow: setVisible
        });

    }

    if (props.type === "Scrollbars") {
        const scrollbarsContent = props.refLink;
    
        if (!scrollbarsContent) return null;

        style = styles.Scrollbars;
        
        setScrollToStart = useArrowToBeginForScrollbars({
            scrollbarsContent,
            setBehaviorScroll: props.setBehaviorScroll,
            setShow: setVisible,
            showState: visible,
            visible: props.visible
        });
    }

    if (!visible) return null;
    
    return (
        <div 
            onClick={setScrollToStart}
            className={classNames(styles.ScrollToStartButton, style)}>
            <ArrowIcon />
        </div>
    );
};

export default ArrowToBegin;
