import React from "react";
import {useLocation} from "react-router-dom";
import routes from "@routes";
import {useAppSelector} from "@root/Hooks";

const useShowSidebar = () => {
    const location = useLocation();
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);

    return (
        location.pathname !==
            routes.lk.project.anonymize(
                openedDocument?.project_id,
                openedDocument?.id
            )
    );
};

export default useShowSidebar;
