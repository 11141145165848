import React, {useEffect, useState} from "react";
import {TreeSelectProps,} from "antd";
import SelectCheckboxes from "../SelectCheckboxes/SelectCheckboxes";
import SelectCheckboxesTypes, {OptionGuard} from "../SelectCheckboxes/types";
import getValueFromOptions from "./Utils/GetValueFromOptions";
import getValidOptions from "./Utils/GetValidOptions";
import styles from "./SelectWithSelectAll.module.less";
import classnames from "classnames";

type fullValue = string[] | number[] | (string | number)[];

type SelectWithSelectAllProps<T extends SelectCheckboxesTypes.DefaultValueType> = {
    setValue: (value: fullValue) => void,
    options: string[] | number[] | SelectCheckboxesTypes.LabelValueType[],
    treeSelectProps?: Omit<TreeSelectProps<T>, "onSelect" | "onDeselect" | "multiple" | "value">
    initialValue?: fullValue,
    disabled?: boolean,
    templateMode?: boolean
};

const SELECT_ALL = "Выбрать все";

const SelectWithSelectAll = (props: SelectWithSelectAllProps<SelectCheckboxesTypes.DefaultValueType>) => {
    const {setValue, options, initialValue, treeSelectProps, disabled, templateMode} = props;
    const [checkedOptions, setCheckedOptions] = useState<fullValue>([]);

    // в форму отправляются данные без служебных значений, таких как SELECT_ALL 
    const sendValueToForm = (fullValue: fullValue) => {
        setValue(fullValue.filter((value) => value !== SELECT_ALL ));
    };

    // функция для обновления useState и одновременного получения результата обновления
    const setAndGetCheckedOptions = (fullValue: fullValue) => {
        setCheckedOptions(fullValue);
        return fullValue;
    };

    const clearCheckedOptions = () => {
        setAndGetCheckedOptions([]);
        sendValueToForm([]);
    };
    
    // Если у нас "Выбрать все" -> присваиваем все возможные options иначе просто добавляем новую option в конец и делаем проверку выбраны ли все возможные options, если да устанавливаем "Выбрать все"
    const selectEntity = (select: SelectCheckboxesTypes.LabelValueType | SelectCheckboxesTypes.RawValueType) => {
        // Получаем value выбранного селекта
        const value = OptionGuard.isLabelValueType(select) ? select.value : select;
        if (!value) return;

        if (value === "Выбрать все") {
            const actualCheckedOptions = setAndGetCheckedOptions([SELECT_ALL, ...getValueFromOptions({options})]);
            sendValueToForm(actualCheckedOptions);
            return;
        }

        const actualCheckedOptions = setAndGetCheckedOptions([...checkedOptions, value]);

        if (actualCheckedOptions.filter((option) => option !== SELECT_ALL).length === options.length) {
            setAndGetCheckedOptions([SELECT_ALL, ...actualCheckedOptions]);
        }
        sendValueToForm(actualCheckedOptions);
    };

    // Если у нас "Выбрать все" -> обнуляем выбранные options иначе просто удаляем options и "Выбрать все" если она выбрана
    const deselectEntity = (deselect: SelectCheckboxesTypes.LabelValueType | SelectCheckboxesTypes.RawValueType) => {
        // Получаем деселекта выбранного селекта
        const value = OptionGuard.isLabelValueType(deselect) ? deselect.value : deselect;
        // if (!value) return;

        if (value === "Выбрать все") {
            const actualCheckedOptions = setAndGetCheckedOptions([]);
            sendValueToForm(actualCheckedOptions);
            return;
        }

        const actualCheckedOptions = setAndGetCheckedOptions(checkedOptions.filter((option) => option !== value && option !== SELECT_ALL));
        sendValueToForm(actualCheckedOptions);  
    };

    useEffect(() => {
        if (initialValue) {
            if ((initialValue.filter((option) => option !== SELECT_ALL).length === options.length)) {
                setAndGetCheckedOptions([SELECT_ALL, ...initialValue]);
                sendValueToForm(initialValue);
                return;
            }
            setAndGetCheckedOptions(initialValue);
            sendValueToForm(initialValue);
        }
    }, [initialValue]);

    return (
        <SelectCheckboxes
            className={classnames({[styles.templateMode]: templateMode})}
            popupClassName={classnames({[styles.templateMode]: templateMode})}
            disabled={disabled}
            allowClear={templateMode ? undefined : true}
            multiple
            value={checkedOptions}
            onSelect={(value) => !templateMode && selectEntity(value)}              
            onClear={clearCheckedOptions}        
            onDeselect={(value) => !templateMode && deselectEntity(value)}
            treeCheckable
            treeData={[
                {
                    value: SELECT_ALL,
                    label: SELECT_ALL,
                    key: SELECT_ALL
                
                }, 
                ...getValidOptions({options})]}
            {...treeSelectProps}
        />
    );
};

export default SelectWithSelectAll;
