import React, {useMemo} from "react";
import {ColumnType, FilterDropdownProps} from "antd/es/table/interface";
import styles from "./useColumns.module.less";
import {useAppDispatch, useAppSelector, useGetMinColumnWidthForTable} from "@root/Hooks";
import TableFilters from "@root/Modules/TableFilters/TableFilters";
import moment from "moment";
import Actions from "@actions";
import FavoriteStar from "@root/Components/FavoriteStar/FavoriteStar";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import getDate from "@root/Utils/Date";
import getFilterDataFunction from "@root/Utils/Columns/getFilterDataFunction";
import dataFilterMapper from "@root/Utils/Columns/dataFilterMapper";
import DropdownMenu from "./Modules/DropdownMenu/DropdownMenu";
import {Projects} from "@types";

type ProjectItem = Projects.Item;

const useColumns = ():ColumnType<ProjectItem>[] => {
    const projects = useAppSelector((state) => state.Projects.projects.data);
    const dispatch = useAppDispatch();
    const getFilterData = getFilterDataFunction({array: projects || []});

    const changeRate = (item: ProjectItem, value: boolean) => {
        dispatch(
            Actions.Projects.setFavorite({
                project_id: item.id,
                favorite: value,
            })
        );
    };

    const titleFD = useMemo(() => getFilterData((item) => item.title), [projects]);
    const createdDateFD = useMemo(() => getFilterData((item) => getDate.short({date: item.created_date})), [projects]);

    return [
        {
            title: <div className={styles.statusTitle}>{""}</div>,
            render: (
                record: ProjectItem
            ) => {
                return (
                    <div
                        className={styles.rate}
                        onClick={(event) => {
                            event.stopPropagation();
                            changeRate(record, !record.favorite);
                        }}>
                        <FavoriteStar active={record.favorite}/>
                    </div>
                );
            },
            showSorterTooltip: false,
            width: 60,
            ellipsis: true,
            className: styles.statusColumn,
        },
        {
            title: "Название проекта",
            dataIndex: "title",
            key: "title",
            sorter: (first: ProjectItem, second: ProjectItem) => first.title.localeCompare(second.title),
            showSorterTooltip: false,
            filters: titleFD.map(dataFilterMapper),
            filterDropdown: (props: FilterDropdownProps) => {
                return (
                    <TableFilters
                        {...props}
                    />
                );
            },
            onFilter: (value: string | number | boolean, record: ProjectItem) => record.title === value,
            ellipsis: true,
            render: (title) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Название проекта")}}
                        className={styles.titleColumn}>
                        <span>
                            {title}
                        </span>
                    </div>
                );
            }
        },
        {
            title: "Дата создания",
            dataIndex: "created_date",
            key: "created_date",
            sorter: (first: ProjectItem, second: ProjectItem) => moment(first.created_date).unix() - moment(second.created_date).unix(),
            showSorterTooltip: false,
            filters: createdDateFD.map(dataFilterMapper),
            filterDropdown: (props: FilterDropdownProps) => {
                return (
                    <TableFilters
                        {...props}
                    />
                );
            },
            onFilter: (value: string | number | boolean, record: ProjectItem) => getDate.short({date: record.created_date}) === value,
            render: (created_date) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Дата создания")}}
                        className={styles.titleColumn}>
                        <span>
                            {getDate.short({date: created_date})}
                        </span>
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: "Документы",
            dataIndex: "docs_count",
            key: "docs_count",
            sorter: (first: ProjectItem, second: ProjectItem) => first.docs_count - second.docs_count,
            showSorterTooltip: false,
            render: (docs_count) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Документы")}}
                        className={styles.titleColumn}>
                        <span>
                            {docs_count}
                        </span>
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
            showSorterTooltip: false,
            render: (description) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Описание")}}
                        className={styles.titleColumn}>
                        <Tooltip
                            overlay={`${description ? description.slice(0, 110) : ""}${description && description.length > 110  ? "..." : ""}`}>
                            <span>
                                {description ? description.slice(0, 10) : ""}{description && description.length > 10 ? "..." : ""}
                            </span>
                        </Tooltip>

                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (id: ProjectItem["id"], record: ProjectItem) => {
                return <DropdownMenu id={id} />;
            },
        }
    ];
};

export default useColumns;
