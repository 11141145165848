import React from "react";
import Actions from "@actions";
import LogOut from "@root/Assets/Icons/HeaderMenu/LogOut/LogOut";
import {ButtonIcon} from "@root/Components/Controls";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import {useAppDispatch} from "@root/Hooks";
import styles from "./LogoutButton.module.less";
import useLastPathStorage from "@root/Hooks/useSaveLastPath/Hooks/useLastPathStorage/useLastPathStorage";

const LogoutButton = () => {
    const dispatch = useAppDispatch();
    const [_, setLastPath] = useLastPathStorage();
    const logout = () => {
        dispatch(Actions.Auth.userLogout());
        dispatch(Actions.User.getUserInfo());
        setLastPath("logout");
    };
    
    return (
        <Tooltip
            lineTooltip={true}
            placement="right"
            title={"Выйти"}>
            <ButtonIcon
                size="large"
                onClick={logout}
                className={styles.button}
                type="ghost"
                icon={<LogOut />}/>
        </Tooltip>

    );
};

export default LogoutButton;
