import React, {useMemo} from "react";
import {useAppSelector} from "@root/Hooks";
import routes from "@root/Routes/Routes";
import {Redirect} from "react-router-dom";
import {User, App} from "@types";

const {isAuthUserInfo} = User.Guard.User;

type CheckAuthorizedProps = {
    children: JSX.Element,
    unauthView: JSX.Element
}

const CheckAuthorized = (props: CheckAuthorizedProps) => {
    const {children, unauthView} = props;
    const userInfo = useAppSelector((state) => state.User.info.data);
    const isAuth = useMemo(() => isAuthUserInfo(userInfo), [userInfo]);

    if (isAuth) return children;

    return unauthView;
};

CheckAuthorized.defaultProps = {
    unauthView: <Redirect to={routes.auth.login}/>
};

export default CheckAuthorized;
