import React from "react";

const FavoriteOutlined = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0099 4.63718L14.7298 8.07318C14.8984 8.4105 15.2237 8.6444 15.601 8.69852L19.4486 9.25234C20.3992 9.38959 20.7774 10.5417 20.0895 11.2018L17.3072 13.8752C17.0338 14.1381 16.9093 14.516 16.974 14.8872L17.6306 18.6615C17.7923 19.5951 16.7986 20.3075 15.9489 19.8658L12.5101 18.0825C12.173 17.9076 11.7692 17.9076 11.4311 18.0825L7.99224 19.8658C7.14257 20.3075 6.14884 19.5951 6.31152 18.6615L6.96715 14.8872C7.03183 14.516 6.90737 14.1381 6.63395 13.8752L3.8517 11.2018C3.16373 10.5417 3.54202 9.38959 4.49263 9.25234L8.34014 8.69852C8.71745 8.6444 9.04379 8.4105 9.21235 8.07318L10.9313 4.63718C11.3566 3.78761 12.5846 3.78761 13.0099 4.63718Z" stroke="currentColor" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default FavoriteOutlined;
