import rootSagaCreator from "../rootSagaCreator";
import GetPdfDocumentSaga from "./GetPdfDocumentSaga";
import DownloadOriginalDocument from "./DownloadOriginalDocumentSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        GetPdfDocumentSaga,
        DownloadOriginalDocument
    ], "DOCUMENT");
}
