import React from "react";
import {Switch, Route} from "react-router-dom";
import Login from "../Contents/Login/Login";
import Registration from "../Contents/Registration/Registration";
import Recovery from "../Contents/Recovery/Recovery";
import routes from "@routes";
import Reset from "../Contents/Reset/Reset";

const ContentController = () => {
    return (
        <Switch>
            <Route path={routes.auth.login}>
                <Login />
            </Route>
            <Route path={routes.auth.registration}>
                <Registration />
            </Route>
            <Route path={routes.auth.recovery}>
                <Recovery />
            </Route>
            <Route path={routes.auth.reset()}>
                <Reset />
            </Route>
        </Switch>
    );
};

export default ContentController;
