import React, {useEffect, useState} from "react";
import styles from "./FilterMenu.module.less";
import {Button, Menu} from "@root/Components/Controls";
import Star from "@root/Assets/Icons/FilterMenu/Star/Star";
import {ConfigProvider, DatePicker} from "antd";
import type {RangePickerProps} from "antd/es/date-picker";
import moment from "moment";
import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";
import NewFirst from "@root/Assets/Icons/FilterMenu/NewFirst/NewFirst";
import OldFirst from "@root/Assets/Icons/FilterMenu/OldFirst/OldFirst";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import Clear from "@root/Assets/Icons/Clear/Clear";

const {RangePicker} = DatePicker;

const FilterMenu = () => {
    const projects = useAppSelector((state) => state.Projects.projects.data);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([""]);
    const [date, setDate] = useState<[moment.Moment | null, moment.Moment | null] | null>();
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.Projects.sort);

    const SortByFavorite = () => {
        dispatch(Actions.Projects.setSort({
            variant: "fav",
            sorted: true
        }));
    };

    const SortOldFirst = () => {
        dispatch(Actions.Projects.setSort({
            variant: "old",
            sorted: true
        }));
    };

    const SortNewFirst = () => {
        dispatch(Actions.Projects.setSort({
            variant: "new",
            sorted: true
        }));
    };

    const onChangeDates = (value: RangePickerProps["value"]) => {
        setSelectedKeys([""]);
        if (value && projects && value[0] && value[1]) {
            const fromDate = moment(value[0].startOf("day")).valueOf();
            const toDate = moment(value[1].startOf("day")).valueOf();
            dispatch(Actions.Projects.setSort({
                dates: {
                    from: fromDate,
                    to: toDate,
                },
                sorted: true
            }));
        }
        if (!value && projects) {
            dispatch(Actions.Projects.setSort({
                dates: {
                    from: null,
                    to: null,
                },
                sorted: sort.variant !== "default",
            }));
        }
    };

    const clearAll = () => {
        if (projects) {
            setSelectedKeys([""]);
            setDate(null);
            dispatch(Actions.Projects.setSort({
                dates: {
                    from: null,
                    to: null,
                },
                sorted: false,
                variant: "new"
            }));
        }
    };

    useEffect(() => {
        if (sort.dates.from && sort.dates.to) {
            setDate([moment(sort.dates.from), moment(sort.dates.to)]);
        }
        if (!sort.sorted) {
            setSelectedKeys([""]);
        } else if (sort.variant === "new") {
            setSelectedKeys(["1"]);
        } else if (sort.variant === "old") {
            setSelectedKeys(["2"]);
        } else if (sort.variant === "fav") {
            setSelectedKeys(["3"]);
        }
    }, [sort]);

    return (
        <div className={styles.wrapper}>
            <ConfigProvider locale={locale}>
                <RangePicker
                    value={date}
                    onCalendarChange={(dates) => {
                        if (!dates) {
                            setDate(null);
                        } else {
                            if (!dates[1]) {
                                setDate([moment(dates[0]), null]);
                            } else if (!dates[0]) {
                                setDate([null, moment(dates[1])]);
                            } else {
                                setDate([moment(dates[0]), moment(dates[1])]);
                            }
                        }
                    }}
                    format="DD.MM.YYYY"
                    separator={"-"}
                    placeholder={["__.__.____", "__.__.____"]}
                    getPopupContainer={(node) => node}
                    className={styles.datepicker}
                    onChange={onChangeDates}/>
            </ConfigProvider>
            <div className={styles.subTitle}>
                Сортировка
            </div>
            <Menu
                mode="vertical"
                className={styles.menu}
                selectedKeys={selectedKeys}
                onSelect={({keyPath}) => setSelectedKeys(keyPath)}
                getPopupContainer={(node) => node}
                selectable>
                <Menu.Item key="1">
                    <Button
                        type="menu"
                        onClick={SortNewFirst}
                        icon={<NewFirst />}
                        className={styles.btn}>
                        Сначала новые
                    </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button
                        type="menu"
                        onClick={SortOldFirst}
                        icon={<OldFirst />}
                        className={styles.btn}>
                        Сначала старые
                    </Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button
                        type="menu"
                        onClick={SortByFavorite}
                        icon={<Star />}
                        className={styles.btn}>
                        Сначала избранные
                    </Button>
                </Menu.Item>
            </Menu>
            <Button
                onClick={clearAll}
                icon={<Clear />}
                className={styles.clearButton}
                type="link">
                Сбросить все
            </Button>
            
        </div>
    );
};

export default FilterMenu;
