import * as React from "react";

const NewFirst = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 6.16672C4 5.85729 4.12292 5.56053 4.34171 5.34173C4.5605 5.12292 4.85725 5 5.16667 5H19.1667C19.4761 5 19.7728 5.12292 19.9916 5.34173C20.2104 5.56053 20.3333 5.85729 20.3333 6.16672C20.3333 6.47616 20.2104 6.77292 19.9916 6.99172C19.7728 7.21052 19.4761 7.33345 19.1667 7.33345H5.16667C4.85725 7.33345 4.5605 7.21052 4.34171 6.99172C4.12292 6.77292 4 6.47616 4 6.16672ZM4 12.0003C4 11.6909 4.12292 11.3941 4.34171 11.1753C4.5605 10.9565 4.85725 10.8336 5.16667 10.8336H19.1667C19.4761 10.8336 19.7728 10.9565 19.9916 11.1753C20.2104 11.3941 20.3333 11.6909 20.3333 12.0003C20.3333 12.3098 20.2104 12.6065 19.9916 12.8253C19.7728 13.0441 19.4761 13.1671 19.1667 13.1671H5.16667C4.85725 13.1671 4.5605 13.0441 4.34171 12.8253C4.12292 12.6065 4 12.3098 4 12.0003ZM4 17.834C4 17.5245 4.12292 17.2278 4.34171 17.009C4.5605 16.7902 4.85725 16.6672 5.16667 16.6672H11C11.3094 16.6672 11.6062 16.7902 11.825 17.009C12.0438 17.2278 12.1667 17.5245 12.1667 17.834C12.1667 18.1434 12.0438 18.4402 11.825 18.659C11.6062 18.8778 11.3094 19.0007 11 19.0007H5.16667C4.85725 19.0007 4.5605 18.8778 4.34171 18.659C4.12292 18.4402 4 18.1434 4 17.834Z" fill="currentColor"/>
        </svg>
    );
};

export default NewFirst;
