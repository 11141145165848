import * as React from "react";

const Delete = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.54972 3C8.74593 3 8.02377 3.52451 7.80848 4.30693C7.80496 4.31971 7.80187 4.33259 7.7992 4.34557L7.57226 5.44906C7.48851 5.85481 7.11433 6.16619 6.65923 6.16619L6.65112 6.16624H3.60765C3.27205 6.16624 3 6.43765 3 6.77246C3 7.10726 3.27205 7.37867 3.60765 7.37867H19.445C19.7806 7.37867 20.0526 7.10726 20.0526 6.77246C20.0526 6.43765 19.7806 6.16624 19.445 6.16624H16.4016L16.3934 6.16619C15.9383 6.16619 15.564 5.85469 15.4804 5.44882L15.2535 4.34557C15.2508 4.33259 15.2477 4.31971 15.2442 4.30693C15.0289 3.52451 14.3068 3 13.503 3H9.54972ZM14.4486 6.16624H8.60404C8.67456 6.01812 8.72839 5.85948 8.76275 5.69275L8.98493 4.61241C9.05529 4.3843 9.27811 4.21243 9.54972 4.21243H13.503C13.7746 4.21243 13.9974 4.3843 14.0677 4.61242L14.29 5.69299C14.3243 5.85973 14.3781 6.01812 14.4486 6.16624Z" fill="currentColor"/>
            <path d="M18.7589 9.75212C18.7866 9.41846 18.5379 9.12558 18.2035 9.09796C17.869 9.07034 17.5755 9.31844 17.5478 9.6521L17.5255 9.9192C17.511 10.0911 17.4901 10.3397 17.4641 10.6461C17.412 11.2588 17.3394 12.1025 17.2577 13.0266C17.0941 14.8774 16.8944 17.0416 16.7483 18.32C16.6912 18.8211 16.5119 19.1604 16.2709 19.378C16.0297 19.5957 15.6663 19.7459 15.1391 19.7552C12.7092 19.7978 10.2772 19.8004 7.85037 19.7507C7.35479 19.7408 7.0069 19.5925 6.77234 19.3753C6.5364 19.1568 6.36008 18.8173 6.30428 18.3263C6.15726 17.0366 5.9578 14.8709 5.79463 13.0214C5.71316 12.098 5.64093 11.2556 5.58907 10.644C5.56314 10.3382 5.5423 10.0902 5.52795 9.91868L5.50575 9.6523C5.47815 9.31863 5.18463 9.07041 4.85017 9.09795C4.51572 9.12549 4.26697 9.41838 4.29458 9.75205L4.31686 10.0195C4.33125 10.1915 4.35212 10.44 4.3781 10.7462C4.43004 11.3587 4.5024 12.2025 4.58401 13.1277C4.74701 14.9752 4.94774 17.1559 5.09677 18.4633C5.17828 19.1802 5.45492 19.8095 5.94553 20.2639C6.43748 20.7195 7.08967 20.9482 7.82578 20.9629C10.2702 21.013 12.7175 21.0102 15.1607 20.9675C15.9139 20.9541 16.5815 20.7326 17.0863 20.277C17.5912 19.8213 17.8728 19.1857 17.9558 18.4571C18.1039 17.161 18.3049 14.9818 18.4683 13.1331C18.5502 12.2074 18.6228 11.3622 18.675 10.7486C18.7011 10.4417 18.7221 10.1927 18.7365 10.0203L18.7589 9.75212Z" fill="currentColor"/>
        </svg>
    );
};

export default Delete;
