import React, {useState} from "react";
import {Upload, Drawer, Spin} from "antd";
import {Button} from "@components/Controls";
import {RcFile} from "antd/es/upload";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import {useParams} from "react-router-dom";
import styles from "./UploadDrawer.module.less";
import Title from "@components/Title/Title";
import UploadDocument from "@assets/Icons/Upload/UploadDocument";

type iParams = {
    projectID: string
}

const {Dragger} = Upload;

const UploadDrawer = () => {
    const [files, setFiles] = useState<RcFile[]>([]);
    const showDrawer = useAppSelector((state) => state.Project.showDrawer);
    const uploadFetching = useAppSelector((state) => state.Project.uploadFetching);
    const tableConfig = useAppSelector((state) => state.Common.documentsTableConfig);

    const dispatch = useAppDispatch();
    const {projectID} = useParams<iParams>();
    const setDrawerShow = (mode: boolean) => {
        dispatch(Actions.Project.changeDrawerView(mode));
    };

    const onUpload = () => {
        dispatch(Actions.Common.setTableConfig({
            config: {
                currentPage: tableConfig?.currentPage || 1,
                defaultPageSize: tableConfig?.defaultPageSize || 10,
                order_by: tableConfig?.order_by,
                name: tableConfig?.name
            },
            table: "documents"
        }));
        dispatch(Actions.Project.changeDrawerView(false));
    };

    const uploadDocuments = () => {
        const params = {
            project: projectID,
            document: files,
            onSuccess: onUpload
        };
        dispatch(Actions.Project.uploadDocument(params));
        setFiles([]);
    };

    return (
        <Drawer
            closeIcon={<RightOutlined />}
            open={showDrawer}
            placement="right"
            closable={true}
            onClose={() => setDrawerShow(false)}
            width={336}
            className={styles.wrapper}>
            {uploadFetching ? (
                <Spin
                    size="large"
                    indicator={<LoadingOutlined />}
                    className={styles.loader}
                    tip="Загрузка документов..." />
            ) : (
                <>
                    <Title
                        variant="h3"
                        className={styles.title}>
                        Загрузка документов
                    </Title>
                    <div className={styles.contentWrapper}>
                        <Dragger
                            name="documents"
                            accept=".pdf,.zip,.rar,.txt,.doc,.docx,.html,.xml,.pst"
                            multiple
                            beforeUpload={(_: RcFile, fileList: RcFile[]) => {
                                setFiles([...files, ...fileList]);
                                return false;
                            }}
                            fileList={files}
                            onRemove={(file) => {
                                setFiles(files.filter((item) => item.uid !== file.uid));
                            }}
                            className={styles.dragger}>
                            <p className="ant-upload-drag-icon">
                                <UploadDocument />
                            </p>
                            <p className="ant-upload-text">
                                Нажмите или перенесите в эту область файлы для загрузки
                            </p>
                            <p className="ant-upload-hint">
                                Поддерживаются документы формата .pdf .zip .rar .doc .docx .txt .html .xml .pst
                            </p>
                        </Dragger>
                        <Button
                            size="large"
                            type="primary"
                            disabled={!files[0]}
                            onClick={() => uploadDocuments()}
                            className={styles.button}>
                            Загрузить документы
                        </Button>
                    </div>
                </>
            )}
        </Drawer>

    );
};

export default UploadDrawer;
