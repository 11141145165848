const button = `
    background-size: cover;
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: -0.8em;
    right: -10px;
    margin-left: -16px;
    cursor: pointer;
    background-position: center;
    display: inline-block;
`;

export const styles = {
    button,
};
