import React from "react";
import styles from "./Label.module.less";

type iLabel = {
    children: string | string[];
    className?: string;
}

const Label = (props: iLabel) => {
    return (
        <div className={styles.wrapper}>
            {props.children}
        </div>
    );
};

export default Label;
