import React from "react";
import ProjectCard from "@root/Modules/ProjectCard/ProjectCard";
import {Row, Col} from "antd";
import styles from "./Projects.module.less";
import {Scrollbars} from "react-custom-scrollbars";
import useFilterProjects from "@root/Pages/Lk/Projects/Modules/Hooks/useFilterProjects/useFilterProjects";

const Projects = () => {
    const filteredProjects = useFilterProjects();

    if (!filteredProjects) return null;

    return (
        <div className={styles.wrapper}>
            <Scrollbars
                autoHide>
                <Row
                    className={styles.row}
                    gutter={[24, 24]}>
                    {filteredProjects.map((item, index) => (
                        <Col
                            className={styles.col}
                            key={index}>
                            <ProjectCard 
                                item={item}
                                disabledRate={false}
                                menuView={true}/>
                        </Col>
                    ))}
                </Row>
            </Scrollbars>
        </div>
    );
};

export default Projects;
