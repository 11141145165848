import rootSagaCreator from "../rootSagaCreator";
import GetTemplatesSaga from "./GetTemplatesSaga";
import CreateTemplateSaga from "./CreateTemplateSaga";
import DeleteTemplateSaga from "./DeleteTemplateSaga";
import ChangeTemplateSaga from "./ChangeTemplateSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        GetTemplatesSaga,
        CreateTemplateSaga,
        DeleteTemplateSaga,
        ChangeTemplateSaga,
    ], "TEMPLATES");
}
