import React from "react";
import {TreeSelect, TreeSelectProps,} from "antd";
import styles from "./SelectCheckboxes.module.less";
import classnames from "classnames";
import SelectCheckboxesTypes from "./types";
import ClearIcon from "./Components/ClearIcon/ClearIcon";

const SelectCheckboxes = <T extends SelectCheckboxesTypes.DefaultValueType>(props: TreeSelectProps<T>) => {  
    const {className, ...selectCheckboxesProps} = props;
    return (
        <TreeSelect 
            clearIcon={<ClearIcon />}
            className={classnames(styles.custom, className)}
            {...selectCheckboxesProps} />
    );
};

export default SelectCheckboxes;
