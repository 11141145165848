import React from "react";
import useStatisticsData from "./Hooks/useStatisticsData";
import styles from "./Statistics.module.less";
import Anonymized from "@assets/Icons/Statistics/Anonymized";
import LineChartMarkedEntities from "@components/LineChartMarkedEntities/LineChartMarkedEntities";
import CountStatBlock from "@components/CountStatBlock/CountStatBlock";
import DonutStat from "@root/Components/DonutStat/DonutStat";
import Documents from "@assets/Icons/Statistics/Documents";

const Statistics = () => {
    const statistics = useStatisticsData();

    if (!statistics) return null;
      
    return (
        <div className={styles.wrapper}>
            <div className={styles.firstLine}>
                <CountStatBlock
                    icon={<Anonymized />}
                    title={"Обезличенные сущности"}
                    statNumber={statistics.anonymized_entities}
                    duringTime={"за все время"} />
                <DonutStat 
                    stat={statistics.extension_stat}
                    sum={statistics.extension_sum}
                    title="Форматы документов" />
                <DonutStat
                    stat={statistics.doctype_stat}
                    sum={statistics.doctype_sum}
                    title="Типы документов" />
            </div>
            <div className={styles.secondLine}>
                <CountStatBlock
                    icon={<Documents />}
                    title={"Всего документов"}
                    statNumber={statistics.docs_total} />
                <div className={styles.bar}>
                    <div className={styles.title}>
                        Обезличенные сущности
                    </div>
                    <LineChartMarkedEntities 
                        anon_stat={statistics.anon_stat}
                        barValues={statistics.barValues}
                        width={785} />
                </div>
            </div>
        </div>
    );
};

export default Statistics;
