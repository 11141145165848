import Request from "../Request";
import {Common} from "./types";
import Endpoints from "./endpoints";

export default {
    getEntities: () => {
        return Request.get<Common.oGetEntities>(`${Endpoints.Common.getEntities}`);
    },
    getMainStatistic: () => {
        const url =  Endpoints.Common.getMainStatistic;
        return Request.get(url);
    },
    getLastDocuments: () => {
        const url =  Endpoints.Common.getLastDocuments;
        return Request.get(url);
    }
};
