import findRange from "./Utils/findRange";

type findRangeNativeProps = {
    start: number,
    end: number,
    originalIframeBody: HTMLBodyElement
}

const findRangeNative = (props: findRangeNativeProps) => {
    const {originalIframeBody} = props;
    
    const {startContainer, endContainer} = findRange(props);

    const range = originalIframeBody.ownerDocument.createRange();

    if (!startContainer || !endContainer) return;

    range.setStart(startContainer.node, startContainer.position);
    range.setEnd(endContainer.node, endContainer.position);

    return range;
};

export default findRangeNative;
