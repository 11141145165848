import React from "react";
import {Layout} from "antd";
import {iLayoutProps} from "../index";
import styles from "./DocumentLayout.module.less";
import classnames from "classnames";

const DocumentLayout = (props: iLayoutProps) => {

    return (
        <Layout className={classnames(styles.layout, props.layoutClassname)}>
            <Layout.Content className={classnames(styles.content, props.contentClassname)}>
                {props.children}
            </Layout.Content>
        </Layout>
    );
};

export default DocumentLayout;
