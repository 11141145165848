import React, {useEffect} from "react";
import {Button, FormItem, Input, Modal} from "@components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import CloseIcon from "@assets/Icons/Close/Close";
import styles from "./CreateModal.module.less";
import GridContainer from "@components/GridContainer/GridContainer";
import {Form} from "antd";
import SelectWithSelectAll from "@root/Components/Controls/SelectWithSelectAll/SelectWithSelectAll";
import {iActions} from "@root/Redux/Templates/types";
import getTitle from "./Utils/GetTitle";

type CreateModalProps = {
    presetEntities?: string[]
}

const CreateModal = (props: CreateModalProps) => {
    const {presetEntities} = props;
    const createModal = useAppSelector((state) => state.Templates.createTemplateModal);
    const createTemplateStatus = useAppSelector((state) => state.Templates.createTemplateStatus);
    const entities = useAppSelector((state) => state.Common.entities.data);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const onCloseModal = () => {
        dispatch(Actions.Templates.setCreateTemplateModal(false));
    };
    const onAfterClose = () => {
        dispatch(Actions.Templates.createTemplateToDefault());
    };

    const onCreate = () => {
        const data: iActions.createTemplate = {
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
            entities: form.getFieldValue("entities"),
        };
        dispatch(Actions.Templates.createTemplate(data));
    };

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [createModal, form]);

    if (!entities) return null;

    const ModalContent = () => {
        return (
            <>
                {
                    (createTemplateStatus.loaded && !presetEntities) && (
                        <div className={styles.finishDesc}>
                            Вы можете перейти в раздел с документами в проекте, чтобы отправить документы на обезличивание и применить шаблон
                        </div>
                    ) 
                }
                {
                    (createTemplateStatus.loaded && presetEntities) && (
                        <div className={styles.finishDesc}>
                            Вы можете его отредактировать или удалить в разделе шаблонов
                        </div>
                    ) 
                }
                {!createTemplateStatus.loaded &&  (
                    <>
                        <FormItem
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Поле не может быть пустым",
                                },
                            ]}
                            label="Название шаблона">
                            <Input
                                disabled={createTemplateStatus.fetching}
                                placeholder={"Введите название"}
                                maxLength={40}
                                allowClear
                            />
                        </FormItem>
                        <FormItem
                            required={false}
                            name="entities"
                            rules={[{
                                required: true,
                                message: "Выберите сущности"
                            }]}
                            label={
                                <div className={styles.label}>
                                    Сущности на обезличивание
                                </div>}> 
                            <SelectWithSelectAll 
                                initialValue={presetEntities}
                                disabled={createTemplateStatus.fetching}
                                setValue={(value) => form.setFieldsValue({["entities"]: value})}
                                options={entities}
                                treeSelectProps={{
                                    placeholder: "Выберите сущности"
                                }}/>
                        </FormItem>
                        <FormItem
                            className={styles.inputProjectDescription}
                            name="description"
                            label="Комментарий">
                            <Input.TextArea
                                disabled={createTemplateStatus.fetching}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 3,
                                }}
                                placeholder={"Напишите комментарий"}
                            />
                        </FormItem>
                        <div className={styles.buttons}>
                            <FormItem className={styles.lastFormItem}>
                                <Button
                                    className={styles.backButton}
                                    type="stroke"
                                    size="large"
                                    onClick={onCloseModal}>
                                        Назад
                                </Button>
                            </FormItem>
                            <FormItem className={styles.lastFormItem}>
                                <Button
                                    className={styles.button}
                                    type="primary"
                                    size="large"
                                    htmlType="submit">
                                        Сохранить
                                </Button>
                            </FormItem>
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <Modal
            antProps={{
                afterClose: onAfterClose,
                destroyOnClose: true,
                open: createModal,
                title: getTitle({createStatus: createTemplateStatus.loaded}),
                footer: null,
                onCancel: onCloseModal,
                closable: true,
                width: 440,
                getContainer: false,
                closeIcon: <CloseIcon />,
                centered: true,
                forceRender: true,
            }}>
            <GridContainer className={styles.grid}>
                <Form
                    name="CreateTemplate"
                    layout="vertical"
                    form={form}
                    requiredMark={false}
                    onFinish={onCreate}>
                    <ModalContent />
                </Form>
            </GridContainer>
        </Modal>
    );
};

export default CreateModal;
