import JsPDF from "jspdf";
import {useNotification} from "@root/Hooks";

const downloadPdf = async (name: string) => {
    const notification = useNotification();

    const pages = document.querySelectorAll("[data-id=\"page-item\"]");
    if (!pages.length) {
        notification({
            type: "error",
            message: "Ошибка при скачивании файла"
        });
    }
    // Получаем высоту и ширину первой страницы документы, чтобы создать jsPDF
    const firstPageCanvases = pages[0].querySelectorAll("canvas");
    const firstPageCanvas = firstPageCanvases[0];
    const width = firstPageCanvas.width > firstPageCanvas.height ? 1500 : 1050;
    const height = firstPageCanvas.height > firstPageCanvas.width ? 1500 : 1050;
    //
    
    const pdf = new JsPDF(
        width > height ? "l" : "p", 
        "pt", 
        [height / 2, width / 2]
    );
    for (let index = 0;index < pages.length;index += 1) {
        const page = pages[index];
        const canvases = page.querySelectorAll("canvas");

        for (let canvasIndex = 0;canvasIndex < canvases.length;canvasIndex += 1) {
            const canvas = canvases[canvasIndex];

            // Получаем высоту и ширину этой страницы
            const width = canvas.width > canvas.height ? 1500 : 1050;
            const height = canvas.height > canvas.width ? 1500 : 1050;
            //

            //Скейлим новый канвас в два раза меньше по качеству
            const scaledCanvas = document.createElement("canvas");
            scaledCanvas.width = width;
            scaledCanvas.height = height;
            const ctx = scaledCanvas.getContext("2d");
            ctx!.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
            //

            // Используется setTimeout с 0 задержкой, чтобы разбить операцию создания PDF на части
            await new Promise((resolve) => setTimeout(resolve, 0));
            //
            pdf.addImage(
                scaledCanvas.toDataURL("", 1.0),
                0,
                0,
                width / 2,
                height / 2,
                undefined,
                "FAST"
            );

            //Удаляем созданный канвас для скачивания
            scaledCanvas.remove();
            //
            
        }
        if (index !== pages.length - 1) {
            //Получаем высоту и ширину следующей страницы
            const nextPageCanvases = pages[index + 1].querySelectorAll("canvas");
            const firstCanvas = nextPageCanvases[0];
            const widthNext = firstCanvas.width > firstCanvas.height ? 1500 : 1050;
            const heightNext = firstCanvas.height > firstCanvas.width ? 1500 : 1050;
            //
            
            pdf.addPage([widthNext / 2, heightNext / 2], widthNext > heightNext ? "l" : "p");
        }
    }
    pdf.save(name, {returnPromise: true});
    notification({
        type: "info",
        message: "Результаты загружены"
    });
};

export default downloadPdf;
