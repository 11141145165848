import getBrowserInfo from "@root/Utils/Browser/getBrowserInfo";

const useCheckUserBrowser = () => {
    const browserInfo = getBrowserInfo();
    let acceptable: boolean = true;
    const acceptableMessage = `
        Ваша версия браузера не
        поддерживается и
        может работать с ошибками.
    `;
    const acceptableDesc = `
        В случае возникновения проблем
        рекомендуем воспользоваться
        следующими браузерами:
        Edge версии 102 и выше,
        Chrome версии 59 и выше,
        Safari версии 10 и выше,
        Yandex версии 19 и выше.
    `;
    
    if (browserInfo.name === "Edge") {
        if (+browserInfo.version < 102) {
            acceptable = false;
        }
    } else if (browserInfo.name === "Chrome"){
        if (+browserInfo.version < 59) {
            acceptable = false;
        }
    } else if (browserInfo.name === "Yandex Browser"){
        if (+browserInfo.version < 19) {
            acceptable = false;
        }
    } else if (browserInfo.name === "Firefox"){
        if (+browserInfo.version < 50) {
            acceptable = false;
        }
    } else if (browserInfo.name === "Safari"){
        if (+browserInfo.version < 10) {
            acceptable = false;
        }
    } else {
        acceptable = false;
    }

    return {
        acceptable,
        acceptableMessage,
        acceptableDesc
    };
};

export default useCheckUserBrowser;
