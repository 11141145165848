import React from "react";
import styles from "./HeadBar.module.less";
import {Button} from "@root/Components/Controls";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";

export const HeadBar = () => {
    const dispatch = useAppDispatch();
    const setDrawerShow = (mode: boolean) => {
        dispatch(Actions.Project.changeDrawerView(mode));
    };

    return (
        <div className={styles.wrapper}> 
            <p className={styles.title}>
                    Список документов
            </p>
            <div className={styles.uploadBtn}>
                <Button
                    type="primary"
                    onClick={() => setDrawerShow(true)}
                >
                    Загрузить документы
                </Button>
            </div>
        </div>
    );
};
