import {Actions as User} from "./User/UserRedux";
import {Actions as Auth} from "./Auth/AuthRedux";
import {Actions as Projects} from "./Projects/ProjectsRedux";
import {Actions as Project} from "./Project/ProjectRedux";
import {Actions as Document} from "./Document/DocumentRedux";
import {Actions as Anonymize} from "./Anonymize/Anonymize";
import {Actions as Common} from "./Common/CommonRedux";
import {Actions as HTML} from "./HTML/HTML";
import {Actions as Templates} from "./Templates/TemplatesRedux";

export default {
    User,
    Auth,
    Projects,
    Project,
    Document,
    Anonymize,
    Common,
    HTML,
    Templates
};
