const refreshCacheAndReload = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
    }
    // delete browser cache and hard reload
    window.location.reload();
};

export default refreshCacheAndReload;
