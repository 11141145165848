import Scrollbars from "react-custom-scrollbars";

type useStartCheckForScrollbarsProps = {
    refLink: React.RefObject<Scrollbars>,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const useStartCheckForScrollbars = (props: useStartCheckForScrollbarsProps) => {
    const scrollbarsContent = props.refLink;
    const checkScrollPosition = () => {
        if (scrollbarsContent.current?.getScrollTop() !== 0) {
            props.setVisible(true);
            return;
        }
        if (scrollbarsContent.current?.getScrollTop() === 0) {
            props.setVisible(false);
        }
    };
    return checkScrollPosition;
};

export default useStartCheckForScrollbars;
