import React from "react";
import {Input} from "antd";
import styles from "./Search.module.less";
import {SearchProps} from "antd/lib/input";
import classNames from "classnames";

const Search = (props: SearchProps) => {
    const {className} = props;
    return (
        <Input.Search 
            {...props}
            enterButton
            className={classNames(styles.search, className)}
            allowClear
        />
    );
};

export default Search;
