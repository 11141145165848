import {takeLatest, call, put} from "redux-saga/effects";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@redux/User/types";
import {PayloadAction} from "@reduxjs/toolkit";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {Api as ApiTypes} from "@root/Types";

const ChangePassword = function* (action: PayloadAction<iActions.changePassword>) {
    const notification = useNotification();
    
    try {
        yield call(Api.User.changePassword, action.payload);
        yield put(Actions.User._changePasswordSuccess());
        action.payload.onSuccess && action.payload.onSuccess();
        notification({
            type: "info",
            message: "Пароль изменен"
        });
    } catch (error: any) {
        const errorData = error.response.data as ApiTypes.ErrorResponse;
        if (errorData) {
            notification({
                type: "error",
                message: errorData.detail.message,
            });
        } else {
            notification({
                type: "error",
                message: "При изменении пароля произошла ошибка"
            });
        }
        yield put(Actions.User._changePasswordError());
    }
};

export default function* () {
    yield takeLatest(Actions.User.changePassword, ChangePassword);
}
