import React from "react";
import styles from "./SwitchModeButtons.module.less";
import ButtonsBlock from "./Modules/ButtonsBlock/ButtonsBlock";
import ButtonChangeMode, {Type} from "./Components/ButtonChangeMode/ButtonChangeMode";
import {useAppSelector} from "@root/Hooks";
import CreateTemplateButton from "@root/Pages/Lk/Templates/Components/CreateTemplateButton/CreateTemplateButton";

type SwitchModeBarProps = {
    startEditing: () => void,
    saveEditing: () => void,
}

const SwitchModeButtons = (props: SwitchModeBarProps) => {
    const editing = useAppSelector((state) => state.Anonymize.coloredPdf.editMode);

    return (
        <div className={styles.switchModeBar}>
            <ButtonsBlock  {...props} />
            <div className={styles.controlButtons}>
                <ButtonChangeMode
                    disabled={!editing}
                    type={Type.save}
                    onClick={props.saveEditing}/>
            </div>
            <CreateTemplateButton />
        </div>
    );
};

export default SwitchModeButtons;
