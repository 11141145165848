import {useEffect} from "react";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import useDocument from "@root/Pages/Lk/Anonymization/Hooks/useDocument";

type useConfirmOkUseEffectProps = {
    confirmOk: boolean,
}

const useConfirmOkUseEffect = (props: useConfirmOkUseEffectProps) => {
    const {confirmOk} = props;
    const dispatch = useAppDispatch();
    const document = useDocument();
    if (!document) return null;
    const currentDocumentViewMode = document.settings.dataMode;

    useEffect(() => {
        return () => {
            if (confirmOk) dispatch(Actions.Anonymize.changeSettings({
                id: document.id,
                settings: {
                    dataMode: currentDocumentViewMode === "original" ? "anonymized" : "original"
                }
            }));
        };
    },[confirmOk]);
};

export default useConfirmOkUseEffect;
