import React, {useCallback, ReactNode} from "react";
import {Scrollbars} from "react-custom-scrollbars";

type refType = (node: HTMLElement | null) => void;

type CustomScrollbarsProps = {
    onScroll: (values: any) => void;
    forwardedRef: refType;
    style: React.CSSProperties;
    children: ReactNode;
}

type CustomScrollbarsVirtualListProps = {
    onScroll: (values: any) => void;
    style: React.CSSProperties;
    children: ReactNode;
}

const CustomScrollbars: React.FC<CustomScrollbarsProps> = ({onScroll, forwardedRef, style, children}) => {
    const refSetter = useCallback((scrollbarsRef) => {
        if (scrollbarsRef) {
            forwardedRef(scrollbarsRef.view);
        } else {
            forwardedRef(null);
        }
    }, []);

    return (
        <Scrollbars
            ref={refSetter}
            style={{...style,
                overflow: "hidden"}}
            onScroll={onScroll} >
            {children}
        </Scrollbars>
    );
};

const CustomScrollbarsVirtualList = React.forwardRef((props: CustomScrollbarsVirtualListProps, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref as refType} />
));

export default CustomScrollbarsVirtualList;
