import codePointsToChars from "./codePointsToChars";
import findOnPosition from "./findOnPosition";

type findRangeProps = {
    start: number,
    end: number,
    originalIframeBody: HTMLBodyElement
}

const findRange = (props: findRangeProps) => {
    const {start, end, originalIframeBody} = props;
    
    return {
        startContainer: codePointsToChars(findOnPosition({
            originalIframeBody,
            position: start,
            borderSide: "right"
        })!),
        endContainer: codePointsToChars(findOnPosition({
            originalIframeBody,
            position: end,
            borderSide: "left"
        })!),
    };
};

export default findRange;
