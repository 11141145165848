import addLabel from "../AddLabel/addLabel";
import findNodesBetween from "../findNodesBetween";
import getHighlight from "./Utils/getHighlight";
import addMarkupStyles from "./Utils/addMarkupStyles";
import {Anonymization} from "@types";

type highlightRangeProps = {
    range: Range,
    markup: Anonymization.MarkupItem | Omit<Anonymization.MarkupItem, "text">,
    workIframe: Document,
    onDelete: (node?: Node) => void,
    onCancelDelete?: () => void
}

const highlightRange = (props: highlightRangeProps) => {
    const {range, markup, workIframe, onDelete, onCancelDelete} = props;
    const {startContainer, endContainer, commonAncestorContainer, startOffset, endOffset} = range;
    const highlights: HTMLSpanElement[] = [];

    if (startContainer === endContainer) {
        const highlight = getHighlight({
            container: startContainer,
            startOffset,
            endOffset,
            markup_id: markup.id
        });
        
        if (highlight) highlights.push(highlight);
    } else {
        const nodesBetween = findNodesBetween({
            startNode: startContainer,
            endNode: endContainer,
            root: commonAncestorContainer
        });
        nodesBetween.forEach((node) => {
            let start = startOffset;
            let end = endOffset;

            if (node !== startContainer) start = 0;
            if (node !== endContainer && node.textContent) end = node.textContent.length;

            const highlight = getHighlight({
                container: node,
                startOffset: start,
                endOffset: end,
                markup_id: markup.id
            });

            if (highlight) highlights.push(highlight);
        });
    }

    addMarkupStyles({highlights});
    
    const lastLabel = highlights[highlights.length - 1];
    if (lastLabel) addLabel(
        {
            lastNode: lastLabel,
            markup,
            workIframe,
            onDelete,
            onCancelDelete,
            highlights
        }
    );
};

export default highlightRange;
