import {SorterResult} from "antd/lib/table/interface";
import {Documents} from "@types";

const getOrderBy = (sorters: SorterResult<Documents.Item> | SorterResult<Documents.Item>[]) => {
    if (Array.isArray(sorters)) return;
    if (sorters.order === "ascend" && sorters.field) return String(sorters.field);
    if (sorters.order === "descend" && sorters.field) return `-${String(sorters.field)}`;
};

export default getOrderBy;
